import {
  LOGIN_USER,
  LOGIN_USER_FAIL,
  LOGOUT,
  TOKEN,
  GET_USER,
  GET_USER_LOADING,
  GET_USER_FAIL,
  GET_ERRORS_TEXT,
  REGISTER_USER,
  UPDATE_USER,
  REGISTER_USER_FAIL,
  UPDATE_USER_FAIL,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_FAIL,
  SET_SUCCESS,
  LOGIN_USER_2FA,
  TOKEN_2FA,
} from "./ActionTypes";
import { SetAuthToken, useURL } from "../Config";
import axios from "axios";
import { toast } from "react-toastify";
import {
  clearErrors,
  returnErrors,
  getPermission,
} from "../Reducer/ErrorReducer";
import {
  // findProviderFunding,
  // getBiller,
  // getBillerFunding,
  getCategory,
  // getChoosenServices,
  // getDataTransactionStat,
  getGateway,
  getHonourworld,
  // getMcd,
  getNetwork,
  // getOgdams,
  // getProducts,
  // getProviderStat,
  // getRingo,
  // getRpiData,
  // getUzobestData,
} from "./ProviderAction";
import { getSettings } from "../Reducer/SettingsReducer";
import {
  getUseCase,
  // manageUpgrade
} from "../Reducer/UseCaseReducer";
import {
  getDashboardMetrics,
  // converterServices,
  // getAllServicesHistory,
  // getDataHistory,
  // getManualBonusHistory,
  // getServicesHistory,
  getWalletBalance,
  // getWalletStat,
  // getWalletStatMonthDay,
  // getServicesHistoryPending,
} from "./GeneralAction";
import // getNotificationsFrom,
// getNotificationsTo,
// getSlider,
// manageBanks,
// manageFaqs,
// manageStartimes,
"./NotificationAction";
import // getAllUserTransactionAmount,
// getUserKeyPatrons,
// getAllUserPrivileges,
// getUserPrivileges,
// getUserWalletTracking,
"./UserActions";
// import { getActivity } from "../Reducer/BizReducer";

// LOGOUT
export const logoutUser = () => async (dispatch) => {
  try {
    dispatch({ type: LOGOUT });
    axios.post(`/api/v2/user/logout`, {}, { baseURL: useURL });
  } catch (err) {
    if (err) console.log(err.response?.data?.error, { err });
    if (err?.response?.status === 429) toast.error(err?.response?.data);
    dispatch({ type: GET_USER_FAIL });
  }
  dispatch(clearErrors());
};

// GET USER INFO
export const loadUser = () => async (dispatch) => {
  let token = localStorage.getItem(TOKEN);
  if (token) SetAuthToken(token);

  dispatch({ type: GET_USER_LOADING });
  dispatch(clearErrors());
  try {
    let res = await axios.get(`/api/v2/user`, {
      baseURL: useURL,
    });

    if (res?.data?.data?.isAdmin) {
      dispatch({
        type: GET_USER,
        payload: res.data,
      });

      dispatch(getWalletBalance());
      dispatch(getSettings());
      dispatch(getNetwork());
      dispatch(getUseCase());
      dispatch(getCategory());
      dispatch(getGateway());
      dispatch(getPermission());

      // dispatch(getDataBundle());
      // dispatch(getElectricityBundle());
      // dispatch(getEducationBundle());
      // dispatch(getProducts());
      // dispatch(getDataToBuy());
      // dispatch(getElectricityToBuy());
      // dispatch(getEducationToBuy());
      // dispatch(getCablesPackages());
      // dispatch(getCablesTypes());
      // dispatch(getBiller());

      //COMMENTED OUT BEFORE REFACTORING
      // dispatch(getWalletStat("notransact"));
      // dispatch(getWalletStatMonthDay("day"));
      // dispatch(getWalletStatMonthDay("month"));
      // dispatch(getAllUserPrivileges());
      // dispatch(manageBanks("get"));
      // dispatch(getBillerFunding());
      // dispatch(getRingo());
      // dispatch(getOgdams());
      // dispatch(getUserPrivileges("user"));
      // dispatch(getUserPrivileges("agent"));
      // dispatch(getUserPrivileges("reseller"));
      // dispatch(getUserPrivileges("superadmin"));
      // dispatch(getUserPrivileges("manager"));
      // dispatch(getUserPrivileges("marketer"));
      // dispatch(getUserPrivileges("developer"));
      // dispatch(getUserPrivileges("accountant"));
      // dispatch(getUserWalletTracking());
      // dispatch(getDataHistory(null, "all"));
      // dispatch(getDataHistory({ streamline: "day" }, "all"));
      // dispatch(getDataHistory({ streamline: "month" }, "all"));
      // dispatch(getServicesHistory("all"));
      // dispatch(getServicesHistory("all", { streamline: "day" }));
      // dispatch(getServicesHistory("all", { streamline: "month" }));
      // dispatch(getAllServicesHistory("all"));
      // dispatch(getAllServicesHistory("all", { streamline: "day" }));
      // dispatch(getAllServicesHistory("all", { streamline: "month" }));
      // dispatch(getAllUserTransactionAmount("user", "notransact"));
      // dispatch(getAllUserTransactionAmount("topuser", "notransact"));
      // dispatch(getAllUserTransactionAmount("reseller", "notransact"));
      // dispatch(getAllUserTransactionAmount("agent", "notransact"));
      // dispatch(getAllUserTransactionAmount("manager", "notransact"));
      // dispatch(getAllUserTransactionAmount("accountant", "notransact"));
      // dispatch(getAllUserTransactionAmount("developer", "notransact"));
      // dispatch(getAllUserTransactionAmount("marketer", "notransact"));
      // dispatch(getAllUserTransactionAmount("superadmin", "notransact"));
      // dispatch(getDataTransactionStat());
      // dispatch(getProviderStat({ route: "/manage-transactions" }));
      // dispatch(getProviderStat({ route: "/manage-wallet-provider" }));
      // dispatch(getSlider());
      // dispatch(converterServices("get", "converter"));
      // dispatch(converterServices("get", "banks"));
      // dispatch(converterServices("get", "converter-number"));
      // dispatch(manageFaqs("get", "faq"));
      // dispatch(manageFaqs("get", "terms"));
      // dispatch(manageFaqs("get", "privacy"));
      // dispatch(manageStartimes("get"));
      // dispatch(getActivity());
      // dispatch(manageUpgrade());
      // dispatch(getNotificationsFrom());
      // dispatch(getNotificationsTo());
      // dispatch(getUserKeyPatrons("day"));
      // dispatch(getUserKeyPatrons("week"));

      // dispatch(getManualBonusHistory("manage-bonus"));
      // dispatch(getManualBonusHistory("manual-funding"));
      // dispatch(findProviderFunding({ provider: "manual" }));
      // dispatch(findProviderFunding({ provider: "budpay" }));
      // dispatch(findProviderFunding({ provider: "monnify" }));
      // dispatch(findProviderFunding({ provider: "paystack" }));
      // dispatch(findProviderFunding({ provider: "flutterwave" }));
      if (process.env.REACT_APP_NAME !== "Honour World") {
        dispatch(getHonourworld());
      }
      if (process.env.REACT_APP_NAME === "Honour World") {
        // dispatch(getChoosenServices());
        // dispatch(getRpiData());
        // dispatch(getMcd());
        // dispatch(getUzobestData());
      }
    } else {
      dispatch({ type: GET_USER_FAIL });
    }
  } catch (err) {
    if (err) console.log(err.response?.data?.error, { err });
    if (err?.response?.status === 429) toast.error(err?.response?.data);
    dispatch({ type: GET_USER_FAIL });
    dispatch({
      type: GET_ERRORS_TEXT,
      payload: err?.response?.data?.error
        ? err?.response?.data?.error?.[0]?.msg
        : err?.response?.data
        ? err?.response?.data
        : err?.message,
    });
  }
};

// LOGIN ACTION
export const loginUser = (userData) => async (dispatch) => {
  try {
    let res = await axios.post(
      `/api/v2/user/login`,
      { ...userData },
      {
        baseURL: useURL,
      }
    );
    dispatch(clearErrors());

    if (res?.data?.data?.isAdmin) {
      dispatch({
        type:
          res?.data?.is2FAEnabled === "enable" ? LOGIN_USER_2FA : LOGIN_USER,
        payload: res.data,
      });
      if (res?.data?.is2FAEnabled !== "enable") {
        // let token = localStorage.getItem(TOKEN);
        // if (token) SetAuthToken(token);
        // dispatch(getDashboardMetrics());

        dispatch(loadUser());
      }
      // if (res?.data?.is2FAEnabled !== "enable") {
      //   let token = localStorage.getItem(TOKEN);
      //   if (token) SetAuthToken(token);
      //   dispatch(getWalletBalance());

      //   dispatch(getSettings());
      //   dispatch(getNetwork());
      //   dispatch(getUseCase());
      //   dispatch(getCategory());
      //   dispatch(getGateway());
      //   dispatch(getPermission());
      // }
      toast.success(res.data.msg, { autoClose: 5000 });
    } else {
      dispatch({ type: LOGIN_USER_FAIL });
      dispatch(
        returnErrors({ error: [{ msg: "Unauthorised User", param: "auth" }] })
      );
    }
  } catch (err) {
    if (err?.response?.status === 429 || err?.response?.status === 405)
      toast.error(err?.response?.data ? err?.response?.data : err?.message);
    console.log({ err });
    let error = err.response?.data?.error;
    if (error) {
      dispatch(returnErrors({ error, status: err?.response?.status }));
    }
    dispatch({ type: LOGIN_USER_FAIL });
  }
};

export const loginUser2FA = (userData) => async (dispatch) => {
  try {
    let res = await axios.put(
      `/api/v2/user/is2fa-authenticate`,
      { ...userData },
      {
        baseURL: useURL,
        headers: {
          Authorization: localStorage.getItem(TOKEN_2FA),
        },
      }
    );
    dispatch(clearErrors());

    if (res?.data?.data?.isAdmin) {
      dispatch({
        type:
          res?.data?.is2FAEnabled === "enable" ? LOGIN_USER_2FA : LOGIN_USER,
        payload: res.data,
      });
      if (res?.data?.is2FAEnabled !== "enable") {
        let token = localStorage.getItem(TOKEN);
        if (token) SetAuthToken(token);
        dispatch(getDashboardMetrics());
        dispatch(loadUser());
      }
      toast.success(res.data.msg, { autoClose: 5000 });
    } else {
      dispatch({ type: LOGIN_USER_FAIL });
      dispatch(
        returnErrors({ error: [{ msg: "Unauthorised User", param: "auth" }] })
      );
    }
  } catch (err) {
    if (err?.response?.status === 429 || err?.response?.status === 405)
      toast.error(err?.response?.data ? err?.response?.data : err?.message);
    console.log({ err });
    let error = err.response?.data?.error;
    if (error) {
      dispatch(returnErrors({ error, status: err?.response?.status }));
    }
    dispatch({ type: LOGIN_USER_FAIL });
  }
};

// REGISTER ACTION
export const registerUser = (userData) => async (dispatch) => {
  dispatch(clearErrors());
  console.log({ userData });
  try {
    var res = await axios.post(
      "/api/v2/user",
      { ...userData },
      { baseURL: useURL }
    );

    dispatch({
      type: REGISTER_USER,
      payload: res.data,
    });
    toast.success(res.data.msg, { autoClose: 5000 });
  } catch (err) {
    if (err?.response?.status === 429 || err?.response?.status === 405)
      toast.error(err?.response?.data ? err?.response?.data : err?.message);
    console.log({ err });
    let error = err.response?.data?.error;
    if (error) {
      dispatch(returnErrors({ error, status: err?.response?.status }));
    }
    dispatch({ type: REGISTER_USER_FAIL });
  }
};

export const updatePassword = (userData) => async (dispatch) => {
  dispatch(clearErrors());

  try {
    var res = await axios.put(
      `/api/v2/user/update-password`,
      { ...userData },
      { baseURL: useURL }
    );

    dispatch({
      type: UPDATE_PASSWORD,
      payload: res.data,
    });
    dispatch({ type: SET_SUCCESS, payload: res?.data?.msg });
  } catch (err) {
    if (err?.response?.status === 429 || err?.response?.status === 405)
      toast.error(err?.response?.data ? err?.response?.data : err?.message);
    console.log({ err });
    let error = err.response?.data?.error;
    if (error) {
      dispatch(returnErrors({ error, status: err?.response?.status }));
    }
    dispatch({ type: UPDATE_PASSWORD_FAIL });
  }
};

export const updateUser = (userData, type) => async (dispatch) => {
  dispatch(clearErrors());

  try {
    var avatar, res;
    if (type === "profile-image") {
      let media = await imageUpload([userData.logo]);
      avatar = media[0];
      res = await axios.put(
        `/api/v2/user/update-avatar`,
        {
          ...userData,
          avatar,
        },
        { baseURL: useURL }
      );
    } else {
      res = await axios.put(
        `/api/v2/user`,
        { ...userData },
        { baseURL: useURL }
      );
    }

    dispatch({
      type: UPDATE_USER,
      payload: res.data,
    });
    dispatch({ type: SET_SUCCESS, payload: res?.data?.msg });
  } catch (err) {
    if (err?.response?.status === 429 || err?.response?.status === 405)
      toast.error(err?.response?.data ? err?.response?.data : err?.message);
    console.log({ err });
    let error = err.response?.data?.error;
    if (error) {
      dispatch(returnErrors({ error, status: err?.response?.status }));
    }
    dispatch({ type: UPDATE_USER_FAIL });
  }
};

export const deleteUser = (userId) => async (dispatch) => {
  dispatch(clearErrors());

  try {
    const res = await axios.delete(`/api/v2/admin/delete-user/${userId}`, {
      baseURL: useURL,
    });
    dispatch({ type: SET_SUCCESS, payload: res?.data?.msg });
  } catch (err) {
    if (err?.response?.status === 429 || err?.response?.status === 405)
      toast.error(err?.response?.data ? err?.response?.data : err?.message);
    console.log({ err });
    let error = err.response?.data?.error;
    if (error) {
      dispatch(returnErrors({ error, status: err?.response?.status }));
    }
  }
};

export const imageUpload = async (images) => {
  let imgArr = [];
  for (const item of images) {
    // console.log({ item });
    let post = new FormData();
    post.append(`file`, item);

    let res = await axios.post(`/api/v2/file`, post, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    const data = await res.data?.data;
    // console.log({ data });
    Array.isArray(data) ? (imgArr = [...imgArr, ...data]) : imgArr.push(data);
  }
  return imgArr;
};
