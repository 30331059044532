import React, { useState, useContext, useEffect } from "react";
import { Container } from "reactstrap";
import {
  MainPaginate,
  TransactionDetails,
  TransactionsData,
} from "../../Components/Transactions";
import { GlobalState } from "../../Data/Context";
import { BottomTab } from "../../Components/LoadMore";

const HeldTransactions = () => {
  let { setStateName } = useContext(GlobalState);
  useEffect(() => {
    setStateName("Urgent Pendings");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="bg-white">
      <Container>
        <SubHeldTransactionsFolder />
      </Container>
    </div>
  );
};

export default HeldTransactions;

export const SubHeldTransactionsFolder = () => {
  const { held, getServicesHistoryHeld, getReload } = useContext(GlobalState);

  let [search, setSearch] = useState(""),
    [state, setState] = useState(null),
    [thisData, setThisData] = useState(null);
  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    if (search) {
      document.getElementById("Search").addEventListener("search", () => {
        getReload();
      });
      let handleSubmit = async () => {
        if (!search) return;

        await getServicesHistoryHeld({
          search,
        });
      };
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (held.isFound) {
      setState(held.mainSearch);
    } else setState(held.transactions);
  }, [held.transactions, held.isFound, held.mainSearch]);

  useEffect(() => {
    getReload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getServicesHistoryHeld();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (held?.search_paginate) {
      setPageCount(held?.search_paginate?.total / held?.search_paginate?.limit);
    }
    setPageCount(Math.ceil(held?.paginate?.total / held?.paginate?.limit));
  }, [held?.paginate?.total, held?.paginate?.limit, held?.search_paginate]);

  if (!state) return <></>;

  const currentItems = state;

  const handlePageClick = async (event) => {
    if (search) {
      await getServicesHistoryHeld({
        page: Number(event.selected + 1),
        search,
      });
    } else {
      await getServicesHistoryHeld({
        page: Number(event.selected + 1),
      });
    }
  };

  return (
    <div className="py-5">
      <div className="w-50 w50">
        <input
          type="search"
          name="search"
          id="Search"
          className="form-control w-100 py-3 borderColor2"
          placeholder="Type here to search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      <TransactionsData
        state={currentItems}
        setThisData={setThisData}
        setState={setState}
        type={"all"}
        criteria={{
          limit: held?.paginate?.limit,
          search: search ? search : "",
        }}
        mainState={state}
      />
      <MainPaginate handlePageClick={handlePageClick} pageCount={pageCount} />
      <BottomTab
        state={state}
        paginate={search ? held?.search_paginate : held?.paginate}
      />
      <TransactionDetails
        type={"all"}
        criteria={{
          limit: held?.paginate?.limit,
          search: search ? search : "",
        }}
        thisData={thisData}
        setThisData={setThisData}
      />
    </div>
  );
};
