import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";
import { useParams } from "react-router-dom";
import { GlobalState } from "../../Data/Context";
import { Buttons } from "../../Utils";
import { ModalComponents } from "../../Components";
// import UsersIcon from "../../Assets/users.svg";
import { Icon } from "@iconify/react";

const colors = ["#f1c40f", "#fd7e14", "#0d6efd", "#d63384", "#198754"];

const Administration = () => {
  let {
    setStateName,
    manager,
    marketer,
    developer,
    accountant,
    superadmin,
    numberWithCommas,
    getUserPrivileges,
    getPermission,
  } = useContext(GlobalState);
  useEffect(() => {
    setStateName("manage Admins");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getUserPrivileges("manager");
    getUserPrivileges("marketer");
    getUserPrivileges("developer");
    getUserPrivileges("accountant");
    getUserPrivileges("superadmin");
    getPermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let productArr = [
    {
      name: "Manage Admins",
      link: "/manager",
      color: "linear-gradient(90.18deg, #3199B7 -52.19%, #144468 81.92%)",
      textColor: "white",
      number: manager?.paginate?.total,
      icon: "subway:admin-1",
    },
    {
      name: "Manage Marketer",
      link: "/marketer",
      color: "linear-gradient(90deg, #DE0DE2 16.14%, #880EC2 101.45%)",
      textColor: "white",
      number: marketer?.paginate?.total,
      icon: "fluent-mdl2:telemarketer",
    },
    {
      name: "Manage Developer",
      link: "/developer",
      color: "linear-gradient(96.86deg, #F2E553 18.88%, #FF9900 125.77%)",
      number: developer?.paginate?.total,
      icon: "iconoir:developer",
    },
    {
      name: "Manage Accountant",
      link: "/accountant",
      color:
        "linear-gradient(102deg, rgba(54,40,3,1) 55%, rgba(224,168,25,1) 100%)",
      textColor: "black",
      number: accountant?.paginate?.total,
      icon: "material-symbols:account-balance-outline-rounded",
    },
    {
      name: "Manage Superadmin",
      link: "/superadmin",
      color: "linear-gradient(90deg, #E43369 16.14%, #C20E19 101.45%)",
      textColor: "white",
      number: superadmin?.paginate?.total,
      icon: "carbon:user-admin",
    },
    // {
    // 	name: "Manage Biller",
    // 	link: "/billers",
    // 	color: "linear-gradient(90.18deg, #6CB731 -52.19%, #0F5A16 81.92%)",
    // 	textColor: "white",
    // },
  ];

  let params = useParams();
  let [isOpen, setIsOpen] = useState(false),
    toggle = () => {
      setIsOpen(!isOpen);
    };

  return (
    <Container className="tw-pt-16">
      <div className="tw-w-5/6 tw-mx-auto tw-grid lg:tw-grid-cols-3 tw-gap-3">
        {productArr?.map((item, i) => (
          <div
            style={{
              background: item?.color || "white",
            }}
            className=" tw-cursor-pointer tw-flex tw-h-24 tw-shadow-lg tw-justify-between tw-items-center tw-px-3"
            key={i}
          >
            <div>
              <Icon
                color={colors[i % colors.length]}
                style={{
                  fontSize: "36px",
                }}
                icon={item?.icon}
              />
            </div>
            <Link
              to={
                item?.type === "button" ? "#" : `/${params?.page}${item?.link}`
              }
              className="d-flex align-items-center justify-content-center text2 myCursor text-decoration-none h-100 eachProduct fontReduceBig textTrunc p-2 p-md-0 py-3 py-md-0 h-100 flex-column"
            >
              <span className="tw-text-xl tw-text-white tw-font-medium tw-capitalize">
                {item?.name}
              </span>
              <span className="tw-pt-4 tw-text-base tw-text-right tw-text-white tw-font-medium Lexend">
                {item?.number ? numberWithCommas(item?.number) : ""}
              </span>
            </Link>
          </div>
        ))}
      </div>
      <ModalComponents title="assign role" isOpen={isOpen} back={toggle}>
        <div className="downH2 d-flex">
          <form className="w-100">
            <div className="form mb-3">
              <label htmlFor="bundle">Choose user</label>
              <select className="form-control rounded10 py-3 form-select">
                <option value="ringo">John Doe</option>
              </select>
            </div>
            <div className="form mb-3">
              <label htmlFor="bundle">Role</label>
              <select className="form-control rounded10 py-3 form-select">
                <option value="ringo">Marketter</option>
              </select>
            </div>
            <Buttons
              title={"assign"}
              css="btn-primary1 text-capitalize py-3 px-4 px-lg-5 mx-auto my-4"
              width={"w-50 w50"}
              onClick={toggle}
              style={{ borderRadius: "30px" }}
            />
          </form>
        </div>
      </ModalComponents>
    </Container>
  );
};

export default Administration;
