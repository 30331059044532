import React, { useState, useContext, useEffect } from "react";
import { Container } from "reactstrap";
import { Buttons } from "../../Utils";
import { GlobalState } from "../../Data/Context";
import { BottomTab } from "../../Components/LoadMore";
import { WalletHistoryList } from "../users/wallet/[id]";
import { ModalComponents } from "../../Components";
import { useNavigate } from "react-router-dom";
import { NumericFormat } from "react-number-format";

const Bonus = () => {
  let [isWallet, setIsWallet] = useState(false),
    toggleWallet = () => {
      setIsWallet(!isWallet);
    };

  let { setStateName } = useContext(GlobalState),
    navigate = useNavigate(),
    { auth, permission } = useContext(GlobalState);

  useEffect(() => {
    if (auth?.user?.privilege !== "superadmin") {
      let findTitle = "viewmanualtop";

      let findPermit = permission?.data?.find(
        (item) =>
          item?.slug === findTitle && item?.user?.includes(auth?.user?._id)
      );
      if (!findPermit) {
        navigate(-1);
      }
    }
  }, [auth?.user, permission?.data, navigate]);
  useEffect(() => {
    setStateName("manual wallet top up history");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="bg-white aboutScreen">
        <Container className="py-5">
          <Buttons
            title={"manual top up"}
            css="btn-primary1 text-capitalize py-3 px-4 px-lg-5"
            width={"w-25 w25"}
            onClick={toggleWallet}
            style={{ borderRadius: "30px" }}
          />
          <ManualHistory type={"credit"} />
        </Container>
      </div>
      <MakeWallet isOpen={isWallet} back={toggleWallet} />
    </>
  );
};

export default Bonus;

export const ManualHistory = ({ type }) => {
  let {
    wallet,
    getManualBonusHistory,
    getReload,
    nairaSign,
    numberWithCommas,
  } = useContext(GlobalState);

  let [data, setData] = useState(null),
    [search, setSearch] = useState("");

  useEffect(() => {
    if (wallet.isFound) setData(wallet.mainSearch);
    else setData(type === "debit" ? wallet?.manual_debit : wallet?.manual);
  }, [
    wallet?.manual,
    wallet?.manual_debit,
    type,
    wallet?.isFound,
    wallet?.mainSearch,
  ]);

  useEffect(() => {
    getReload();
    getManualBonusHistory("manage-funding", { type });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // let [loading, setLoading] = useState(false);


  // let handleLoadMore = async () => {
  //   setLoading(true);

  //   await getManualBonusHistory("manage-funding", {
  //     page: Number(
  //       type === "debit"
  //         ? wallet?.paginate_manual_debit?.nextPage
  //         : wallet?.paginate_manual?.nextPage
  //     ),
  //     // limit: Number(
  //     // 	type === "debit"
  //     // 		? wallet?.paginate_manual_debit?.nextPage *
  //     // 				wallet?.paginate_manual_debit?.limit
  //     // 		: wallet?.paginate_manual?.nextPage * wallet?.paginate_manual?.limit
  //     // ),
  //     type,
  //   });
  //   setLoading(false);
  // };

  useEffect(() => {
    if (search) {
      document.getElementById("Search").addEventListener("search", () => {
        getReload();
      });
      let handleSubmit = async () => {
        if (!search) return;

        await getManualBonusHistory("manage-funding", {
          search,
          type,
        });
      };
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  if (!data) return;
  // console.log({ data });

  return (
    <div className="pb-5 my-5">
      <div className="w-50 w50 mb-3">
        <input
          type="search"
          name="search"
          id="Search"
          className="form-control w-100 py-3 borderColor2"
          placeholder="Type here to search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <WalletHistoryList state={data} type={type}/>
      <div className="py-3">
        <h2 className="Lexend">
          Total: {nairaSign}{" "}
          {numberWithCommas(
            Number(
              type === "debit"
                ? wallet?.manualDebitTotal || 0
                : wallet?.manualCreditTotal || 0
            ).toFixed(2)
          )}
        </h2>
      </div>
      <BottomTab
        state={data}
        paginate={
          type === "debit"
            ? wallet?.paginate_manual_debit
            : wallet?.paginate_manual
        }
      />
      {/* <LoadMore
        next={
          type === "debit"
            ? wallet?.paginate_manual_debit?.next
            : wallet?.paginate_manual?.next
        }
        handleLoadMore={handleLoadMore}
        loading={loading}
      /> */}
    </div>
  );
};

export const MakeWallet = ({
  isOpen,
  back,
  user,
  debit = false,
  amount,
  title,
}) => {
  let {
    manageWallet,
    wallet,
    manualDirectDebit,
    nairaSign,
    numberWithCommas,
    commission,
  } = useContext(GlobalState);

  let init = {
      type: "wallet",
      user: user ? user : "",
      amount: "",
    },
    [state, setState] = useState(init),
    [loading, setLoading] = useState(false),
    [submit, setSubmit] = useState(false),
    textChange =
      (name) =>
      ({ target: { value } }) => {
        setState({ ...state, [name]: value });
      },
    handleSubmit = async (e) => {
      e?.preventDefault();
      setLoading(true);
      if (debit && title !== "commission") {
        await manualDirectDebit(state);
      } else
        await manageWallet(
          title || "wallet",
          state,
          title && debit ? "debit" : "add"
        );
      setLoading(false);
      setSubmit(true);
    };

  useEffect(() => {
    if (user) {
      setState({ ...state, user });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (wallet?.isAdded && submit) {
      back();
      setSubmit(false);
    }
    if (commission?.isAdded && submit) {
      back();
      setSubmit(false);
    }
    if (wallet?.isManualDebit && submit) {
      back();
      setSubmit(false);
    }
    if (commission?.isManualDebit && submit) {
      back();
      setSubmit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    wallet?.isAdded,
    submit,
    wallet?.isManualDebit,
    commission?.isAdded,
    commission?.isManualDebit,
  ]);

  return (
    <>
      <ModalComponents
        isOpen={isOpen}
        back={back}
        title={
          debit
            ? `debit user ${title || "wallet"}`
            : `top up ${title || "Wallet"}`
        }
      >
        <form onSubmit={handleSubmit}>
          {amount ? (
            <>
              <h4 className="Lexend fw-600 fontReduceBig text-capitalize">
                Current {title || "Wallet"} Balance: {nairaSign}{" "}
                {numberWithCommas(
                  Number(amount?.[title || "available"]).toFixed(2)
                )}
              </h4>
            </>
          ) : null}
          <WalletForm
            state={state}
            textChange={textChange}
            user={user}
            setState={setState}
          />
          <Buttons
            title={debit ? "debit user" : "top up"}
            css="btn-primary1 text-capitalize py-3 w-50 my-4 mx-auto"
            width={"w-50"}
            style={{ borderRadius: "30px" }}
            loading={loading}
            onClick={handleSubmit}
          />
        </form>
      </ModalComponents>
    </>
  );
};
// const MakeBundle = ({ isOpen, back }) => {
// 	return (
// 		<>
// 			<ModalComponents isOpen={isOpen} back={back} title="new Wallet">
// 				<form className="row mx-0" >
// 					<div className="form mb-3 col-6">
// 						<label htmlFor="wallet">Title</label>
// 						<input
// 							type="text"
// 							className="form-control rounded10 py-3"
// 							placeholder="Enter title"
// 						/>
// 					</div>
// 					<div className="form mb-3 col-6">
// 						<label htmlFor="wallet">Choose biller</label>
// 						<select className="form-control rounded10 py-3 form-select">
// 							<option value="ringo">Ringo</option>
// 						</select>
// 					</div>
// 					<div className="form mb-3 col-6">
// 						<label htmlFor="wallet">Choose network</label>
// 						<select className="form-control rounded10 py-3 form-select">
// 							<option value="mtn">MTN</option>
// 						</select>
// 					</div>
// 					<div className="form mb-3 col-6">
// 						<label htmlFor="wallet">Price</label>
// 						<input
// 							type="number"
// 							className="form-control rounded10 py-3"
// 							placeholder="200"
// 						/>
// 					</div>
// 					<div className="form mb-3 col-6">
// 						<label htmlFor="wallet">Network ID</label>
// 						<input
// 							type="text"
// 							className="form-control rounded10 py-3"
// 							placeholder="1234567"
// 						/>
// 					</div>
// 					<div className="form mb-3 col-6">
// 						<label htmlFor="wallet">Plan ID</label>
// 						<input
// 							type="text"
// 							className="form-control rounded10 py-3"
// 							placeholder="1234567"
// 						/>
// 					</div>
// 					<div className="form mb-3 col-6">
// 						<label htmlFor="wallet">Validity</label>
// 						<input
// 							type="text"
// 							className="form-control rounded10 py-3"
// 							placeholder="1"
// 						/>
// 					</div>
// 					<div className="form mb-3 col-6">
// 						<label htmlFor="wallet">HW Code</label>
// 						<input
// 							type="text"
// 							className="form-control rounded10 py-3"
// 							placeholder="1234"
// 						/>
// 					</div>
// 					<div className="form mb-3 col-6">
// 						<label htmlFor="wallet">Allowance</label>
// 						<input
// 							type="text"
// 							className="form-control rounded10 py-3"
// 							placeholder=""
// 						/>
// 					</div>
// 				</form>
// 				<Buttons
// 					title={"create wallet"}
// 					css="btn-primary1 text-capitalize py-3 w-50 my-4 mx-auto"
// 					width={"w-50"}
// 					style={{ borderRadius: "30px" }}
// 				/>
// 			</ModalComponents>
// 		</>
// 	);
// };

export const WalletForm = ({ state, textChange, user, setState }) => {
  let { nairaSignNeutral } = useContext(GlobalState);
  return (
    <form className="row mx-0">
      {!user && (
        <>
          {" "}
          <div className="form mb-3">
            <label htmlFor="type">Type</label>
            <select
              value={state?.type}
              onChange={textChange("type")}
              className="form-control rounded10 py-3 form-select"
            >
              <option value="">Choose type</option>
              <option value="wallet">Wallet ID</option>
              <option value="email">User Email</option>
              <option value="telephone">User Number</option>
            </select>
          </div>
          <div className="form mb-3">
            <label htmlFor="id">
              {state?.type === "email"
                ? "Email"
                : state?.type === "telephone"
                ? "Telephone"
                : "Wallet ID"}
            </label>
            <input
              type={state?.type === "email" ? "email" : "tel"}
              value={state?.user}
              onChange={textChange("user")}
              className="form-control rounded10 py-3"
              placeholder={
                state?.type === "email"
                  ? "example@mail.com"
                  : state?.type === "telephone"
                  ? "0800 0000 000"
                  : "12345678"
              }
              name={
                state?.type === "email"
                  ? "email"
                  : state?.type === "telephone"
                  ? "phone"
                  : "walletId"
              }
              maxLength={
                state?.type === "telephone"
                  ? 11
                  : state?.type === "wallet"
                  ? 8
                  : false
              }
            />
          </div>
        </>
      )}
      <div className="form mb-3">
        <label htmlFor="value">Amount</label>
        {/* <input
							type={"number"}
							placeholder="500"
							className="form-control py-3"
							value={state?.amount}
							onChange={textChange("amount")}
							min={0}
						/> */}
        <NumericFormat
          prefix={`${nairaSignNeutral} `}
          className="form-control rounded10 py-3"
          value={state.amount}
          placeholder="500"
          displayType="input"
          thousandSeparator={true}
          onValueChange={(val) =>
            setState({ ...state, amount: val?.floatValue })
          }
          min={0}
          inputMode="decimal"
          renderText={(value, props) => <span {...props}>{value}</span>}
          allowNegative={false}
        />
      </div>
    </form>
  );
};
