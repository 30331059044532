import axios from "axios";
import { toast } from "react-toastify";
import { returnErrors } from "../Reducer/ErrorReducer";
import {
  ADD_BANK,
  ADD_FAQS,
  ADD_FAQS_FAIL,
  ADD_PRIVACY_POLICY,
  ADD_SLIDER,
  ADD_SLIDER_FAIL,
  ADD_STARTIMES,
  ADD_TERMS_CONDITIONS,
  ADD_WITHDRAWAL,
  ADD_WITHDRAWAL_FAIL,
  DELETE_BANK,
  DELETE_FAQS,
  DELETE_NOTIFICATONS,
  DELETE_SLIDER,
  DELETE_STARTIMES,
  GET_ALL_WITHDRAWAL,
  GET_BANKS_ADDED,
  GET_FAQS,
  GET_PRIVACY_POLICY,
  GET_SLIDER,
  GET_SLIDER_FAIL,
  GET_STARTIMES,
  GET_STARTIMES_FAIL,
  GET_TERMS_CONDITIONS,
  GET_WITHDRAWAL,
  MANAGE_WALLET_PIN,
  SET_SUCCESS,
  TRANSFER_FUND_FAIL,
  UPDATE_FAQS,
  UPDATE_SLIDER,
  UPDATE_STARTIMES,
} from "./ActionTypes";
import { imageUpload } from "./AuthActions";
import {
  GET_NOTIFICATION_FROM,
  GET_NOTIFICATION_FROM_FAIL,
  GET_NOTIFICATION_TO,
  GET_NOTIFICATION_TO_FAIL,
  GET_USER_NOTIFICATION,
  GET_USER_NOTIFICATION_FAIL,
  SEARCH_NOTIFICATION,
  SEARCH_NOTIFICATION_FAIL,
  SEARCH_NOTIFICATION_LOADING,
  SEND_NOTIFICATION,
  SEND_NOTIFICATION_FAIL,
  UPDATE_NOTIFICATION,
} from "./ActionTypes";
import { getWalletBalance } from "./GeneralAction";
import { useURL } from "../Config";

export const getSlider = (data) => async (dispatch) => {
  try {
    let res = await axios.get(
      `/api/v2/slider?a=a${!data?.page ? "" : `&page=${data?.page}`}${
        !data?.limit ? "" : `&limit=${data?.limit}`
      }`,
      {
        baseURL: useURL,
      }
    );

    dispatch({
      type: GET_SLIDER,
      payload: res.data,
    });
  } catch (err) {
    if (err) console.log(err.response?.data?.error, { err });
    if (err?.response?.status === 429) toast.error(err?.response?.data);
    dispatch({
      type: GET_SLIDER_FAIL,
    });
  }
};

export const manageSlider = (data, id, type) => async (dispatch) => {
  try {
    let res;
    if (!id) {
      let media = await imageUpload([data?.image]),
        image = media[0];
      res = await axios.post(
        `/api/v2/slider`,
        { ...data, image },
        {
          baseURL: useURL,
        }
      );
    } else if (type === "delete")
      res = await axios.delete(`/api/v2/slider/${id}`, {
        baseURL: useURL,
      });
    else {
      let image;
      if (data?.image)
        if (!data?.image?._id) {
          let media = await imageUpload([data?.image]);
          image = media[0];
        }
      res = await axios.put(
        `/api/v2/slider/${id}`,
        {
          ...data,
          image: image || data?.image,
        },
        {
          baseURL: useURL,
        }
      );
    }
    dispatch({
      type: id
        ? type === "delete"
          ? DELETE_SLIDER
          : UPDATE_SLIDER
        : ADD_SLIDER,
      payload: type === "delete" ? data : res.data?.data,
    });

    dispatch({ type: SET_SUCCESS, payload: res?.data?.msg });
  } catch (err) {
    if (err?.response?.status === 429 || err?.response?.status === 405)
      toast.error(err?.response?.data ? err?.response?.data : err?.message);
    console.log({ err });
    let error = err.response?.data?.error;
    if (error) {
      dispatch(returnErrors({ error, status: err?.response?.status }));
    }
    dispatch({ type: ADD_SLIDER_FAIL });
  }
};

export const getNotificationsFrom =
  (data, search = false) =>
  async (dispatch) => {
    if (search) dispatch({ type: SEARCH_NOTIFICATION_LOADING, search: search });
    try {
      let res1 = await axios.get(
        `/api/v2/notification/manage-notifications?type=from
						${!data?.limit ? "" : `&limit=${data.limit}`}${
          !data?.page ? "" : `&page=${data.page}`
        }
						${!data?.search ? "" : `&search=${data?.search}`}
						`,
        {
          baseURL: useURL,
        }
      );
      dispatch({
        type: !search ? GET_NOTIFICATION_FROM : SEARCH_NOTIFICATION,
        payload: res1.data,
        search: search
          ? res1?.data?.search
            ? res1?.data?.search
            : search
          : "",
      });
    } catch (err) {
      if (err) console.log({ err });
      if (err) console.log(err?.response ? err?.response?.data : err?.message);
      dispatch({
        type: !search ? GET_NOTIFICATION_FROM_FAIL : SEARCH_NOTIFICATION_FAIL,
      });
    }
  };

export const getNotificationsTo = (data) => async (dispatch) => {
  try {
    // if (search) dispatch({ type: SEARCH_NOTIFICATION_LOADING, search: data?.search });
    let res1 = await axios.get(
      `/api/v2/notification/manage-notifications?type=to
						${!data?.limit ? "" : `&limit=${data.limit}`}${
        !data?.page ? "" : `&page=${data.page}`
      }
						${!data?.search ? "" : `&search=${data?.search}`}`,
      {
        baseURL: useURL,
      }
    );

    dispatch({
      type: GET_NOTIFICATION_TO,
      payload: res1.data,
      // search: data?.search ? res1?.data?.search ? res1?.data?.search : data?.search : ""
    });
  } catch (err) {
    if (err) console.log({ err });
    if (err) console.log(err?.response ? err?.response?.data : err?.message);
    dispatch({ type: GET_NOTIFICATION_TO_FAIL });
  }
};

export const getNotificationsUser = (data) => async (dispatch) => {
  try {
    let res1 = await axios.get(
      `/api/v2/notification/${data?.user}${
        !data?.limit ? "" : `?limit=${data.limit}`
      }${!data?.page ? "" : `&page=${data.page}`}`,
      {
        baseURL: useURL,
      }
    );

    dispatch({
      type: GET_USER_NOTIFICATION,
      payload: res1.data,
    });
  } catch (err) {
    if (err) console.log({ err });
    if (err) console.log(err?.response ? err?.response?.data : err?.message);
    dispatch({ type: GET_USER_NOTIFICATION_FAIL });
  }
};

export const sendNotification = (data) => async (dispatch) => {
  try {
    let image;
    if (data?.image)
      if (!data?.image?._id) {
        let media = await imageUpload([data?.image]);
        image = media[0];
      }
    let res = await axios.post(
      `/api/v2/notification/manage-notifications`,
      {
        ...data,
        image: image || data?.image,
      },
      {
        baseURL: useURL,
      }
    );

    dispatch({
      type: SEND_NOTIFICATION,
      payload: res.data?.data,
    });
    dispatch({ type: SET_SUCCESS, payload: res?.data?.msg });
  } catch (err) {
    if (err) console.log({ err });
    let error = err.response?.data?.error;
    if (error) {
      dispatch(returnErrors({ error, status: err?.response?.status }));
    }
    dispatch({ type: SEND_NOTIFICATION_FAIL });
    if (err) console.log(err?.response ? err?.response?.data : err?.message);
  }
};

export const manageNotify = (data, id, prior) => async (dispatch) => {
  try {
    let res;
    if (!id)
      res = await axios.post(
        `/api/v2/notification`,
        { ...data },
        {
          baseURL: useURL,
        }
      );
    else if (prior === "delete")
      res = await axios.delete(`/api/v2/notification/${id}`, {
        baseURL: useURL,
      });
    else if (prior === "status")
      res = await axios.put(
        `/api/v2/notification/manage-notifications/${id}?status=${
          data?.status === "play" ? "pause" : "play"
        }`,
        {},
        {
          baseURL: useURL,
        }
      );
    else if (prior)
      res = await axios.put(
        `/api/v2/notification/${id}`,
        {},
        {
          baseURL: useURL,
        }
      );
    else
      res = await axios.post(
        `/api/v2/notification/${id}`,
        {},
        {
          baseURL: useURL,
        }
      );
    dispatch({
      type: id
        ? prior === "delete"
          ? DELETE_NOTIFICATONS
          : UPDATE_NOTIFICATION
        : SEND_NOTIFICATION,
      payload: prior === "delete" ? data : res.data,
    });
    if (!id || ["delete", "status"]?.includes(prior))
      dispatch({ type: SET_SUCCESS, payload: res?.data?.msg });
  } catch (err) {
    if (err) console.log(err.response?.data?.error, { err });
    if (err?.response?.status === 429 || err?.response?.status === 405)
      toast.error(err?.response?.data ? err?.response?.data : err?.message);
    let error = err.response?.data?.error;
    if (error)
      if (!prior || prior !== "prior")
        dispatch(returnErrors({ error, status: err?.response?.status }));
    // error.forEach(error =>
    // 	error?.param
    // 		? error?.param !== "suggestion" &&
    // 		  toast.error(error.msg, { autoClose: false })
    // 		: toast.error(error.msg, { autoClose: false })
    // );
    dispatch({ type: SEND_NOTIFICATION_FAIL });
  }
};

export const readNotification = (data) => async (dispatch) => {
  try {
    let res = await axios.put(
      `/api/v2/notification/${data}`,
      {},
      {
        baseURL: useURL,
      }
    );

    dispatch({
      type: UPDATE_NOTIFICATION,
      payload: res.data?.data,
    });
  } catch (err) {
    if (err) console.log({ err });
    if (err) console.log(err?.response ? err?.response?.data : err?.message);
  }
};

export const manageBanks = (method, data) => async (dispatch) => {
  try {
    let res;
    if (method === "post")
      res = await axios.put(`/api/v2/user/account/manage-bank`, { ...data });
    else if (method === "delete")
      res = await axios.delete(`/api/v2/user/account/manage-bank`);
    else res = await axios.get(`/api/v2/user/account/manage-bank`);
    dispatch({
      type:
        method === "delete"
          ? DELETE_BANK
          : method === "post"
          ? ADD_BANK
          : GET_BANKS_ADDED,
      payload: method === "delete" ? data : res.data,
    });
    if (method !== "get")
      dispatch({ type: SET_SUCCESS, payload: res?.data?.msg });
  } catch (err) {
    if (err) console.log(err.response?.data?.error, { err });
    if (err?.response?.status === 429 || err?.response?.status === 405)
      toast.error(err?.response?.data ? err?.response?.data : err?.message);
    let error = err.response?.data?.error;
    if (error) dispatch(returnErrors({ error, status: err?.response?.status }));
    dispatch({ type: ADD_WITHDRAWAL_FAIL });
  }
};

export const manageWithdrawal = (method, data) => async (dispatch) => {
  try {
    let res;
    if (method === "post")
      res = await axios.post(
        `/api/v2/wallet/manage-withdrawal`,
        { ...data },
        {
          baseURL: useURL,
        }
      );
    else
      res = await axios.get(
        `/api/v2/wallet/manage-withdrawal${
          method === "all-withdrawal" ? "/all-withdrawal" : ""
        }`,
        {
          baseURL: useURL,
        }
      );
    dispatch({
      type:
        method === "post"
          ? ADD_WITHDRAWAL
          : method === "all-withdrawal"
          ? GET_ALL_WITHDRAWAL
          : GET_WITHDRAWAL,
      payload: res.data,
    });
    if (method === "post") {
      dispatch(getWalletBalance());
      dispatch({ type: SET_SUCCESS, payload: res?.data?.msg });
    }
  } catch (err) {
    if (err) console.log(err.response?.data?.error, { err });
    if (err?.response?.status === 429 || err?.response?.status === 405)
      toast.error(err?.response?.data ? err?.response?.data : err?.message);
    let error = err.response?.data?.error;
    if (error) dispatch(returnErrors({ error, status: err?.response?.status }));
    dispatch({ type: ADD_WITHDRAWAL_FAIL });
  }
};

export const manageWalletPin = (method, data) => async (dispatch) => {
  try {
    console.log({ method, data });
    let res;
    if (method === "post")
      res = await axios.post(
        `/api/v2/wallet/manage-wallet-pin`,
        { ...data },
        {
          baseURL: useURL,
        }
      );
    else if (method === "delete")
      res = await axios.delete(`/api/v2/wallet/manage-wallet-pin`, {
        data,
        baseURL: useURL,
      });
    else
      res = await axios.put(
        `/api/v2/wallet/manage-wallet-pin`,
        { ...data },
        {
          baseURL: useURL,
        }
      );
    dispatch({
      type: MANAGE_WALLET_PIN,
      payload: res.data,
    });
    dispatch(getWalletBalance());
    dispatch({ type: SET_SUCCESS, payload: res?.data?.msg });
  } catch (err) {
    if (err) console.log(err.response?.data?.error, { err });
    if (err?.response?.status === 429 || err?.response?.status === 405)
      toast.error(err?.response?.data ? err?.response?.data : err?.message);
    let error = err.response?.data?.error;
    if (error) dispatch(returnErrors({ error, status: err?.response?.status }));
    dispatch({ type: TRANSFER_FUND_FAIL });
  }
};

export const manageStartimes = (type, data) => async (dispatch) => {
  try {
    let res;
    if (type === "post") {
      res = await axios.post(
        `/api/v2/startime`,
        { ...data },
        {
          baseURL: useURL,
        }
      );
    } else if (type === "put")
      res = await axios.put(
        `/api/v2/startime?plan=${data?._id}`,
        { ...data },
        {
          baseURL: useURL,
        }
      );
    else if (type === "delete")
      res = await axios.delete(`/api/v2/startime?plan=${data?._id}`, {
        baseURL: useURL,
      });
    else {
      res = await axios.get(`/api/v2/startime`, {
        baseURL: useURL,
      });
    }
    dispatch({
      type:
        type === "delete"
          ? DELETE_STARTIMES
          : type === "post"
          ? ADD_STARTIMES
          : type === "put"
          ? UPDATE_STARTIMES
          : GET_STARTIMES,
      payload: type === "delete" ? data : res.data?.data,
    });
    if (type !== "get")
      dispatch({ type: SET_SUCCESS, payload: res?.data?.msg });
  } catch (err) {
    if (err?.response?.status === 429 || err?.response?.status === 405)
      toast.error(err?.response?.data ? err?.response?.data : err?.message);
    console.log({ err });
    let error = err.response?.data?.error;
    if (error) {
      dispatch(returnErrors({ error, status: err?.response?.status }));
    }
    dispatch({ type: GET_STARTIMES_FAIL });
  }
};

export const manageFaqs = (type, data, scene) => async (dispatch) => {
  try {
    let res;
    if (type === "post") {
      res = await axios.post(
        `/api/v2/faqs`,
        { ...data },
        {
          baseURL: useURL,
        }
      );
    } else if (type === "put")
      res = await axios.put(
        `/api/v2/faqs?id=${data?._id}`,
        { ...data },
        {
          baseURL: useURL,
        }
      );
    else if (type === "delete")
      res = await axios.delete(`/api/v2/faqs?id=${data?._id}`, {
        baseURL: useURL,
      });
    else {
      res = await axios.get(`/api/v2/faqs${scene ? `?type=${scene}` : ""}`, {
        baseURL: useURL,
      });
    }
    dispatch({
      type:
        type === "delete"
          ? DELETE_FAQS
          : type === "post"
          ? scene === "privacy"
            ? ADD_PRIVACY_POLICY
            : scene === "terms"
            ? ADD_TERMS_CONDITIONS
            : ADD_FAQS
          : type === "put"
          ? UPDATE_FAQS
          : scene === "privacy"
          ? GET_PRIVACY_POLICY
          : scene === "terms"
          ? GET_TERMS_CONDITIONS
          : GET_FAQS,
      payload: type === "delete" ? data : res.data?.data,
    });
    if (type !== "get")
      dispatch({ type: SET_SUCCESS, payload: res?.data?.msg });
  } catch (err) {
    if (err?.response?.status === 429 || err?.response?.status === 405)
      toast.error(err?.response?.data ? err?.response?.data : err?.message);
    console.log({ err });
    let error = err.response?.data?.error;
    if (error) {
      dispatch(returnErrors({ error, status: err?.response?.status }));
    }
    dispatch({ type: ADD_FAQS_FAIL });
  }
};
