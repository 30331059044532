import React, { useContext, useEffect, useState } from "react";
import { GlobalState } from "../../../Data/Context";
import { MainPaginate } from "../../../Components/Transactions";
import { BottomTab } from "../../../Components/LoadMore";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { Container } from "reactstrap";
import { BiCopy } from "react-icons/bi";
import { toast } from "react-toastify";
import { ModalComponents } from "../../../Components";
import ExportButtons from "../../../Utils/Export Functions/ExportButtons";

const MainPage = () => {
  let { step } = useParams(),
    navigate = useNavigate(),
    { auth, permission } = useContext(GlobalState);

  useEffect(() => {
    if (auth?.user?.privilege !== "superadmin") {
      let findTitle = "viewwalletfunding";

      let findPermit = permission?.data?.find(
        (item) =>
          item?.slug === findTitle && item?.user?.includes(auth?.user?._id)
      );
      if (!findPermit) {
        navigate(-1);
      }
    }
  }, [auth?.user, permission?.data, navigate]);

  if (!step) return;
  return (
    <div className="bg-white aboutScreen">
      <Container className="py-3 py-md-5">
        <h5 className="Lexend text-capitalize"> {step}</h5>

        <FundingPreview id={step} />
      </Container>
    </div>
  );
};

export default MainPage;

export const FundingPreview = ({ id }) => {
  const {
    funding,
    numberWithCommas,
    findProviderFunding,
    nairaSign,
    getReload,
    auth,
  } = useContext(GlobalState);

  let [search, setSearch] = useState(""),
    [state, setState] = useState(null);

  useEffect(() => {
    if (search) {
      document.getElementById("Search").addEventListener("search", () => {
        getReload();
      });
      let handleSubmit = async () => {
        if (!search) return;

        await findProviderFunding({
          search,
          provider: id,
        });
      };
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    getReload();
    findProviderFunding({ provider: id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // let [loading, setLoading] = useState(false),
  // let handleLoadMore = async () => {
  //     setLoading(true);
  //     if (search) {
  //       await findProviderFunding({
  //         page: Number(funding?.[`${id}_paginate`]?.nextPage),
  //         // limit: Number(
  //         // 	funding?.[`${id}_paginate`]?.nextPage *
  //         // 		funding?.[`${id}_paginate`]?.limit
  //         // ),
  //         provider: id,
  //         search,
  //       });
  //     } else
  //       await findProviderFunding({
  //         page: Number(funding?.[`${id}_paginate`]?.nextPage),
  //         // limit: Number(
  //         // 	funding?.[`${id}_paginate`]?.nextPage *
  //         // 		funding?.[`${id}_paginate`]?.limit
  //         // ),
  //         provider: id,
  //       });
  //     setLoading(false);
  //   },
  let [thisData, setThisData] = useState(null);

  useEffect(() => {
    setState(
      funding?.isFound
        ? funding?.mainSearch
        : id === "monnify"
        ? funding?.monnify
        : id === "manual"
        ? funding?.manual
        : id === "flutterwave"
        ? funding?.flutterwave
        : id === "budpay"
        ? funding?.budpay
        : id === "paystack"
        ? funding?.paystack
        : id === "transfer"
        ? funding?.transfer
        : []
    );
  }, [
    funding?.monnify,
    id,
    funding?.paystack,
    funding?.transfer,
    funding?.flutterwave,
    funding?.budpay,
    funding?.manual,
    funding?.isFound,
    funding?.mainSearch,
  ]);

  // let [range, setRange] = useState(10);
  const params = useParams();

  const [pageCount, setPageCount] = useState(
    Math.ceil(
      funding?.[`${id}_paginate`]?.total / funding?.[`${id}_paginate`]?.limit
    )
  );

  useEffect(() => {
    if (funding?.[`${id}_search_paginate`]) {
      setPageCount(
        Math.ceil(
          funding?.[`${id}_search_paginate`]?.total /
            funding?.[`${id}_search_paginate`]?.limit
        )
      );
    } else {
      setPageCount(
        Math.ceil(
          funding?.[`${id}_paginate`]?.total /
            funding?.[`${id}_paginate`]?.limit
        )
      );
    }
  }, [
    id,
    funding,
    funding?.[`${id}_search_paginate`],
    funding?.[`${id}_paginate`],
  ]);

  // const [itemOffset, setItemOffset] = useState(0);
  if (!state) return;

  // const endOffset = itemOffset + range;

  // const currentItems = state.slice(itemOffset, endOffset);
  // const pageCount = Math.ceil(state.length / range);

  const currentItems = state;

  const handlePageClick = async (event) => {
    // const newOffset = (event.selected * range) % state.length;
    // setItemOffset(newOffset);

    if (search) {
      await findProviderFunding({
        // page: Number(funding?.[`${id}_paginate`]?.nextPage),
        page: Number(event?.selected + 1),
        provider: id,
        search,
      });
    } else
      await findProviderFunding({
        // page: Number(funding?.[`${id}_paginate`]?.nextPage),
        page: Number(event?.selected + 1),
        provider: id,
      });
  };

  return (
    <>
      <div className="w-50 w50 mb-3">
        <input
          type="search"
          name="search"
          id="Search"
          className="form-control w-100 py-3 borderColor2"
          placeholder="Type here to search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className="tw-flex tw-justify-between tw-items-center">
        <div className="tw-flex-1">
          {/* <MainRanger range={range} setRange={setRange} /> */}
        </div>
        <ExportButtons
          tableId="wallet-funding"
          data={currentItems}
          fileName={`${params?.page} ${params?.id ? `(${params?.id})` : ""}`}
        />
      </div>

      <div className="py-5">
        {/* <div className="bland row mx-0 py-3 px-0 text-capitalize">
					<div className="col textTrunc fontReduce fw-bold Lexend d-none d-md-flex">
						S/N
					</div>
					<div className="col textTrunc fontReduce fw-bold Lexend d-none d-md-flex">
						Reference
					</div>
					<div className="col textTrunc fontReduce fw-bold Lexend d-none d-md-flex">
						User
					</div>
					<div className="col textTrunc fontReduce fw-bold Lexend">Amount</div>
					<div className="col textTrunc fontReduce fw-bold Lexend d-none d-md-flex">
						Previous Balance
					</div>
					<div className="col textTrunc fontReduce fw-bold Lexend d-none d-md-flex">
						New Balance
					</div>
					<div className="col textTrunc fontReduce fw-bold Lexend">
						date & time
					</div>
				</div>
				<div className="bland2 row mx-0">
					{currentItems?.map((item, index) => (
						<div
							onClick={() => setThisData(item)}
							key={index}
							className="row mx-0 py-3 px-0 border-bottom myCursor">
							<div className="col textTrunc fontReduce2 my-auto d-none d-md-flex">
								{index + 1}
							</div>
							<div className="col textTrunc fontReduce2 my-auto textTrunc textTrunc2 d-none d-md-flex">
								{item?.data?.reference ||
									item?.data?.flw_ref ||
									item?.data?.transactionReference ||
									item?.wallet?.item_id}
							</div>
							<div className="col textTrunc fontReduce2 my-auto textTrunc textTrunc3 d-none d-md-flex">
								{item?.user?.lastName} {item?.user?.firstName}
							</div>
							<div className="col textTrunc fontReduce2 my-auto">
								{nairaSign} {numberWithCommas(Number(item?.amount).toFixed(2))}
							</div>
							<div className="col textTrunc fontReduce2 my-auto d-none d-md-flex">
								{nairaSign}{" "}
								{numberWithCommas(Number(item?.wallet?.prevBalance).toFixed(2))}
							</div>
							<div className="col textTrunc fontReduce2 my-auto d-none d-md-flex">
								{nairaSign}{" "}
								{numberWithCommas(Number(item?.wallet?.balance).toFixed(2))}
							</div>
							<div className="col textTrunc fontReduce2 my-auto">
								{moment(item?.createdAt).format("DD/MM/YYYY hh:mm A")}
							</div>
						</div>
					))}
				</div> */}
        <FundingTable currentItems={currentItems} setThisData={setThisData} />
        <MainPaginate handlePageClick={handlePageClick} pageCount={pageCount} />
        {id === "budpay" && funding?.budpay_gain && (
          <>
            <div className="py-3">
              <h2 className="Lexend">
                Total Profit: {nairaSign}{" "}
                {numberWithCommas(Number(funding?.budpay_gain || 0).toFixed(2))}
              </h2>
            </div>
          </>
        )}
        <BottomTab state={state} paginate={funding?.[`${id}_paginate`]} />
        {/* <LoadMore
          next={funding?.[`${id}_paginate`]?.next}
          handleLoadMore={handleLoadMore}
          loading={loading}
        /> */}
      </div>
      <ModalComponents
        isOpen={thisData ? true : false}
        toggle={() => setThisData(false)}
        title="Funding details"
      >
        <div className="downH2 d-flex flex-column">
          <p className="text-capitalize border-bottom d-flex justify-content-between">
            <span>Reference: </span>
            <span
              className="fontInherit Lexend force-d-flex myCursor"
              onClick={
                thisData?.data?.reference ||
                thisData?.data?.flw_ref ||
                thisData?.data?.transactionReference ||
                thisData?.wallet?.item_id
                  ? () => {
                      navigator.clipboard
                        .writeText(
                          thisData?.data?.reference ||
                            thisData?.data?.flw_ref ||
                            thisData?.data?.transactionReference ||
                            thisData?.wallet?.item_id
                        )
                        .then(
                          () => {
                            toast.info("Copied", { autoClose: 2000 });
                          },
                          (err) => {
                            toast.warn(`Could not copy: ${err}`, {
                              autoClose: 2000,
                            });
                          }
                        );
                    }
                  : null
              }
            >
              {thisData?.data?.reference ||
                thisData?.data?.flw_ref ||
                thisData?.data?.transactionReference ||
                thisData?.wallet?.item_id}
              <BiCopy />
            </span>{" "}
          </p>
          {thisData?.provider === "manual" && (
            <p className="text-capitalize border-bottom d-flex justify-content-between">
              <span>Mode: </span>
              <span className={`fontInherit Lexend text-uppercase`}>
                {thisData?.wallet?.type}
              </span>{" "}
            </p>
          )}
          <p className="text-capitalize border-bottom d-flex justify-content-between">
            <span>type: </span>
            <span
              className={`fontInherit Lexend text-uppercase text-success2 text-success-2 text-success`}
            >
              {thisData?.type?.replace(/[_]/g, " ")}
            </span>{" "}
          </p>
          {thisData?.channel && thisData?.channel !== "auth" && (
            <p className="text-capitalize border-bottom d-flex justify-content-between">
              <span>Channel: </span>
              <span className="fontInherit Lexend">
                {thisData?.channel}
              </span>{" "}
            </p>
          )}
          {auth?.user?.isAdmin && (
            <p className="border-bottom d-flex justify-content-between">
              <span className="text-capitalize">
                User{thisData?.user?.initEmail && <>(Deleted)</>}:{" "}
              </span>
              <span>
                <span className="fontInherit Lexend d-block text-capitalize">
                  {thisData?.user?.lastName} {thisData?.user?.firstName}
                </span>{" "}
                <span className="fontInherit Lexend d-block">
                  {thisData?.user?.initTelephone || thisData?.user?.telephone}
                </span>{" "}
                <span className="fontInherit Lexend d-block">
                  {thisData?.user?.initEmail || thisData?.user?.email}
                </span>{" "}
                {thisData?.user?.privilege && (
                  <span className="fontInherit Lexend d-block tw-uppercase">
                    {thisData?.user?.privilege}
                  </span>
                )}
              </span>
            </p>
          )}
          <p className="text-capitalize border-bottom d-flex justify-content-between">
            <span>Amount: </span>
            <span className="fontInherit Lexend">
              {nairaSign}{" "}
              {thisData?.amount
                ? numberWithCommas(Number(thisData?.amount).toFixed(2))
                : 0}
            </span>{" "}
          </p>
          <p className="text-capitalize border-bottom d-flex justify-content-between">
            <span>{thisData?.status ? "Previous " : "Initial "} Balance: </span>
            <span className="fontInherit Lexend">
              {nairaSign}{" "}
              {thisData?.wallet?.prevBalance
                ? numberWithCommas(
                    Number(thisData?.wallet?.prevBalance).toFixed(2)
                  )
                : 0}
            </span>{" "}
          </p>
          <p className="text-capitalize border-bottom d-flex justify-content-between">
            <span>{thisData?.status ? "Current " : "Expected "}Balance: </span>
            <span className="fontInherit Lexend">
              {nairaSign}{" "}
              {thisData?.wallet?.balance
                ? numberWithCommas(Number(thisData?.wallet?.balance).toFixed(2))
                : 0}
            </span>{" "}
          </p>
          {thisData?.wallet2?.user && (
            <>
              {auth?.user?.isAdmin && (
                <p className="border-bottom d-flex justify-content-between">
                  <span className="text-capitalize">Recipient: </span>
                  <span>
                    <span className="fontInherit Lexend d-block text-capitalize">
                      {thisData?.wallet2?.user?.lastName}{" "}
                      {thisData?.wallet2?.user?.firstName}
                    </span>{" "}
                    <span className="fontInherit Lexend d-block">
                      {thisData?.wallet2?.user?.telephone}
                    </span>{" "}
                    <span className="fontInherit Lexend d-block">
                      {thisData?.wallet2?.user?.email}
                    </span>{" "}
                    {thisData?.wallet2?.user?.privilege && (
                      <span className="fontInherit Lexend d-block tw-uppercase">
                        {thisData?.wallet2?.user?.privilege}
                      </span>
                    )}
                  </span>
                </p>
              )}
              <p className="text-capitalize border-bottom d-flex justify-content-between">
                <span>
                  Recipient {thisData?.status ? "Previous " : "Initial "}{" "}
                  Balance:{" "}
                </span>
                <span className="fontInherit Lexend">
                  {nairaSign}{" "}
                  {thisData?.wallet2?.prevBalance
                    ? numberWithCommas(
                        Number(thisData?.wallet2?.prevBalance).toFixed(2)
                      )
                    : 0}
                </span>{" "}
              </p>
              <p className="text-capitalize border-bottom d-flex justify-content-between">
                <span>
                  Recipient {thisData?.status ? "Current " : "Expected "}
                  Balance:{" "}
                </span>
                <span className="fontInherit Lexend">
                  {nairaSign}{" "}
                  {thisData?.wallet2?.balance
                    ? numberWithCommas(
                        Number(thisData?.wallet2?.balance).toFixed(2)
                      )
                    : 0}
                </span>{" "}
              </p>
            </>
          )}
          <p className="text-capitalize border-bottom d-flex justify-content-between">
            <span>date&time: </span>
            <span className="fontInherit Lexend">
              {moment(thisData?.createdAt).format("DD/MM/YYYY hh:mm A")}
            </span>{" "}
          </p>
          <p className="text-capitalize border-bottom d-flex justify-content-between">
            <span>Status: </span>
            <span
              className={`fontInherit Lexend ${
                thisData?.status
                  ? "text-success2 text-success-2 text-success"
                  : "text-danger2"
              }`}
            >
              {thisData?.statusText?.replace(/[_]/g, " ")}
            </span>{" "}
          </p>
          {auth?.user?.isAdmin && thisData?.authorizer && (
            <>
              <p className="border-bottom d-flex justify-content-between">
                <span className="text-capitalize">Admin: </span>
                <span>
                  <span className="fontInherit Lexend d-block text-capitalize">
                    {thisData?.authorizer?.lastName}{" "}
                    {thisData?.authorizer?.firstName}
                  </span>{" "}
                  <span className="fontInherit Lexend d-block">
                    {thisData?.authorizer?.telephone}
                  </span>{" "}
                  <span className="fontInherit Lexend d-block">
                    {thisData?.authorizer?.email}
                  </span>{" "}
                  {thisData?.authorizer?.privilege && (
                    <span className="fontInherit Lexend d-block tw-uppercase">
                      {thisData?.authorizer?.privilege}
                    </span>
                  )}
                </span>
              </p>
            </>
          )}
          <div className="ms-auto d-flex align-items-center">
            <button
              className="btn btn-danger-2 btn-danger2 d-block ms-auto me-2"
              onClick={() => setThisData(false)}
            >
              Close
            </button>
          </div>
        </div>
      </ModalComponents>
    </>
  );
};

export const FundingTable = ({ currentItems, setThisData }) => {
  let { nairaSign, numberWithCommas } = useContext(GlobalState);
  return (
    <div className="tw-relative tw-overflow-x-auto tw-overflow-y-visible tw-min-h-52">
      <table id="wallet-funding" className="tw-w-full tw-text-sm tw-text-left">
        <thead className="tw-text-xs tw-text-gray-700 tw-captalize bland tw-px-1">
          <tr>
            <th scope="col" className="tw-px-6 tw-py-6 Lexend tw-capitalize">
              S/N
            </th>
            <th scope="col" className="tw-px-6 tw-py-6 Lexend tw-capitalize">
              Reference
            </th>
            <th scope="col" className="tw-px-6 tw-py-6 Lexend tw-capitalize">
              User
            </th>
            <th scope="col" className="tw-px-6 tw-py-6 Lexend tw-capitalize">
              Amount
            </th>
            <th scope="col" className="tw-px-6 tw-py-6 Lexend tw-capitalize">
              Previous Balance
            </th>
            <th scope="col" className="tw-px-6 tw-py-6 Lexend tw-capitalize">
              New Balance
            </th>
            <th scope="col" className="tw-px-6 tw-py-6 Lexend tw-capitalize">
              date & time
            </th>
          </tr>
        </thead>
        <tbody>
          {currentItems?.map((it, i) => (
            <tr
              onClick={() => setThisData(it)}
              className="bg-white tw-border-b tw-text-xs px-1 py-3 myCursor"
              key={i}
            >
              <td className="tw-w-4 tw-p-4">{i + 1}</td>
              <td className="tw-px-6 tw-py-6">
                {it?.data?.reference ||
                  it?.data?.flw_ref ||
                  it?.data?.transactionReference ||
                  it?.wallet?.item_id}
              </td>
              <td className="tw-px-6 tw-py-6">
                {it?.user?.lastName} {it?.user?.firstName}
              </td>
              <td className="tw-px-6 tw-py-6">
                {nairaSign} {numberWithCommas(Number(it?.amount).toFixed(2))}
              </td>
              <td className="tw-px-6 tw-py-6">
                {nairaSign}{" "}
                {numberWithCommas(Number(it?.wallet?.prevBalance).toFixed(2))}
              </td>
              <td className="tw-px-6 tw-py-6">
                {nairaSign}{" "}
                {numberWithCommas(Number(it?.wallet?.balance).toFixed(2))}
              </td>
              <td className="tw-px-6 tw-py-6">
                {moment(it?.createdAt).format("DD/MM/YYYY hh:mm A")}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
