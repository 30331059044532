import { useContext, useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { GlobalState } from "../Data/Context";
// import user from "../Assets/avatar3.png";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { VscBellDot } from "react-icons/vsc";
import { FaCircle } from "react-icons/fa";

const DefaultHeader = () => {
  const { auth, stateName, notifications, getNotificationsTo } =
    useContext(GlobalState);
  let navigate = useNavigate(),
    [counter, setCounter] = useState(0),
    param = useLocation();

  useEffect(() => {
    getNotificationsTo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (notifications?.notify && notifications?.notify?.length > 0) {
      let res = notifications?.notify?.filter(
        (item) => !item?.isNotifiedTo?.includes(auth?.user?._id)
      );
      if (res?.length > 0) setCounter(1);
      else setCounter(0);
    }
    return () => setCounter(0);
  }, [notifications?.notify, auth?.user]);

  return (
    <section className="border-bottom bg-white">
      <div className="pe-md-5 px-2 ps-md-3 d-flex align-items-center barFont justify-content-between defaultHead">
        <div className="w-100 d-flex align-items-center ps-md-auto ps-4">
          {param.pathname === "/dashboard" ? (
            <></>
          ) : (
            <BiArrowBack
              className="ms-2 ms-md-5 myCursor"
              onClick={() => navigate(-1)}
            />
          )}
          <h3 className="ms-2 text-capitalize my-0 fontReduceBig Lexend">
            {stateName ? stateName : param.pathname.split("/")[1]}
          </h3>
        </div>
        <header className="d-flex align-items-center my-auto justify-content-end container">
          <div className="position-relative">
            <VscBellDot
              className="myCursor"
              size={24}
              onClick={() => navigate("/notifications")}
            />
            {counter ? (
              Number(counter) > 0 ? (
                <FaCircle
                  style={{
                    top: "0px",
                    right: "-1px",
                  }}
                  size={12}
                  className="text-danger2 position-absolute"
                />
              ) : null
            ) : null}
          </div>
          <Link
            className="text-dark text-decoration-none d-flex align-items-center"
            to="/settings"
          >
            {/* <img
							src={auth?.user?.avatar?.url ? auth?.user?.avatar?.url : user}
							alt={`${auth?.user?.fullname} `}
							style={{
								height: "3.5rem",
								width: "3.5rem",
								objectFit: "cover",
								objectPosition: "center 15%",
							}}
							className="rounded-circle img-fluid mx-md-3"
						/> */}
            <AvatarImg user={auth?.user} />
            <h6 className="d-none d-md-flex">
              {auth?.user?.firstName} {auth?.user?.lastName}
            </h6>
          </Link>
        </header>
      </div>
    </section>
  );
};

export default DefaultHeader;

export const TabHeader = ({ title, subtitle }) => {
  let { page } = useParams();
  return (
    <>
      <h3 className="text-capitalize lexendFont">{title ? title : page}</h3>
      <p className="text-capitalize">
        {subtitle
          ? subtitle
          : `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dicta,
				impedit.`}
      </p>
    </>
  );
};

export const ModalComponents = ({
  isOpen,
  toggle,
  title,
  children,
  back,
  size,
  notHeader,
  borderNone,
  success,
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);
  return (
    <Modal
      isOpen={isOpen}
      toggle={back ? back : toggle}
      centered
      scrollable
      size={size}
      backdrop={true}
      className={notHeader ? "p-0 overflow-hidden" : ""}
    >
      {!notHeader && (
        <ModalHeader
          toggle={toggle}
          className={`${borderNone ? borderNone : ""} ${
            success ? success : ""
          } text-capitalize Lexend textColor2`}
        >
          {back && <BiArrowBack className="me-3 myCursor" onClick={back} />}
          {title}
        </ModalHeader>
      )}
      <ModalBody className={notHeader ? "p-0 overflow-hidden" : ""}>
        {children}
      </ModalBody>
    </Modal>
  );
};

export const AvatarImg = ({ user, style }) => {
  return (
    <div
      class="tw-relative tw-inline-flex tw-items-center tw-justify-center tw-w-16 tw-h-10 tw-overflow-hidden tw-border-blue-600 tw-border tw-bg-gray-100 tw-rounded-full tw-dark:bg-gray-600 tw-mx-1"
      style={style || null}
    >
      <span
        class={`tw-font-medium tw-text-gray-600 tw-dark:text-gray-300 ${
          style ? "tw-text-5xl tw-font-bold" : ""
        }`}
      >
        {user?.firstName?.slice(0, 1) || "H"}
        {""}
        {user?.lastName?.slice(0, 1) || "W"}
      </span>
    </div>
  );
};
