import React, { useContext, useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Container } from "reactstrap";
// import { BsEye, BsEyeSlash } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { GlobalState } from "../Data/Context";
import { Buttons, EyeToggle } from "../Utils";
import bg1 from "../Assets/Group3610.png";
import image from "../Assets/Group 42903.png";
import { VerifyMail } from "./forget-password";
import Loginbg from "../Assets/loginbg.png";
import Lock from "../Assets/lock.svg";

const Login = () => {
  const {
    loginUser,
    // getSetTempUser,
    auth,
    loginUser2FA,
  } = useContext(GlobalState);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let [typePass, setTypePass] = useState(false),
    init = {
      email: "",
      password: "",
    },
    [stateData, setStateData] = useState(init),
    [loading, setLoading] = useState(false),
    [submit, setSubmit] = useState(false),
    [step, setStep] = useState(1),
    [code, setCode] = useState(""),
    navigate = useNavigate(),
    textChange =
      (name) =>
      ({ target: { value } }) => {
        setStateData({ ...stateData, [name]: value });
      };

  let handleSubmit = async (e) => {
      e?.preventDefault();
      if (!stateData?.password || !stateData?.email) return;
      setLoading(true);
      await loginUser(stateData);
      setSubmit(true);
      // getSetTempUser("auth");
      setLoading(false);
      // navigate("/");
    },
    handleSubmit2 = async (e) => {
      e?.preventDefault();
      if (!code) return;
      setLoading(true);
      await loginUser2FA({ token: code });
      setSubmit(true);
      // getSetTempUser("auth");
      setLoading(false);
    };

  // useEffect(() => {
  //   if (auth?.is2FA) {
  //     setStep(2);
  //   }
  // }, [auth?.is2FA]);

  useEffect(() => {
    if (submit && auth?.isLoggedIn) {
      setSubmit(false);
      navigate("/");
    }
    if (submit && auth?.is2FA) {
      setSubmit(false);
      setStep(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit, auth?.isLoggedIn]);

  useEffect(() => {
    code?.length === 6 && handleSubmit2();
  }, [code]);



  return (
    <>
      <div
        style={{
          background: `url(${Loginbg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="tw-w-full tw-h-screen tw-relative tw-px-10"
      >
        <div className="tw-flex tw-justify-between tw-h-full tw-items-center container">
          <div className="tw-relative tw-h-full">
            <div className="tw-mx-auto tw-hidden lg:tw-block tw-absolute tw-w-[500px] tw-bottom-20">
              <h1 className="tw-text-6xl general tw-text-black tw-font-bold tw-text-center">
                Pay your bills <br /> without{" "}
                <span className="tw-text-6xl general tw-text-[#2F75FD] tw-font-extrabold tw-text-center">
                  Stress
                </span>
              </h1>
            </div>
          </div>

          <div className="tw-bg-white tw-rounded-2xl tw-bg-opacity-20 tw-backdrop-blur-[15px] tw-shadow-md tw-pt-24 tw-px-14 tw-pb-16 lg:tw-w-[508px] tw-w-full">
            <div onClick={() => navigate("/")} className="">
              <img
                src={process.env.REACT_APP_IMAGE_URL}
                alt={`logo ${process.env.REACT_APP_NAME}`}
                className="tw-h-16"
              />
            </div>
            <div>
              <h3 className="tw-text-capitalize tw-py-5 tw-font-semibold general tw-text-black tw-text-3xl tw-text-left">
                Login
              </h3>
              <small className="mb-4 tw-text-base tw-font-medium d-block">
                {step !== 1
                  ? `Please input the token in your Authenticator App`
                  : `Put in your email and password to get started`}
              </small>
              {step !== 1 ? (
                <Enable2FAComponent
                  handleSubmit={handleSubmit2}
                  loading={loading}
                  code={code}
                  setCode={setCode}
                  subtext={"Enter code generated from your Authenticator App"}
                />
              ) : (
                <form className="mt-1" onSubmit={handleSubmit}>
                  <div className="mb-8">
                    <div
                      style={{
                        borderRadius: "8px",
                        border: "2px solid #000",
                        backdropFilter: "blur(2px)",
                      }}
                      className="tw-relative tw-flex tw-items-center tw-w-full tw-h-14 tw-bg-white tw-mb-6"
                    >
                      <div className="tw-absolute tw-top-4 tw-left-4">
                        <AiOutlineMail size={15} />
                      </div>

                      <input
                        type="email"
                        required
                        name="email"
                        className="tw-h-full tw-text-black tw-pl-12 tw-w-full tw-font-medium tw-text-xl general tw-rounded-lg"
                        placeholder="Email"
                        value={stateData.email}
                        onChange={textChange("email")}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      borderRadius: "8px",
                      border: " 2px solid #000",
                      backdropFilter: "blur(2px)",
                    }}
                    className="tw-relative tw-flex tw-gap-3 tw-items-center tw-w-full tw-h-14 tw-bg-white"
                  >
                    <img src={Lock} alt="" className="tw-left-4 tw-absolute" />
                    <div className="tw-flex tw-justify-between tw-h-full tw-w-full tw-pl-12 tw-items-center">
                      <input
                        type={typePass ? "text" : "password"}
                        required
                        style={{
                          border: "none !important",
                        }}
                        name="password"
                        className="tw-w-full tw-h-full tw-border-none tw-text-black tw-font-normal tw-text-xl general tw-rounded-lg"
                        value={stateData.password}
                        onChange={textChange("password")}
                        placeholder="Password"
                      />
                      <div className="tw-absolute tw-top-4 tw-right-4">
                        <EyeToggle
                          typePass={typePass}
                          setTypePass={setTypePass}
                        />
                      </div>
                    </div>
                  </div>
                  <p className="tw-text-xs general tw-font-medium tw-text-black tw-pt-8">
                    Forgot Password?{" "}
                    <Link
                      to={`/forget-password`}
                      className="tw-text-xs general tw-font-bold tw-text-black tw-underline"
                    >
                      Recover Password
                    </Link>{" "}
                  </p>
                  <div className="tw-mt-8">
                    <Buttons
                      onClick={handleSubmit}
                      loading={loading}
                      title={"sign in"}
                      css="btn-primary1 text-capitalize py-3 w-100 my-2"
                    />
                  </div>
                  <div className="my-3 d-flex align-items-center justify-content-between">
                    <Link
                      to={`/activate`}
                      className="btn text-capitalize Lexend fw-600"
                    >
                      verify mail
                    </Link>{" "}
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
  
};

export default Login;

export const DefaultAuthComponent = ({ children }) => {
  return (
    <>
      <div className="bg-white">
        <div className="row mx-0">
          <Container
            className="heroBg col-md-7"
            style={{
              background: `url(${bg1})`,
            }}
          >
            <section className="d-flex justify-content-center align-items-center fullscreen2 py-3 py-md-5">
              <div
                className="m-auto px-3 py-5 rounded w-100"
                style={{
                  maxWidth: "550px",
                }}
              >
                <Container className="px-lg-5 px-3">{children}</Container>
              </div>
            </section>
          </Container>
          <div className="col-lg-5 d-none d-lg-flex fullscreen2 ">
            <img
              src={image}
              alt="Banner"
              className="img-fluid m-auto h-100 imgFluid"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export const Enable2FAComponent = ({
  handleSubmit,
  code,
  setCode,
  loading,
  subtext,
}) => {
  return (
    <>
      <h3 className="text-capitalize">OTP</h3>
      <form onSubmit={handleSubmit}>
        <VerifyMail
          code={code}
          setCode={setCode}
          text="confirm Token"
          numInputs={6}
          subtext={subtext}
          isInputSecure
        />
        <Buttons
          onClick={handleSubmit}
          loading={loading}
          css="btn btn-primary1 text-capitalize py-3 w-100 my-4"
          title="confirm Token"
          type="button"
        />
      </form>
    </>
  );
};
