import React, { useContext, useEffect, useState } from "react";
import { GlobalState } from "../../Data/Context";
import { Container } from "reactstrap";
import moment from "moment";
import { BiCopy, BiDotsHorizontalRounded } from "react-icons/bi";
import { RiShieldStarFill } from "react-icons/ri";
import { Buttons, EmptyComponent } from "../../Utils";
import { useNavigate, useParams } from "react-router-dom";
import { RoundCharts } from "../Charts";
import { toast } from "react-toastify";
import { ModalComponents } from "../DefaultHeader";
import { FaCcMastercard, FaCcVisa } from "react-icons/fa";
import LoadMore, { BottomTab } from "../LoadMore";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import axios from "axios";
import { useMonnifyPayment } from "react-monnify";
import { usePaystackPayment } from "react-paystack";
import { WalletForm } from "../../Pages/wallets/manual";
import { MainPaginate, MainRanger } from "../Transactions";
import Icon1 from "../../Assets/sales.svg";
import SterlingLogo from "../../Assets/sterling-bank-plc.png";
import WemaLogo from "../../Assets/wema-bank.png";
import MoniepointLogo from "../../Assets/moniepoint.png";
import PremiumTrust from "../../Assets/premium-trust-logo.png";
import { IconContext } from "react-icons";
import { NumericFormat } from "react-number-format";
import { TransactionPinBox } from "../Products/AutoBuy";
import ExportButtons, {
  ExportButtons2,
} from "../../Utils/Export Functions/ExportButtons";
// import { useBudPayPayment, closeBudPayPaymentModal } from "budpay-react-v2";

export let colorArr = ["#E9F9F9", "#C0938E", "#000000", "#B3CEDE"];

const Wallets = () => {
  let {
    setStateName,
    wallet,
    numberWithCommas,
    usecase,
    converterServices,
    gateway,
    getWalletBalance,
  } = useContext(GlobalState);
  let [isTransfer, setIsTransfer] = useState(false);
  let toggleTransfer = () => {
      setIsTransfer(!isTransfer);
    },
    navigate = useNavigate();
  let toggleWithdraw = () => {
    navigate("/wallets/withdrawal");
  };
  let [isVirtual, setIsVirtual] = useState(false);
  let toggleVirtual = () => {
    setIsVirtual(!isVirtual);
  };
  let [isCard, setIsCard] = useState("");
  let toggleCard = () => {
    setIsCard("");
  };
  let [isCardType, setIsCardType] = useState("");
  let toggleCardType = () => {
    setIsCardType(!isCardType);
  };
  let [moveType, setMoveType] = useState(false),
    [key, setKey] = useState({
      flutterwave: process.env.REACT_APP_FLUTTERWAVE_PUBLIC_KEY,
      paystack: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
      monnify: process.env.REACT_APP_MONNIFY_API_KEY,
      monnifyContract: process.env.REACT_APP_MONNIFY_CONTRACT_CODE,
      budpay: process.env.REACT_APP_BUDPAY_PUBLIC_KEY,
    }),
    [thisData, setThisData] = useState(false),
    [active, setActive] = useState(0);

  useEffect(() => {
    if (gateway) {
      let value = gateway?.data?.find((item) =>
        item?.name?.includes("flutterwave")
      );
      if (value)
        if (value?.thirdApiKey)
          setKey({ ...key, flutterwave: value?.thirdApiKey });
      let value2 = gateway?.data?.find((item) =>
        item?.name?.includes("paystack")
      );
      if (value2)
        if (value2?.thirdApiKey)
          setKey({ ...key, paystack: value2?.thirdApiKey });
      let value3 = gateway?.data?.find((item) =>
        item?.name?.includes("monnify")
      );
      if (value3)
        if (value3?.apiKey)
          setKey({
            ...key,
            monnify: value3?.apiKey,
            monnifyContract: value3?.thirdApiKey,
          });
      let value4 = gateway?.data?.find((item) =>
        item?.name?.includes("budpay")
      );
      if (value4)
        if (value4?.thirdApiKey)
          setKey({ ...key, budpay: value4?.thirdApiKey });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gateway]);

  useEffect(() => {
    setStateName("my account");
    converterServices("get", "banks");
    getWalletBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="bg-white aboutScreen">
      <Container className="py-5">
        <div className="tw-grid lg:tw-grid-cols-3 xl:tw-grid-cols-4 tw-gap-8 tw-mb-10 tw-w-[90%] tw-mx-auto">
          <div
            style={{
              background:
                // "linear-gradient(90deg, rgba(228, 51, 105, 0.7) 16.14%, rgba(194, 14, 25, 0.7) 101.45%)",
                "#2986E4",
            }}
            className="tw-h-36 tw-shadow-xl tw-rounded-md tw-flex tw-justify-center tw-flex-col tw-bg-white tw-px-4"
          >
            <div className="tw-flex tw-justify-between tw-items-center">
              <div className="">
                <h3 className="tw-text-white tw-text-xl tw-font-semibold">
                  Wallet Balance
                </h3>
                <h1 className={`tw-text-2xl tw-font-extrabold tw-text-white`}>
                  {" "}
                  NGN{" "}
                  {wallet?.balance?.available
                    ? numberWithCommas(
                        Number(wallet?.balance?.available).toFixed(2)
                      )
                    : 0}
                </h1>
              </div>

              <img src={Icon1} alt="" className="" />
            </div>
            <div className="tw-flex tw-bottom-2 tw-gap-2">
              <button
                onClick={toggleTransfer}
                className="tw-h-8 tw-rounded-xl tw-w-32 tw-bg-white tw-text-black tw-text-sm tw-font-semibold"
              >
                Wallet Transfer
              </button>
              <button
                onClick={toggleWithdraw}
                className="tw-h-8 tw-rounded-xl tw-w-32 tw-bg-white tw-text-black tw-text-sm tw-font-semibold"
              >
                Withdraw
              </button>
            </div>
          </div>
          <div
            className="tw-h-36 tw-shadow-xl tw-rounded-md tw-flex tw-justify-between tw-items-center tw-bg-white tw-px-4"
            style={{
              background:
                // "linear-gradient(90.18deg, #6CB731 -52.19%, #0F5A16 81.92%)",
                "#03AD3C",
            }}
          >
            <div className="">
              <h1 className="tw-text-white tw-text-xl tw-font-semibold">
                Wallet ID
              </h1>
              <div className="">
                <p className="tw-text-base tw-font-medium tw-text-white">
                  {wallet?.balance?.wallet_id}
                </p>
              </div>
              <button
                onClick={
                  wallet?.balance?.wallet_id
                    ? () => {
                        navigator.clipboard
                          .writeText(wallet?.balance?.wallet_id)
                          .then(
                            () => {
                              toast.info("Copied", { autoClose: 2000 });
                            },
                            (err) => {
                              toast.warn(`Could not copy: ${err}`, {
                                autoClose: 2000,
                              });
                            }
                          );
                      }
                    : null
                }
                className="tw-h-8 tw-rounded-xl tw-flex tw-justify-center tw-items-center tw-cursor-pointer tw-w-32 tw-bg-white tw-text-black tw-text-sm tw-font-semibold"
              >
                Copy{" "}
                <span>
                  <IconContext.Provider value={{ color: "black" }}>
                    <BiCopy />
                  </IconContext.Provider>
                </span>
              </button>
            </div>
            <img src={Icon1} alt="" className="" />
          </div>
          <div
            className="tw-h-36 tw-shadow-xl tw-rounded-md tw-flex tw-justify-between tw-items-center tw-bg-white tw-px-4"
            style={{
              background:
                // "linear-gradient(90.18deg, #84C7DB -52.19%, #377FB6 81.92%)",
                "#2986E4",
            }}
          >
            <div className="">
              <h1 className="tw-text-white tw-text-xl tw-font-semibold">
                Fund Wallet
              </h1>
              <div className="tw-space-y-2 tw-flex tw-flex-col">
                <button
                  onClick={toggleVirtual}
                  className="tw-h-8 tw-rounded-xl tw-w-32 tw-bg-white tw-text-black tw-text-sm tw-font-semibold"
                >
                  Virtual Account
                </button>
                {/* <button
                  onClick={toggleCardType}
                  className="tw-h-8 tw-rounded-xl tw-w-32 tw-bg-white tw-text-black tw-text-sm tw-font-semibold"
                >
                  Debit Card
                </button> */}
              </div>
            </div>
            <img src={Icon1} alt="" className="" />
          </div>
          {/* <div className="tw-h-36 tw-shadow-xl tw-rounded-md tw-flex tw-justify-between tw-items-center tw-bg-white tw-px-4">
						<img src={Icon1} alt="" className="" />
						<div className="">
							<h1 className="tw-text-[#1b1b1b] tw-font-semibold tw-text-xl tw-capitalize">
								Cards
							</h1>
						</div>
					</div> */}
          <div
            style={{
              background:
                // "linear-gradient(90deg, rgba(228, 51, 105, 0.7) 16.14%, rgba(194, 14, 25, 0.7) 101.45%)",
                "#03AD3C",
            }}
            className="tw-h-36 tw-shadow-xl tw-rounded-md tw-flex tw-justify-center tw-flex-col tw-bg-white tw-px-4"
          >
            <div className="tw-flex tw-justify-between tw-items-center">
              <div className="">
                <h3 className="tw-text-white tw-text-xl tw-font-semibold">
                  Commission Balance
                </h3>
                <h1 className={`tw-text-2xl tw-font-extrabold tw-text-white`}>
                  {" "}
                  NGN{" "}
                  {wallet?.balance?.commission
                    ? numberWithCommas(
                        Number(wallet?.balance?.commission).toFixed(2)
                      )
                    : 0}
                </h1>
              </div>

              <img src={Icon1} alt="" className="" />
            </div>
            <div className="tw-flex tw-bottom-2 tw-gap-2">
              <button
                onClick={() => setMoveType("commission")}
                className="tw-h-8 tw-rounded-xl tw-w-32 tw-bg-white tw-text-black tw-text-sm tw-font-semibold"
              >
                Move Commission
              </button>
              <button
                onClick={() => navigate(`/wallets/commissions`)}
                className="tw-h-8 tw-rounded-xl tw-w-32 tw-bg-white tw-text-black tw-text-sm tw-font-semibold"
              >
                View Commission
              </button>
            </div>
          </div>
          <div
            style={{
              background:
                // "linear-gradient(90deg, rgba(228, 51, 105, 0.7) 16.14%, rgba(194, 14, 25, 0.7) 101.45%)",
                "#2986E4",
            }}
            className="tw-h-36 tw-shadow-xl tw-rounded-md tw-flex tw-justify-center tw-flex-col tw-bg-white tw-px-4"
          >
            <div className="tw-flex tw-justify-between tw-items-center">
              <div className="">
                <h3 className="tw-text-white tw-text-xl tw-font-semibold">
                  Bonus Balance
                </h3>
                <h1 className={`tw-text-2xl tw-font-extrabold tw-text-white`}>
                  {" "}
                  NGN{" "}
                  {wallet?.balance?.bonus
                    ? numberWithCommas(
                        Number(wallet?.balance?.bonus).toFixed(2)
                      )
                    : 0}
                </h1>
              </div>

              <img src={Icon1} alt="" className="" />
            </div>
            <div className="tw-flex tw-bottom-2 tw-gap-2">
              <button
                onClick={() => setMoveType("bonus")}
                className="tw-h-8 tw-rounded-xl tw-w-32 tw-bg-white tw-text-black tw-text-sm tw-font-semibold"
              >
                Move Bonus
              </button>
              <button
                onClick={() => navigate(`/wallets/bonus`)}
                className="tw-h-8 tw-rounded-xl tw-w-32 tw-bg-white tw-text-black tw-text-sm tw-font-semibold"
              >
                View Bonus List
              </button>
            </div>
          </div>
        </div>
        {/* <div className="rounded20 walletDiv p-3 px-md-5">
          <div className="d-md-flex align-items-center h-100">
            <div className="px-3 text-dark h-100 py-3 py-md-5 mx-auto">
              <h3 className="fontReduceBig">My Wallet balance</h3>
              <h1 className={`fw-bold text5 textMini mb-5`}>
                NGN{" "}
                {wallet?.balance?.available
                  ? numberWithCommas(
                      Number(wallet?.balance?.available).toFixed(2)
                    )
                  : 0}
              </h1>
              <div className="row mx-0 w-100 mb-5">
                <div
                  className="col text-center myCursor"
                  onClick={() => setMoveType("commission")}
                >
                  <h5 className="fw-bold">
                    {nairaSign}
                    {wallet?.balance?.commission
                      ? numberWithCommas(
                          Number(wallet?.balance?.commission).toFixed(2)
                        )
                      : 0}
                  </h5>
                  <small>Commission</small>
                </div>
                <div
                  className="col text-center myCursor"
                  onClick={() => setMoveType("bonus")}
                >
                  <h5 className="fw-bold">
                    {nairaSign}
                    {wallet?.balance?.bonus
                      ? numberWithCommas(
                          Number(wallet?.balance?.bonus).toFixed(2)
                        )
                      : 0}
                  </h5>
                  <small>Bonus</small>
                </div>
           
                <div className="col text-center">
                  <h5 className="fw-bold">
                    {nairaSign}
                    {wallet?.wallet_details?.purchase
                      ? numberWithCommas(
                          Number(wallet?.wallet_details?.purchase).toFixed(2)
                        )
                      : 0}
                  </h5>
                  <small>Purchase</small>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                {usecase?.usecase?.transferFund === "enable" && (
                  <button
                    onClick={toggleTransfer}
                    className="btn text-capitalize fw-bold btn-primary1"
                  >
                    wallet transfer
                  </button>
                )}
                <button
                  onClick={toggleWithdraw}
                  className="btn text-capitalize fw-bold btn-primary1"
                >
                  withdraw
                </button>
              </div>
            </div>
            <div className="ms-md-auto row mx-0 h-100">
              <div className="col darkBg h-100 rounded10 p-3 py-md-5 genWalletWidth d-flex flex-column">
                <p className="text2 text-center fw-bold">Wallet ID</p>
                <p className="text-center">{wallet?.balance?.wallet_id}</p>
                <p
                  className="mt-auto myCursor"
                  onClick={
                    wallet?.balance?.wallet_id
                      ? () => {
                          navigator.clipboard
                            .writeText(wallet?.balance?.wallet_id)
                            .then(
                              () => {
                                toast.info("Copied", { autoClose: 2000 });
                              },
                              (err) => {
                                toast.warn(`Could not copy: ${err}`, {
                                  autoClose: 2000,
                                });
                              }
                            );
                        }
                      : null
                  }
                >
                  Copy <BiCopy />{" "}
                </p>
              </div>
              <div className="col lilacBg h-100 rounded10 p-3 py-md-5 genWalletWidth mx-md-4 mx-md-3 my-3 my-md-0">
                <p className="text2 mb-5 text-dark fw-bold">Fund Wallet</p>
                <Buttons
                  onClick={toggleVirtual}
                  css={"btn-dark rounded10 text-capitalize my-3 py-3"}
                  title="virtual account"
                />
                {usecase?.usecase?.fundWallet === "enable" && (
                  <>
                    <Buttons
                      onClick={toggleCardType}
                      css={"btn-dark rounded10 text-capitalize my-3 py-3"}
                      title="debit card"
                    />
                  </>
                )}
              </div>
              <div className="col greyBg h-100 rounded10 p-3 py-md-5 genWalletWidth">
                <p className="text2 text-dark fw-bold">Cards</p>
                <CardList bg />
              </div>
            </div>
          </div>
        </div>{" "} */}
        <div className="d-flex my-4">
          {/* <Link
						to={`/wallets/commissions`}
						className="rounded20 shadow2 p-md-5 p-4 mx-2 mx-md-3 eachProduct myCursor text-dark text-decoration-none text-center">
						<div>
							<h6>Commission</h6>
							<h5 className="textMini2">
								{nairaSign}{" "}
								{wallet?.balance?.commission
									? numberWithCommas(
											Number(wallet?.balance?.commission).toFixed(2)
									  )
									: 0}
							</h5>
						</div>
					</Link>
					<Link
						to={`/wallets/bonus`}
						className="rounded20 shadow2 p-md-5 p-4 mx-2 mx-md-3 eachProduct myCursor text-dark text-decoration-none text-center">
						<div>
							<h6>Bonus</h6>
							<h5 className="textMini2">
								{nairaSign}{" "}
								{wallet?.balance?.bonus
									? numberWithCommas(Number(wallet?.balance?.bonus).toFixed(2))
									: 0}
							</h5>
						</div>
					</Link> */}
          {/* <Link
						to={`/wallets/referral`}
						className="rounded20 shadow2 p-md-5 p-4 mx-2 mx-md-3 eachProduct myCursor text-dark text-decoration-none text-center">
						<div>
							<h6>Referral</h6>
							<h5 className="textMini2">
								{nairaSign}{" "}
								{wallet?.balance?.referral
									? numberWithCommas(
											Number(wallet?.balance?.referral).toFixed(2)
									  )
									: 0}
							</h5>
						</div>
					</Link> */}
        </div>
        <div className="row mx-0 g-4">
          <div className="col-12">
            <div className="btn-group pb-3">
              <button
                onClick={() => setActive(0)}
                className={`btn text-capitalize fw-bold Lexend ${
                  active === 0 ? "text-dark border-bottom" : "text-muted"
                }`}
              >
                my wallet history
              </button>
            </div>
            {/* <div className="Lexend fw-bold mb-2">Wallet History</div> */}
            <div>
              <TransferList setThisData={setThisData} />
              <WalletDetails thisData={thisData} setThisData={setThisData} />
            </div>
          </div>
          <div
            className="col-md-2 rounded10 p-3 d-none"
            style={{ background: "#FCFCF9" }}
          >
            <h5 className="fw-bold">Your activity</h5>
            <RoundCharts
              state={[
                {
                  name: "expenses",
                  value: wallet?.wallet_details?.purchase,
                  color: "#FEC430",
                },
                {
                  name: "commission",
                  value: wallet?.wallet_details?.commissionTotal,
                  color: "#AD9BB1",
                },
                {
                  name: "fund",
                  value: wallet?.wallet_details?.walletTotal,
                  color: "#63B0C4",
                },
                {
                  name: "bonus",
                  value: wallet?.wallet_details?.bonusTotal,
                  color: "#B9BBBC",
                },
              ]}
              type="pie"
              css="h-100 w-100"
              noLegend
            />
          </div>
        </div>
      </Container>{" "}
      <MakeTransfer isOpen={isTransfer} back={toggleTransfer} />
      <MakeCardType
        isOpen={isCardType}
        back={toggleCardType}
        setIsCard={setIsCard}
        apiKey={key}
      />
      {key?.flutterwave &&
        usecase?.usecase?.fundWalletFlutterwave === "enable" &&
        isCard === "flutterwave" && (
          <MakeCardsFlutter
            isOpen={isCard === "flutterwave"}
            back={toggleCard}
            back2={() => setIsCard("")}
            value={isCard}
            apiKey={key?.flutterwave}
          />
        )}
      {key?.paystack &&
        usecase?.usecase?.fundWalletPaystack === "enable" &&
        isCard === "paystack" && (
          <MakeCardsPaystack
            isOpen={isCard === "paystack"}
            back={toggleCard}
            back2={() => setIsCard("")}
            value={isCard}
            apiKey={key?.paystack}
          />
        )}
      {process.env.REACT_APP_MONNIFY_API_KEY &&
        process.env.REACT_APP_MONNIFY_CONTRACT_CODE &&
        usecase?.usecase?.fundWalletMonnifyCard === "enable" &&
        isCard === "monnify" && (
          <MakeCardsMonnify
            isOpen={isCard === "monnify"}
            back={toggleCard}
            back2={() => setIsCard("")}
            value={isCard}
            apiKey={key}
          />
        )}
      {key?.budpay &&
        usecase?.usecase?.fundWalletBudpay === "enable" &&
        isCard === "budpay" && (
          <MakeCardsBudpay
            isOpen={isCard === "budpay"}
            back={toggleCard}
            back2={() => setIsCard("")}
            value={isCard}
            apiKey={key?.budpay}
          />
        )}
      {/* {usecase?.usecase?.fundWalletBudpay === "enable" &&
				isCard === "budpay" && (
					<MakeCardsBudpay
						isOpen={isCard === "budpay"}
						back={toggleCard}
						back2={() => setIsCard("")}
						value={isCard}
						apiKey={key}
					/>
				)} */}
      <MakeVirtual isOpen={isVirtual} back={toggleVirtual} />
      <MoveFund isOpen={moveType} back={() => setMoveType(false)} />
    </div>
  );
};

const MakeCardsFlutter = ({ isOpen, back, back2, apiKey }) => {
  const {
    wallet,
    returnErrors,
    usecase,
    auth,
    manageFundWalletFlutterwave,
    nairaSignNeutral,
    numberWithCommas,
  } = useContext(GlobalState);

  let [amount, setAmount] = useState(""),
    [payment_data, setPaymentData] = useState(null),
    [reference, setReference] = useState(Date.now()),
    config = {
      public_key: apiKey,
      tx_ref: reference,
      amount,
      currency: "NGN",
      payment_options: "card",
      customer: {
        email: auth?.user?.email,
        phone_number: auth?.user?.telephone,
        phonenumber: auth?.user?.telephone,
        name: `${auth?.user?.firstName} ${auth?.user?.lastName}`,
      },
      customizations: {
        title: process.env.REACT_APP_NAME + " Wallet Funding",
        description: "Card wallet funding",
        logo: process.env.REACT_APP_IMAGE_URL,
      },
    },
    handleFlutterPayment = useFlutterwave(config);

  useEffect(() => {
    if (payment_data) {
      let sendBackend = async () => {
        setLoading(true);
        await manageFundWalletFlutterwave(payment_data, "flutterwave");
        setLoading(false);
        setSubmit(true);
      };

      sendBackend();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment_data]);

  useEffect(() => {
    if (reference) {
      handleFlutterPayment({
        callback: (response) => {
          // console.log(response);
          setPaymentData(response);
          closePaymentModal();
        },
        onClose: () => {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reference]);

  let [loading, setLoading] = useState(false),
    [submit, setSubmit] = useState(false),
    handleSubmit = async (e) => {
      e?.preventDefault();
      if (Number(amount) <= 0)
        return returnErrors({
          error: [
            {
              msg: `Amount cannot be less than or equal to ${nairaSignNeutral} 0`,
              param: "amount",
            },
          ],
        });
      if (Number(amount) < Number(usecase?.usecase?.cardFundingMini))
        return returnErrors({
          error: [
            {
              msg: `Amount cannot be less than ${nairaSignNeutral} ${numberWithCommas(
                Number(usecase?.usecase?.cardFundingMini)
              )}`,
              param: "amount",
            },
          ],
        });
      if (Number(amount) > Number(usecase?.usecase?.cardFundingMax))
        return returnErrors({
          error: [
            {
              msg: `Amount cannot be more than ${nairaSignNeutral} ${numberWithCommas(
                Number(usecase?.usecase?.cardFundingMax)
              )}`,
              param: "amount",
            },
          ],
        });
      if (!apiKey)
        return returnErrors({
          error: [
            {
              msg: `Your request could not be processed at the moment, please try again later`,
              param: "flutterwave",
            },
          ],
        });
      // console.log({ payment_data });
      try {
        setLoading(true);
        var resp = await axios.get(
          `/api/v2/wallet/generate-wallet-reference?amount=${amount}`
        );
        // console.log({ resp: resp?.data });
        setReference(resp?.data?.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log({ err });
        let error = err.response?.data?.error;
        if (error) {
          returnErrors({ error, status: err?.response?.status });
        }
        if (err?.response?.status === 429) toast.error(err?.response?.data);
      }
    };

  useEffect(() => {
    if (submit && wallet?.isFunded) {
      back2();
      setSubmit(false);
      setReference("");
      setPaymentData(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit, wallet?.isFunded]);

  // console.log({ wal: wallet?.data, updateValue });
  return (
    <>
      <ModalComponents
        isOpen={isOpen ? true : false}
        back={back}
        title="Flutterwave checkout process"
      >
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="value">Amount</label>
            <NumericFormat
              prefix={`${nairaSignNeutral} `}
              className="form-control py-3 rounded10"
              value={amount}
              placeholder="500"
              displayType="input"
              thousandSeparator={true}
              onValueChange={(val) => setAmount(val?.floatValue)}
              min={0}
              inputMode="decimal"
              renderText={(value, props) => <span {...props}>{value}</span>}
              allowNegative={false}
            />
          </div>
          <Buttons
            title={"fund"}
            css="btn-primary1 text-capitalize py-3 w-50 my-4 mx-auto"
            width={"w-50"}
            style={{ borderRadius: "30px" }}
            loading={loading}
            onClick={() => handleSubmit()}
          />
        </form>
      </ModalComponents>
    </>
  );
};

// const MakeCardsBudpay = ({ isOpen, back, back2, apiKey }) => {
// 	const {
// 		wallet,
// 		returnErrors,
// 		usecase,
// 		auth,
// 		manageFundWalletFlutterwave,
// 		nairaSignNeutral,
// 	} = useContext(GlobalState);

// 	let [amount, setAmount] = useState(0),
// 		[payment_data, setPaymentData] = useState(null),
// 		[reference, setReference] = useState(Date.now()),
// 		config = {
// 			api_key: apiKey,
// 			reference,
// 			amount,
// 			email: auth?.user?.email,
// 			first_name: auth?.user?.firstName,
// 			last_name: auth?.user?.lastName,
// 			currency: "NGN",
// 			logo_url: process.env.REACT_APP_IMAGE_URL,
// 		},
// 		handleBudpayPayment = useBudPayPayment(config);

// 	useEffect(() => {
// 		if (payment_data) {
// 			let sendBackend = async () => {
// 				setLoading(true);
// 				await manageFundWalletFlutterwave(payment_data, "budpay");
// 				setLoading(false);
// 				setSubmit(true);
// 			};

// 			sendBackend();
// 		}
// 		// eslint-disable-next-line react-hooks/exhaustive-deps
// 	}, [payment_data]);

// 	useEffect(() => {
// 		if (reference) {
// 			handleBudpayPayment({
// 				text: "Pay with Honour World Wema Account",
// 				callback: response => {
// 					setPaymentData(response);
// 					closeBudPayPaymentModal();
// 				},
// 				onClose: function (response) {
// 					console.log(response);
// 					toast.error("Transaction was not completed, window closed.");
// 				},
// 			});
// 		}
// 		// eslint-disable-next-line react-hooks/exhaustive-deps
// 	}, [reference]);

// 	let [loading, setLoading] = useState(false),
// 		[submit, setSubmit] = useState(false),
// 		handleSubmit = async e => {
// 			e?.preventDefault();
// 			if (Number(amount) <= 0)
// 				return returnErrors({
// 					error: [
// 						{
// 							msg: `Amount cannot be less than or equal to ${nairaSignNeutral} 0`,
// 							param: "amount",
// 						},
// 					],
// 				});
// 			if (Number(amount) < Number(usecase?.usecase?.cardFundingMini))
// 				return returnErrors({
// 					error: [
// 						{
// 							msg: `Amount cannot be less than ${nairaSignNeutral} ${Number(
// 								usecase?.usecase?.cardFundingMini
// 							)}`,
// 							param: "amount",
// 						},
// 					],
// 				});
// 			if (Number(amount) > Number(usecase?.usecase?.cardFundingMax))
// 				return returnErrors({
// 					error: [
// 						{
// 							msg: `Amount cannot be more than ${nairaSignNeutral} ${Number(
// 								usecase?.usecase?.cardFundingMax
// 							)}`,
// 							param: "amount",
// 						},
// 					],
// 				});
// 			if (!apiKey)
// 				return returnErrors({
// 					error: [
// 						{
// 							msg: `Your request could not be processed at the moment, please try again later`,
// 							param: "budpay",
// 						},
// 					],
// 				});
// 			// console.log({ payment_data });
// 			try {
// 				setLoading(true);
// 				var resp = await axios.get(
// 					`/api/v2/wallet/generate-wallet-reference?amount=${amount}`
// 				);
// 				// console.log({ resp: resp?.data });
// 				setReference(resp?.data?.data);
// 				setLoading(false);
// 			} catch (err) {
// 				setLoading(false);
// 				console.log({ err });
// 				let error = err.response?.data?.error;
// 				if (error) {
// 					returnErrors({ error, status: err?.response?.status });
// 				}
// 				if (err?.response?.status === 429) toast.error(err?.response?.data);
// 			}
// 		};

// 	useEffect(() => {
// 		if (submit && wallet?.isFunded) {
// 			back2();
// 			setSubmit(false);
// 			setReference("");
// 			setPaymentData(null);
// 		}
// 		// eslint-disable-next-line react-hooks/exhaustive-deps
// 	}, [submit, wallet?.isFunded]);

// 	// console.log({ wal: wallet?.data, updateValue });
// 	return (
// 		<>
// 			<ModalComponents
// 				isOpen={isOpen ? true : false}
// 				back={back}
// 				title="Budpay checkout process">
// 				<form onSubmit={handleSubmit}>
// 					<div className="mb-3">
// 						<label htmlFor="value">Amount</label>
// 						<NumericFormat
// 							prefix={`${nairaSignNeutral} `}
// 							className="form-control py-3 rounded10"
// 							value={amount}
// 							placeholder="500"
// 							displayType="input"
// 							thousandSeparator={true}
// 							onValueChange={val => setAmount(val?.floatValue)}
// 							min={0}
// 							inputMode="decimal"
// 							renderText={(value, props) => <span {...props}>{value}</span>}
// 							allowNegative={false}
// 						/>
// 					</div>
// 					<Buttons
// 						title={"fund"}
// 						css="btn-primary1 text-capitalize py-3 w-50 my-4 mx-auto"
// 						width={"w-50"}
// 						style={{ borderRadius: "30px" }}
// 						loading={loading}
// 						onClick={() => handleSubmit()}
// 					/>
// 				</form>
// 			</ModalComponents>
// 		</>
// 	);
// };

export const MakeCardsBudpay = ({ isOpen, back, back2, apiKey }) => {
  const { returnErrors, usecase, nairaSignNeutral, numberWithCommas } =
    useContext(GlobalState);

  let [amount, setAmount] = useState(""),
    [paymentData, setPaymentData] = useState(null);

  useEffect(() => {
    if (paymentData) back2();
    setPaymentData(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentData]);

  let [loading, setLoading] = useState(false),
    handleSubmit = async (e) => {
      e?.preventDefault();
      if (Number(amount) <= 0)
        return returnErrors({
          error: [
            {
              msg: `Amount cannot be less than or equal to ${nairaSignNeutral} 0`,
              param: "amount",
            },
          ],
        });
      if (Number(amount) < Number(usecase?.usecase?.cardFundingMini))
        return returnErrors({
          error: [
            {
              msg: `Amount cannot be less than ${nairaSignNeutral} ${numberWithCommas(
                Number(usecase?.usecase?.cardFundingMini)
              )}`,
              param: "amount",
            },
          ],
        });
      if (Number(amount) > Number(usecase?.usecase?.cardFundingMax))
        return returnErrors({
          error: [
            {
              msg: `Amount cannot be more than ${nairaSignNeutral} ${numberWithCommas(
                Number(usecase?.usecase?.cardFundingMax)
              )}`,
              param: "amount",
            },
          ],
        });
      if (!apiKey)
        return returnErrors({
          error: [
            {
              msg: `Your request could not be processed at the moment, please try again later`,
              param: "budpay",
            },
          ],
        });
      // console.log({ payment_data });
      try {
        setLoading(true);
        var resp = await axios.put(`/api/v2/wallet/manage-budpay`, {
          amount,
        });
        setPaymentData(resp?.data?.data);
        setLoading(false);
        window.open(resp?.data?.data?.authorization_url, "_blank");
      } catch (err) {
        setLoading(false);
        console.log({ err });
        let error = err.response?.data?.error;
        if (error) {
          returnErrors({ error, status: err?.response?.status });
        }
        if (err?.response?.status === 429) toast.error(err?.response?.data);
      }
    };

  // console.log({ wal: wallet?.data, updateValue });
  return (
    <>
      <ModalComponents
        isOpen={isOpen ? true : false}
        back={back}
        title="Budpay checkout process"
      >
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="value">Amount</label>
            <NumericFormat
              prefix={`${nairaSignNeutral} `}
              className="form-control py-3 rounded10"
              value={amount}
              placeholder="500"
              displayType="input"
              thousandSeparator={true}
              onValueChange={(val) => setAmount(val?.floatValue)}
              min={0}
              inputMode="decimal"
              renderText={(value, props) => <span {...props}>{value}</span>}
              allowNegative={false}
            />
          </div>
          <Buttons
            title={"fund"}
            css="btn-primary1 text-capitalize py-3 w-50 my-4 mx-auto"
            width={"w-50"}
            style={{ borderRadius: "30px" }}
            loading={loading}
            onClick={() => handleSubmit()}
          />
        </form>
      </ModalComponents>
    </>
  );
};

export const MakeCardsPaystack = ({ isOpen, back, back2, apiKey }) => {
  const {
    wallet,
    returnErrors,
    usecase,
    auth,
    manageFundWalletPaystack,
    nairaSignNeutral,
    numberWithCommas,
  } = useContext(GlobalState);

  let [amount, setAmount] = useState(""),
    [reference, setReference] = useState(Date.now()),
    mainName = `${auth?.user?.firstName} ${auth?.user?.lastName}`,
    config = {
      email: auth?.user?.email,
      amount: Number(amount * 100),
      publicKey: apiKey,
      metadata: {
        name: mainName,
        phone: auth?.user?.telephone,
        custom_fields: [
          {
            display_name: "Full Name",
            variable_name: "full_name",
            value: mainName,
          },
          {
            display_name: "Phone Number",
            variable_name: "phone_number",
            value: auth?.user?.telephone,
          },
        ],
      },
      reference: reference ? reference?.toString()?.split("|")?.join("") : "",
    },
    initializePayment = usePaystackPayment(config);

  let handleSuccess = async (ref) => {
    setLoading(true);
    await manageFundWalletPaystack(ref);
    setLoading(false);
    setSubmit(true);
  };

  // you can call this function anything
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("closed");
  };

  const onSuccess = (ref) => {
    // console.log({ ref });
    handleSuccess(ref);
  };

  useEffect(() => {
    if (reference) {
      initializePayment(onSuccess, onClose);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reference]);

  let [loading, setLoading] = useState(false),
    [submit, setSubmit] = useState(false),
    handleSubmit = async (e) => {
      e?.preventDefault();
      if (Number(amount) <= 0)
        return returnErrors({
          error: [
            {
              msg: `Amount cannot be less than or equal to ${nairaSignNeutral} 0`,
              param: "amount",
            },
          ],
        });
      if (Number(amount) < Number(usecase?.usecase?.cardFundingMini))
        return returnErrors({
          error: [
            {
              msg: `Amount cannot be less than ${nairaSignNeutral} ${numberWithCommas(
                Number(usecase?.usecase?.cardFundingMini)
              )}`,
              param: "amount",
            },
          ],
        });
      if (Number(amount) > Number(usecase?.usecase?.cardFundingMax))
        return returnErrors({
          error: [
            {
              msg: `Amount cannot be more than ${nairaSignNeutral} ${numberWithCommas(
                Number(usecase?.usecase?.cardFundingMax)
              )}`,
              param: "amount",
            },
          ],
        });
      if (!apiKey)
        return returnErrors({
          error: [
            {
              msg: `Your request could not be processed at the moment, please try again later`,
              param: "paystack",
            },
          ],
        });
      // console.log({ payment_data });
      try {
        setLoading(true);
        var resp = await axios.get(
          `/api/v2/wallet/generate-wallet-reference?amount=${amount}`
        );
        // console.log({ resp: resp?.data });
        setReference(resp?.data?.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log({ err });
        let error = err.response?.data?.error;
        if (error) {
          returnErrors({ error, status: err?.response?.status });
        }
        if (err?.response?.status === 429) toast.error(err?.response?.data);
      }
    };

  useEffect(() => {
    if (submit && wallet?.isFunded) {
      back2();
      setSubmit(false);
      setReference("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit, wallet?.isFunded]);

  return (
    <>
      <ModalComponents
        isOpen={isOpen ? true : false}
        back={back}
        title="Paystack checkout process"
      >
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="value">Amount</label>
            <NumericFormat
              prefix={`${nairaSignNeutral} `}
              className="form-control py-3 rounded10"
              value={amount}
              placeholder="500"
              displayType="input"
              thousandSeparator={true}
              onValueChange={(val) => setAmount(val?.floatValue)}
              min={0}
              inputMode="decimal"
              renderText={(value, props) => <span {...props}>{value}</span>}
              allowNegative={false}
            />
          </div>
          <Buttons
            title={"fund"}
            css="btn-primary1 text-capitalize py-3 w-50 my-4 mx-auto"
            width={"w-50"}
            style={{ borderRadius: "30px" }}
            loading={loading}
            onClick={() => handleSubmit()}
          />
        </form>
      </ModalComponents>
    </>
  );
};

export default Wallets;

const MoveFund = ({ isOpen, back }) => {
  const { manageWallet } = useContext(GlobalState);

  let [loading, setLoading] = useState(false),
    [submit, setSubmit] = useState(false);
  let handleMove = async (e) => {
    e?.preventDefault();
    setLoading(true);
    await manageWallet(isOpen);
    setLoading(false);
    setSubmit(true);
  };

  useEffect(() => {
    if (isOpen && submit) {
      setSubmit(false);
      back();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, submit]);

  return (
    <>
      <ModalComponents
        isOpen={isOpen}
        back={back}
        title={`Move ${isOpen} wallet`}
      >
        <form onSubmit={handleMove}>
          <div className="downH2 d-flex align-items-center justify-content-center">
            <form className="" onSubmit={handleMove}>
              <p>Do you want to move {isOpen} to main wallet?</p>
              <div className="btn-group mx-auto w-100">
                <Buttons
                  loading={loading}
                  onClick={handleMove}
                  width="w-50"
                  css="btn-success-2 btn-success2 text-capitalize py-3 w-50"
                  title={"yes"}
                />
                <Buttons
                  onClick={back}
                  width="w-50"
                  css="btn-danger-2 btn-danger2 text-capitalize py-3 w-50"
                  title={"no"}
                  type={"no"}
                />
              </div>
            </form>
          </div>
        </form>
      </ModalComponents>
    </>
  );
};

const MakeCardType = ({ isOpen, back, setIsCard, apiKey }) => {
  let { usecase } = useContext(GlobalState);
  let [details, setDetails] = useState("");
  return (
    <>
      <ModalComponents isOpen={isOpen} back={back} title="Choose provider">
        <form
          onSubmit={() => {
            if (!details) return;
            setIsCard(details);
            back();
          }}
        >
          <div>
            {apiKey?.paystack &&
              usecase?.usecase?.fundWalletPaystack === "enable" && (
                <div
                  onClick={() => setDetails("paystack")}
                  className={`my-3 d-flex align-items-center rounded10 myCursor flex-column p-3 ${
                    details === "paystack" ? "list-group-item-info" : ""
                  }`}
                >
                  <div className="d-flex flex-column mx-auto">
                    <div
                      className="p-3 d-flex rounded10 align-items-center justify-content-center"
                      style={{
                        background: "#EFEFEF",
                        height: "5rem",
                        width: "100%",
                      }}
                    >
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/0b/Paystack_Logo.png/1200px-Paystack_Logo.png?20200430170057"
                        alt="Paystack"
                        className="img-fluid objectFit h-100 w-100"
                      />
                    </div>
                  </div>
                  {/* <div className="text-center">
										<h6 className="fw-bold text-dark Lexend text2p">
											Paystack
										</h6>
									</div> */}
                </div>
              )}
            {apiKey?.flutterwave &&
              usecase?.usecase?.fundWalletFlutterwave === "enable" && (
                <div
                  onClick={() => setDetails("flutterwave")}
                  className={`my-3 d-flex align-items-center rounded10 myCursor flex-column p-3 ${
                    details === "flutterwave" ? "list-group-item-info" : ""
                  }`}
                >
                  <div className="d-flex flex-column mx-auto">
                    <div
                      className="p-3 d-flex rounded10 align-items-center justify-content-center"
                      style={{
                        background: "#EFEFEF",
                        height: "5rem",
                        width: "100%",
                      }}
                    >
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/9e/Flutterwave_Logo.png/1200px-Flutterwave_Logo.png?20220812092224"
                        alt="Flutterwave"
                        className="img-fluid objectFit h-100 w-100"
                      />
                    </div>
                  </div>
                  {/* <div className="text-center">
										<h6 className="fw-bold text-dark Lexend text2p">
											Flutterwave
										</h6>
									</div> */}
                </div>
              )}
            {apiKey?.budpay &&
              usecase?.usecase?.fundWalletBudpay === "enable" && (
                <div
                  onClick={() => setDetails("budpay")}
                  className={`my-3 d-flex align-items-center rounded10 myCursor flex-column p-3 ${
                    details === "budpay" ? "list-group-item-info" : ""
                  }`}
                >
                  <div className="d-flex flex-column mx-auto">
                    <div
                      className="p-3 d-flex rounded10 align-items-center justify-content-center"
                      style={{
                        background: "#EFEFEF",
                        height: "5rem",
                        width: "100%",
                      }}
                    >
                      <img
                        src="https://merchant.budpay.com/assets/front/img/BudPay-Logo3.png"
                        alt="Budpay"
                        className="img-fluid objectFit h-100 w-100"
                      />
                    </div>
                  </div>
                  {/* <div className="text-center">
										<h6 className="fw-bold text-dark Lexend text2p">Budpay</h6>
									</div> */}
                </div>
              )}
            {process.env.REACT_APP_MONNIFY_API_KEY &&
              process.env.REACT_APP_MONNIFY_CONTRACT_CODE &&
              usecase?.usecase?.fundWalletMonnifyCard === "enable" && (
                <div
                  onClick={() => setDetails("monnify")}
                  className={`my-3 border-bottom d-flex align-items-center rounded10 myCursor flex-column p-3 ${
                    details === "monnify" ? "list-group-item-info" : ""
                  }`}
                >
                  <div className="d-flex flex-column mx-auto">
                    <div
                      className="p-3 d-flex rounded10 align-items-center justify-content-center"
                      style={{
                        background: "#EFEFEF",
                        height: "5rem",
                        width: "100%",
                      }}
                    >
                      <img
                        src="https://monnify.com/assets/img/svg/site-logo.svg"
                        alt="Monnify"
                        className="img-fluid objectFit h-100 w-100"
                      />
                    </div>
                  </div>
                  {/* <div className="text-center">
										<h6 className="fw-bold text-dark Lexend text2p">Monnify</h6>
									</div> */}
                </div>
              )}
          </div>
          <Buttons
            title={"proceed"}
            css="btn-primary1 text-capitalize py-3 w-50 my-4 mx-auto"
            width={"w-50"}
            style={{ borderRadius: "30px" }}
            onClick={() => {
              if (!details) return;
              setIsCard(details);
              back();
            }}
            type={"button"}
          />
        </form>
      </ModalComponents>
    </>
  );
};

const MakeCardsMonnify = ({ isOpen, back, back2, apiKey }) => {
  const {
    wallet,
    returnErrors,
    usecase,
    auth,
    manageFundWalletFlutterwave,
    nairaSignNeutral,
    numberWithCommas,
  } = useContext(GlobalState);

  let [payment_data, setPayment] = useState(null);

  let close = () => {
    console.log("Closed");
  };
  let onComplete = (response) => {
    // console.log(response);
    setPayment(response);
  };
  let [amount, setAmount] = useState(""),
    [reference, setReference] = useState(),
    config = {
      apiKey: apiKey?.monnify,
      contractCode: apiKey?.monnifyContract,
      reference,
      amount,
      currency: "NGN",
      payment_options: "card",
      customerEmail: auth?.user?.email,
      customerMobileNumber: auth?.user?.telephone,
      customerFullName: `${auth?.user?.firstName} ${auth?.user?.lastName}`,
      paymentDescription:
        process.env.REACT_APP_NAME + " Wallet Funding Card wallet funding",
      isTestMode: process.env.NODE_ENV === "development",
      onComplete: onComplete,
      onClose: close,
    },
    handleMonnifyPayment = useMonnifyPayment(config);

  useEffect(() => {
    if (reference) {
      handleMonnifyPayment(onComplete, close);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reference]);

  useEffect(() => {
    if (payment_data) {
      let sendBackend = async () => {
        setLoading(true);
        await manageFundWalletFlutterwave(
          payment_data?.status === "SUCCESS"
            ? payment_data
            : {
                ...payment_data,
                transactionReference: payment_data?.paymentReference,
              },
          "monnify"
        );
        setLoading(false);
        setSubmit(true);
      };

      sendBackend();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment_data]);

  let [loading, setLoading] = useState(false),
    [submit, setSubmit] = useState(false),
    handleSubmit = async (e) => {
      e?.preventDefault();
      if (Number(amount) <= 0)
        return returnErrors({
          error: [
            {
              msg: `Amount cannot be less than or equal to ${nairaSignNeutral} 0`,
              param: "amount",
            },
          ],
        });
      if (Number(amount) < Number(usecase?.usecase?.cardFundingMini))
        return returnErrors({
          error: [
            {
              msg: `Amount cannot be less than ${nairaSignNeutral} ${numberWithCommas(
                Number(usecase?.usecase?.cardFundingMini)
              )}`,
              param: "amount",
            },
          ],
        });
      if (Number(amount) > Number(usecase?.usecase?.cardFundingMax))
        return returnErrors({
          error: [
            {
              msg: `Amount cannot be more than ${nairaSignNeutral} ${numberWithCommas(
                Number(usecase?.usecase?.cardFundingMax)
              )}`,
              param: "amount",
            },
          ],
        });
      if (!apiKey?.monnify)
        return returnErrors({
          error: [
            {
              msg: `Your request could not be processed at the moment, please try again later`,
              param: "flutterwave",
            },
          ],
        });
      // console.log({ payment_data });
      try {
        setLoading(true);
        var resp = await axios.get(
          `/api/v2/wallet/generate-wallet-reference?amount=${amount}`
        );
        // console.log({ resp: resp?.data });
        setReference(resp?.data?.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log({ err });
        let error = err.response?.data?.error;
        if (error) {
          returnErrors({ error, status: err?.response?.status });
        }
        if (err?.response?.status === 429) toast.error(err?.response?.data);
      }
    };

  useEffect(() => {
    if (submit && wallet?.isFunded) {
      back2();
      setSubmit(false);
      setReference("");
      setPayment(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit, wallet?.isFunded]);

  // console.log({ wal: wallet?.data, updateValue });
  return (
    <>
      <ModalComponents
        isOpen={isOpen ? true : false}
        back={back}
        title="Monnify checkout process"
      >
        <form>
          <div className="mb-3">
            <label htmlFor="value">Amount</label>
            <NumericFormat
              prefix={`${nairaSignNeutral} `}
              className="form-control py-3 rounded10"
              value={amount}
              placeholder="500"
              displayType="input"
              thousandSeparator={true}
              onValueChange={(val) => setAmount(val?.floatValue)}
              min={0}
              inputMode="decimal"
              renderText={(value, props) => <span {...props}>{value}</span>}
              allowNegative={false}
            />
          </div>

          <Buttons
            title={"Pay"}
            css="btn-primary1 text-capitalize py-3 w-50 my-4 mx-auto"
            width={"w-50"}
            style={{ borderRadius: "30px" }}
            loading={loading}
            onClick={() => handleSubmit()}
          />
        </form>
      </ModalComponents>
    </>
  );
};

const MakeVirtual = ({ isOpen, back }) => {
  const { wallet, generateVirtual, usecase } = useContext(GlobalState);
  let [loading, setLoading] = useState(false);
  // console.log(wallet?.balance?.budpayAccount?.provider?.bank_name);
  // console.log(wallet?.balance?.budpayAccount?.bank?.name);

  return (
    <>
      <ModalComponents isOpen={isOpen} back={back} title="virtual accounts">
        <form>
          {usecase?.usecase?.fundWalletMonnify === "enable" && (
            <>
              {wallet?.balance?.monnifyAccount ? (
                <div>
                  {wallet?.balance?.monnifyAccount?.accounts?.map((it, i) => (
                    <div
                      key={i}
                      className="my-3 d-flex align-items-center rounded10 bg-light p-3"
                    >
                      <div className="d-flex me-2">
                        <div
                          className="p-2 d-flex rounded10 align-items-center justify-content-center"
                          style={{
                            // background: `${colorArr[i % colorArr.length]}`,
                            background: "white",
                          }}
                        >
                          {/* <RiShieldStarFill
                            size={24}
                            color={`${
                              colorArr[i % colorArr.length] === "#000000"
                                ? "#fff"
                                : "#000"
                            }`}
                          /> */}
                          <img
                            src={
                              it?.bankName === "Sterling bank"
                                ? SterlingLogo
                                : it?.bankName === "Wema bank"
                                ? WemaLogo
                                : MoniepointLogo
                            }
                            alt=""
                            style={{
                              width: "50px",
                              height: "50px",
                              objectFit: "contain",
                            }}
                          />
                        </div>
                      </div>
                      <div>
                        <h6 className="fw-bold text-muted">{it?.bankName}</h6>
                        <h6 className="fw-bold force-d-flex">
                          {it?.accountNumber || it?.bankAccount}{" "}
                          <BiCopy
                            size={20}
                            className="ms-3 myCursor"
                            onClick={() => {
                              navigator.clipboard
                                .writeText(it?.accountNumber || it?.bankAccount)
                                .then(
                                  () => {
                                    toast.info("Copied", { autoClose: 2000 });
                                  },
                                  (err) => {
                                    toast.warn(`Could not copy: ${err}`, {
                                      autoClose: 2000,
                                    });
                                  }
                                );
                            }}
                          />{" "}
                        </h6>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <Buttons
                  title={"generate account"}
                  css="btn-primary1 text-capitalize py-3 w-50 my-4 mx-auto"
                  width={"w-50"}
                  onClick={async () => {
                    setLoading("monnify");
                    await generateVirtual();
                    setLoading(false);
                  }}
                  style={{ borderRadius: "30px" }}
                  loading={loading === "monnify"}
                />
              )}
            </>
          )}
          {usecase?.usecase?.fundWalletBudpay === "enable" && (
            <>
              {wallet?.balance?.budpayAccount ? (
                <>
                  <h3 className="Lexend">Honour World Linked Account</h3>
                  <div className="my-3 d-flex align-items-center rounded10 bg-light p-3">
                    <div className="d-flex me-2">
                      <div
                        className="p-3 d-flex rounded10 align-items-center justify-content-center"
                        style={{
                          // background: `${colorArr[colorArr.length - 1]}`,
                          background: "white",
                        }}
                      >
                        {/* <RiShieldStarFill
                          size={24}
                          color={`${
                            colorArr[colorArr.length - 1] === "#000000"
                              ? "#fff"
                              : "#000"
                          }`}
                        /> */}

                        <img
                          src={
                            wallet?.balance?.budpayAccount?.provider?.bank_name.split(
                              " "
                            )[0] === "Sterling"
                              ? SterlingLogo
                              : wallet?.balance?.budpayAccount?.provider?.bank_name.split(
                                  " "
                                )[0] === "Wema"
                              ? WemaLogo
                              : wallet?.balance?.budpayAccount?.bank?.name.split(
                                  " "
                                )[0] === "Premium"
                              ? PremiumTrust
                              : MoniepointLogo
                          }
                          alt=""
                          style={{
                            width: "50px",
                            height: "50px",
                            objectFit: "contain",
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <h6 className="fw-bold text-muted">
                        {wallet?.balance?.budpayAccount?.provider?.bank_name ||
                          wallet?.balance?.budpayAccount?.bank?.name}
                      </h6>
                      <h6 className="fw-bold force-d-flex">
                        {wallet?.balance?.budpayAccount?.dedicated_account
                          ?.account_number ||
                          wallet?.balance?.budpayAccount?.account_number}{" "}
                        <BiCopy
                          size={20}
                          className="ms-3 myCursor"
                          onClick={() => {
                            navigator.clipboard
                              .writeText(
                                wallet?.balance?.budpayAccount
                                  ?.dedicated_account?.account_number ||
                                  wallet?.balance?.budpayAccount?.account_number
                              )
                              .then(
                                () => {
                                  toast.info("Copied", { autoClose: 2000 });
                                },
                                (err) => {
                                  toast.warn(`Could not copy: ${err}`, {
                                    autoClose: 2000,
                                  });
                                }
                              );
                          }}
                        />{" "}
                      </h6>
                    </div>
                  </div>
                </>
              ) : (
                <Buttons
                  title={"generate Honour World Wema Account"}
                  css="btn-primary1 text-capitalize py-3 w-50 my-4 mx-auto"
                  width={"w-50"}
                  onClick={async () => {
                    setLoading("manage-budpay");
                    await generateVirtual("manage-budpay");
                    setLoading(false);
                  }}
                  style={{ borderRadius: "30px" }}
                  loading={loading === "manage-budpay"}
                />
              )}
            </>
          )}
        </form>
      </ModalComponents>
    </>
  );
};

const MakeTransfer = ({ isOpen, back }) => {
  let { manageWallet, wallet, returnErrors, nairaSignNeutral } =
    useContext(GlobalState);

  let init = {
      type: "wallet",
      user: "",
      amount: "",
      pin: "",
    },
    [state, setState] = useState(init),
    [loading, setLoading] = useState(false),
    [submit, setSubmit] = useState(false),
    textChange =
      (name) =>
      ({ target: { value } }) => {
        setState({ ...state, [name]: value });
      },
    handleSubmit = async (e) => {
      e?.preventDefault();
      if (Number(state?.amount) <= 0)
        return returnErrors({
          error: [
            {
              msg: `Amount cannot be less than or equal to ${nairaSignNeutral} 0`,
              param: "amount",
            },
          ],
        });
      setLoading(true);
      await manageWallet("wallet", state);
      setLoading(false);
      setSubmit(true);
    },
    [active, setActive] = useState(0);

  useEffect(() => {
    if (wallet?.isTransfer && submit) {
      back();
      setSubmit(false);
      setState(init);
      setActive(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet?.isTransfer, submit]);

  useEffect(() => {
    if (state?.pin && state?.pin?.length === 4) handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.pin]);

  return (
    <>
      <ModalComponents isOpen={isOpen} back={back} title="Transfer">
        {active === 1 ? (
          <>
            <TransactionPinBox
              state={state}
              setState={setState}
              handleSubmit={handleSubmit}
              loading={loading}
              title={"transfer"}
            />
          </>
        ) : (
          <>
            <WalletForm
              state={state}
              textChange={textChange}
              setState={setState}
            />
            <Buttons
              title={"transfer"}
              css="btn-primary1 text-capitalize py-3 w-50 my-4 mx-auto"
              width={"w-50"}
              style={{ borderRadius: "30px" }}
              loading={loading}
              onClick={
                wallet?.balance?.wallet_pin
                  ? () => {
                      setActive(1);
                    }
                  : handleSubmit
              }
            />
          </>
        )}
      </ModalComponents>
    </>
  );
};

export const BonusCommission = ({ type, general }) => {
  const {
    bonus,
    commission,
    numberWithCommas,
    getWalletHistory,
    nairaSign,
    referral,
    getReload,
    wallet,
  } = useContext(GlobalState);

  let [state, setState] = useState(null),
    [search, setSearch] = useState(""),
    [thisData, setThisData] = useState(null);

  useEffect(() => {
    getWalletHistory(
      type === "bonus"
        ? "bonus"
        : type === "referral"
        ? "referral"
        : "commission",
      general
        ? { general: general === "authorizer" ? general : "general" }
        : null
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [general, type]);

  // let [loading, setLoading] = useState(false);

  // let handleLoadMore = async () => {
  //   setLoading(true);

  //   await getWalletHistory(
  //     type === "bonus"
  //       ? "bonus"
  //       : type === "referral"
  //       ? "referral"
  //       : "commission",
  //     general
  //       ? {
  //           page: Number(
  //             type === "referral"
  //               ? search
  //                 ? wallet?.search_paginate?.nextPage
  //                 : referral?.general_paginate?.nextPage
  //               : type === "bonus"
  //               ? search
  //                 ? wallet?.search_paginate?.nextPage
  //                 : bonus?.paginate?.nextPage
  //               : search
  //               ? wallet?.search_paginate?.nextPage
  //               : commission?.general_paginate?.nextPage
  //           ),

  //           general: general === "authorizer" ? general : "general",
  //         }
  //       : {
  //           limit: Number(
  //             type === "referral"
  //               ? search
  //                 ? wallet?.search_paginate?.nextPage
  //                 : referral?.paginate?.nextPage
  //               : type === "bonus"
  //               ? search
  //                 ? wallet?.search_paginate?.nextPage
  //                 : bonus?.paginate?.nextPage
  //               : search
  //               ? wallet?.search_paginate?.nextPage
  //               : commission?.paginate?.nextPage
  //           ),
  //         }
  //   );
  //   setLoading(false);
  // };

  useEffect(() => {
    if (search) {
      document.getElementById("Search").addEventListener("search", () => {
        getReload();
      });
      let handleSubmit = async () => {
        if (!search) return;

        await getWalletHistory(
          type === "bonus"
            ? "bonus"
            : type === "referral"
            ? "referral"
            : "commission",
          general
            ? {
                general: general === "authorizer" ? general : "general",
                search,
              }
            : {
                search,
              }
        );
      };
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (wallet?.isFound) setState(wallet?.mainSearch);
    else if (type === "bonus") {
      setState(bonus?.bonus);
    } else if (type === "referral") {
      if (general) setState(referral?.general_referral);
      else setState(referral?.referral);
    } else {
      if (general === "authorizer") setState(commission?.manual_commission);
      else if (general) setState(commission?.general_commission);
      else setState(commission?.commission);
    }
  }, [
    type,
    bonus,
    commission,
    general,
    referral,
    wallet?.mainSearch,
    wallet?.isFound,
  ]);

  useEffect(() => {
    getReload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, general]);

  // let [range, setRange] = useState(10);
  const params = useParams();

  const [pageCount, setPageCount] = useState(
    commission?.paginate
      ? Math.ceil(commission?.paginate?.total / commission?.paginate?.limit)
      : 1
  );

  useEffect(() => {
    if (commission?.search_paginate) {
      setPageCount(
        Math.ceil(
          commission?.search_paginate?.total /
            commission?.search_paginate?.limit
        )
      );
    } else if (type === "bonus") {
      setPageCount(Math.ceil(bonus?.paginate?.total / bonus?.paginate?.limit));
    } else if (type === "referral" && general) {
      setPageCount(
        Math.ceil(
          referral?.general_paginate?.total / referral?.general_paginate?.limit
        )
      );
    } else if (type === "referral") {
      setPageCount(
        Math.ceil(referral?.paginate?.total / referral?.paginate?.limit)
      );
    } else if (general === "authorizer") {
      setPageCount(
        Math.ceil(
          commission?.manual_paginate?.total /
            commission?.manual_paginate?.limit
        )
      );
    } else if (general) {
      setPageCount(
        Math.ceil(
          commission?.general_paginate?.total /
            commission?.general_paginate?.limit
        )
      );
    } else {
      setPageCount(
        Math.ceil(commission?.paginate?.total / commission?.paginate?.limit)
      );
    }
  }, [
    type,
    general,
    commission?.search_paginate,
    bonus?.paginate,
    referral?.general_paginate,
    referral?.paginate,
    commission?.manual_paginate,
    commission?.general_paginate,
    commission?.paginate,
  ]);

  // const [itemOffset, setItemOffset] = useState(0);
  if (!state) return;

  // const endOffset = itemOffset + range;

  // const currentItems = state.slice(itemOffset, endOffset);
  // const pageCount = Math.ceil(state.length / range);

  const currentItems = state;

  const handlePageClick = async (event) => {
    // const newOffset = (event.selected * range) % state.length;
    // setItemOffset(newOffset);

    await getWalletHistory(
      type === "bonus"
        ? "bonus"
        : type === "referral"
        ? "referral"
        : "commission",
      general
        ? {
            // page: Number(
            //   type === "referral"
            //     ? search
            //       ? wallet?.search_paginate?.nextPage
            //       : referral?.general_paginate?.nextPage
            //     : type === "bonus"
            //     ? search
            //       ? wallet?.search_paginate?.nextPage
            //       : bonus?.paginate?.nextPage
            //     : search
            //     ? wallet?.search_paginate?.nextPage
            //     : commission?.general_paginate?.nextPage
            // ),
            page: Number(event?.selected + 1),

            general: general === "authorizer" ? general : "general",
          }
        : {
            page: Number(
              // type === "referral"
              //   ? search
              //     ? wallet?.search_paginate?.nextPage
              //     : referral?.paginate?.nextPage
              //   : type === "bonus"
              //   ? search
              //     ? wallet?.search_paginate?.nextPage
              //     : bonus?.paginate?.nextPage
              //   : search
              //   ? wallet?.search_paginate?.nextPage
              //   : commission?.paginate?.nextPage
              event?.selected + 1
            ),
          }
    );
  };

  const headers = [
    { title: "Description", field: "description" },
    { title: "Amount", field: "amount" },
    { title: "Previous Balance", field: "prev_balance" },
    { title: "Balance", field: "balance" },
    { title: "Type", field: "type" },
    { title: "Date & Time", field: "createdAt" },
  ];

  const data = currentItems.map((item) => ({
    description: item?.description ? `${item?.description}` : "",
    amount: item?.amount
      ? `₦ ${numberWithCommas(Number(item?.amount).toFixed(2))}`
      : "",
    prev_balance: item?.prevBalance
      ? `₦ ${numberWithCommas(Number(item?.prevBalance).toFixed(2))}`
      : "",
    balance: item?.balance
      ? `₦ ${numberWithCommas(Number(item?.balance).toFixed(2))}`
      : "",
    type: item?.type ? `${item?.type}` : "",
    createdAt: moment(item?.createdAt).format("DD/MM/YYYY hh:mm A"),
  }));

  return (
    <div className="py-5">
      <div className="w-50 w50 mb-3">
        <input
          type="search"
          name="search"
          id="Search"
          className="form-control w-100 py-3 borderColor2"
          placeholder="Type here to search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className="tw-flex tw-justify-between tw-items-center">
        <div className="tw-flex-1">
          {/* <MainRanger range={range} setRange={setRange} /> */}
        </div>
        <ExportButtons2
          headers={headers}
          data={data}
          fileName={`${params?.page} ${params?.id ? `(${params?.id})` : ""}`}
        />
      </div>
      <div className="bland row mx-0 py-3 px-0 text-capitalize">
        <div className="col textTrunc fontReduce fw-bold Lexend d-none d-md-flex">
          Description
        </div>
        <div className="col textTrunc fontReduce fw-bold Lexend">Amount</div>
        <div className="col textTrunc fontReduce fw-bold Lexend">
          Previous Balance
        </div>
        <div className="col textTrunc fontReduce fw-bold Lexend">Balance</div>
        <div className="col textTrunc fontReduce fw-bold Lexend">Type</div>
        <div className="col textTrunc fontReduce fw-bold Lexend d-none d-md-flex">
          Data & Time
        </div>
      </div>
      <div className="bland2 row mx-0">
        {currentItems?.length === 0 ? (
          <EmptyComponent
            subtitle={`${
              type === "bonus"
                ? "Bonus"
                : type === "referral"
                ? "Referral"
                : "Commission"
            } list is empty`}
          />
        ) : (
          currentItems?.map((item, index) => (
            <div
              key={index}
              onClick={() => setThisData(item)}
              className="row mx-0 py-3 px-0 myCursor"
            >
              <div className="col textTrunc fontReduce2 my-auto textTrunc textTrunc3 d-none d-md-flex">
                {item?.description}
              </div>
              <div className="col textTrunc fontReduce2 my-auto">
                {nairaSign}
                {numberWithCommas(Number(item?.amount).toFixed(2))}
              </div>
              <div className="col textTrunc fontReduce2 my-auto">
                {nairaSign}
                {numberWithCommas(Number(item?.prevBalance).toFixed(2))}
              </div>
              <div className="col textTrunc fontReduce2 my-auto">
                {nairaSign}
                {numberWithCommas(Number(item?.balance).toFixed(2))}
              </div>
              <div
                className={`col textTrunc fontReduce2 my-auto text-capitalize ${
                  item?.type === "credit" ? "text-success" : "text-danger"
                }`}
              >
                {item?.type}
              </div>
              <div className="col textTrunc fontReduce2 my-auto d-none d-md-flex">
                {moment(item?.createdAt).format("DD/MM/YYYY hh:mm A")}
              </div>
            </div>
          ))
        )}
      </div>
      <MainPaginate handlePageClick={handlePageClick} pageCount={pageCount} />
      <BottomTab
        state={state}
        paginate={
          wallet?.isFound
            ? wallet?.search_paginate
            : type === "bonus"
            ? bonus?.paginate
            : type === "referral" && general
            ? referral?.general_paginate
            : type === "referral"
            ? referral?.paginate
            : general === "authorizer"
            ? commission?.manual_paginate
            : general
            ? commission?.general_paginate
            : commission?.paginate
        }
      />
      {/* <LoadMore
        next={
          wallet?.isFound
            ? wallet?.search_paginate?.next
            : type === "bonus"
            ? bonus?.paginate?.next
            : type === "referral" && general
            ? referral?.general_paginate
            : type === "referral"
            ? referral?.paginate?.next
            : general === "authorizer"
            ? commission?.manual_paginate?.next
            : general
            ? commission?.general_paginate?.next
            : commission?.paginate?.next
        }
        handleLoadMore={handleLoadMore}
        loading={loading}
      /> */}
      <WalletDetails thisData={thisData} setThisData={setThisData} />
    </div>
  );
};

const TransferList = ({ setThisData }) => {
  const { wallet, getWalletHistory, getReload } = useContext(GlobalState);
  // let [loading, setLoading] = useState(false),
  let [search, setSearch] = useState(""),
    [state, setState] = useState(null);

  useEffect(() => {
    getWalletHistory("wallet");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (search) {
      document.getElementById("Search").addEventListener("search", () => {
        getReload();
      });
      let handleSubmit = async () => {
        if (!search) return;

        await getWalletHistory("wallet", {
          search,
        });
      };
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (wallet.isFound) {
      setState(wallet.mainSearch);
    } else setState(wallet.wallet);
  }, [wallet.wallet, wallet.isFound, wallet.mainSearch]);

  useEffect(() => {
    getReload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // let handleLoadMore = async () => {
  //   setLoading(true);

  //   if (search) {
  //     await getWalletHistory("wallet", {
  //       page: Number(wallet?.search_paginate?.nextPage),
  //       // limit: Number(
  //       //   wallet?.search_paginate?.nextPage * wallet?.search_paginate?.limit
  //       // ),
  //       search,
  //     });
  //   } else {
  //     await getWalletHistory("wallet", {
  //       page: Number(wallet?.paginate?.nextPage),
  //       // limit: Number(wallet?.paginate?.nextPage * wallet?.paginate?.limit),
  //     });
  //   }
  //   setLoading(false);
  // };

  return (
    <>
      <HistoryData
        search={search}
        setSearch={setSearch}
        setThisData={setThisData}
        state={state}
      />
      <BottomTab
        state={state}
        paginate={search ? wallet?.search_paginate : wallet?.paginate}
      />
      {/* <LoadMore
        next={search ? wallet?.search_paginate?.next : wallet?.paginate?.next}
        handleLoadMore={handleLoadMore}
        loading={loading}
      /> */}
    </>
  );
};

export let CardList = ({ bg, details, selectBg }) => {
  const { wallet } = useContext(GlobalState);

  let [state, setState] = useState(null);

  useEffect(() => {
    setState(bg ? wallet?.cards?.slice(0, 2) : wallet?.cards);
  }, [bg, wallet?.cards]);

  if (!state) return;

  return (
    <>
      <div>
        {state?.map((it, i) => (
          <div
            key={i}
            onClick={details ? () => details(it) : () => {}}
            className={`my-3 d-flex align-items-center rounded10 myCursor ${
              bg ? "" : "bg-light"
            }p-3 ${selectBg === it?.card_number ? "list-group-item-info" : ""}`}
          >
            <div className="d-flex me-2">
              <div
                className="p-3 d-flex rounded10 align-items-center justify-content-center"
                style={{ background: i % 2 === 0 ? "#EFEFEF" : "#34302F" }}
              >
                {it?.brand?.toLowerCase() === "visa" ? (
                  <FaCcVisa
                    size={30}
                    color={i % 2 !== 0 ? "#EFEFEF" : "#34302F"}
                  />
                ) : (
                  <FaCcMastercard
                    size={30}
                    color={i % 2 !== 0 ? "#EFEFEF" : "#34302F"}
                  />
                )}
              </div>
            </div>
            <div>
              <h6 className="fw-bold text-dark">
                *
                {
                  it?.card_number?.split(" ")[
                    it?.card_number?.split(" ")?.length - 1
                  ]
                }
              </h6>
              <small className="fw-bold text-dark text-capitalize">
                {it?.brand}
              </small>
              {/* <h6 className="fw-bold">
								{it?.number}{" "}
								<BiCopy
									size={20}
									className="ms-3 myCursor"
									onClick={() => {
										navigator.clipboard.writeText(it?.number).then(
											() => {
												toast.info("Copied");
											},
											err => {
												toast.warn(`Could not copy: ${err}`);
											}
										);
									}}
								/>{" "}
							</h6> */}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export const WalletDetails = ({ thisData, setThisData }) => {
  let { numberWithCommas, auth, nairaSign } = useContext(GlobalState);
  return (
    <>
      <ModalComponents
        isOpen={thisData ? true : false}
        toggle={() => setThisData(false)}
        title="Wallet details"
      >
        <div className="downH2 d-flex flex-column">
          <p className="text-capitalize border-bottom d-flex justify-content-between">
            <span>Id: </span>
            <span
              className="fontInherit Lexend force-d-flex myCursor"
              onClick={
                thisData?.item_id
                  ? () => {
                      navigator.clipboard.writeText(thisData?.item_id).then(
                        () => {
                          toast.info("Copied", { autoClose: 2000 });
                        },
                        (err) => {
                          toast.warn(`Could not copy: ${err}`, {
                            autoClose: 2000,
                          });
                        }
                      );
                    }
                  : null
              }
            >
              {thisData?.item_id}
              <BiCopy />
            </span>{" "}
          </p>
          <p className="text-capitalize border-bottom d-flex justify-content-between">
            <span>type: </span>
            <span
              className={`fontInherit Lexend ${
                thisData?.type === "credit"
                  ? "text-success2 text-success-2 text-success"
                  : "text-danger2"
              }`}
            >
              {thisData?.type}
            </span>{" "}
          </p>
          {thisData?.type === "credit" &&
            thisData?.title === "wallet" &&
            thisData?.case !== "manual" &&
            (thisData?.data?.reference ||
              thisData?.data?.flw_ref ||
              thisData?.data?.transactionReference ||
              thisData?.wallet?.item_id) && (
              <p className="text-capitalize border-bottom d-flex justify-content-between">
                <span>Reference: </span>
                <span
                  className="fontInherit Lexend force-d-flex myCursor"
                  onClick={
                    thisData?.data?.reference ||
                    thisData?.data?.flw_ref ||
                    thisData?.data?.transactionReference ||
                    thisData?.wallet?.item_id
                      ? () => {
                          navigator.clipboard
                            .writeText(
                              thisData?.data?.reference ||
                                thisData?.data?.flw_ref ||
                                thisData?.data?.transactionReference ||
                                thisData?.wallet?.item_id
                            )
                            .then(
                              () => {
                                toast.info("Copied", { autoClose: 2000 });
                              },
                              (err) => {
                                toast.warn(`Could not copy: ${err}`, {
                                  autoClose: 2000,
                                });
                              }
                            );
                        }
                      : null
                  }
                >
                  {thisData?.data?.reference ||
                    thisData?.data?.flw_ref ||
                    thisData?.data?.transactionReference ||
                    thisData?.wallet?.item_id}
                  <BiCopy />
                </span>{" "}
              </p>
            )}
          {thisData?.channel && thisData?.channel !== "auth" && (
            <p className="text-capitalize border-bottom d-flex justify-content-between">
              <span>Channel: </span>
              <span className="fontInherit Lexend">
                {thisData?.channel}
              </span>{" "}
            </p>
          )}
          {auth?.user?.isAdmin && (
            <p className="border-bottom d-flex justify-content-between">
              <span className="text-capitalize">
                User{thisData?.user?.initEmail && <>(Deleted)</>}:{" "}
              </span>
              <span>
                <span className="fontInherit Lexend d-block text-capitalize">
                  {thisData?.user?.lastName} {thisData?.user?.firstName}
                </span>{" "}
                <span className="fontInherit Lexend d-block">
                  {thisData?.user?.initTelephone || thisData?.user?.telephone}
                </span>{" "}
                <span className="fontInherit Lexend d-block">
                  {thisData?.user?.initEmail || thisData?.user?.email}
                </span>{" "}
                {thisData?.user?.privilege && (
                  <span className="fontInherit Lexend d-block tw-uppercase">
                    {thisData?.user?.privilege}
                  </span>
                )}
              </span>
            </p>
          )}
          {thisData?.user?.wallet?.wallet_id && (
            <p className="text-capitalize border-bottom d-flex justify-content-between">
              <span>Wallet Id: </span>
              <span
                className="fontInherit Lexend force-d-flex myCursor"
                onClick={
                  thisData?.user?.wallet?.wallet_id
                    ? () => {
                        navigator.clipboard
                          .writeText(thisData?.user?.wallet?.wallet_id)
                          .then(
                            () => {
                              toast.info("Copied", { autoClose: 2000 });
                            },
                            (err) => {
                              toast.warn(`Could not copy: ${err}`, {
                                autoClose: 2000,
                              });
                            }
                          );
                      }
                    : null
                }
              >
                {thisData?.user?.wallet?.wallet_id}
                <BiCopy />
              </span>{" "}
            </p>
          )}
          <p className="text-capitalize border-bottom d-flex justify-content-between">
            <span>Amount: </span>
            <span className="fontInherit Lexend">
              {nairaSign}{" "}
              {thisData?.amount
                ? numberWithCommas(Number(thisData?.amount).toFixed(2))
                : 0}
            </span>{" "}
          </p>
          {thisData?.transaction && thisData?.transPath === "Transaction" && (
            <p className="text-capitalize border-bottom d-flex justify-content-between">
              <span>recipient: </span>
              <span
                className="fontInherit Lexend force-d-flex myCursor"
                onClick={
                  thisData?.transaction?.properties
                    ? () => {
                        navigator.clipboard
                          .writeText(
                            thisData?.transaction?.type === "cables" ? (
                              thisData?.transaction?.properties?.smartCardNo
                            ) : thisData?.transaction?.type ===
                              "electricity" ? (
                              thisData?.transaction?.properties?.meterNo
                            ) : thisData?.transaction?.type === "airtime" ? (
                              thisData?.transaction?.properties?.phone
                            ) : thisData?.transaction?.type === "education" ? (
                              <>{thisData?.transaction?.properties?.type}</>
                            ) : thisData?.transaction?.type === "data" ? (
                              thisData?.transaction?.properties?.phone
                            ) : thisData?.transaction?.type === "biz" ? (
                              thisData?.transaction?.properties?.name
                            ) : thisData?.transaction?.type === "verify" ? (
                              thisData?.transaction?.properties?.verify
                            ) : (
                              ""
                            )
                          )
                          .then(
                            () => {
                              toast.info("Copied", { autoClose: 2000 });
                            },
                            (err) => {
                              toast.warn(`Could not copy: ${err}`, {
                                autoClose: 2000,
                              });
                            }
                          );
                      }
                    : null
                }
              >
                {thisData?.transaction?.type === "cables" ? (
                  thisData?.transaction?.properties?.smartCardNo
                ) : thisData?.transaction?.type === "electricity" ? (
                  thisData?.transaction?.properties?.meterNo
                ) : thisData?.transaction?.type === "airtime" ? (
                  thisData?.transaction?.properties?.phone
                ) : thisData?.transaction?.type === "education" ? (
                  <>{thisData?.transaction?.properties?.type}</>
                ) : thisData?.transaction?.type === "data" ? (
                  thisData?.transaction?.properties?.phone
                ) : thisData?.transaction?.type === "biz" ? (
                  thisData?.transaction?.properties?.name
                ) : thisData?.transaction?.type === "verify" ? (
                  thisData?.transaction?.properties?.verify
                ) : (
                  ""
                )}
                <BiCopy />
              </span>{" "}
            </p>
          )}
          <p className="text-capitalize border-bottom d-flex justify-content-between">
            <span>{thisData?.status ? "Previous " : "Initial "} Balance: </span>
            <span className="fontInherit Lexend">
              {nairaSign}{" "}
              {thisData?.prevBalance
                ? numberWithCommas(Number(thisData?.prevBalance).toFixed(2))
                : 0}
            </span>{" "}
          </p>
          <p className="text-capitalize border-bottom d-flex justify-content-between">
            <span>{thisData?.status ? "Current " : "Expected "}Balance: </span>
            <span className="fontInherit Lexend">
              {nairaSign}{" "}
              {thisData?.balance
                ? numberWithCommas(Number(thisData?.balance).toFixed(2))
                : 0}
            </span>{" "}
          </p>
          <p className="text-capitalize border-bottom d-flex justify-content-between">
            <span>Description: </span>
            <span className="fontInherit Lexend">
              {thisData?.description}{" "}
              {thisData?.channel === "auto buy"
                ? ` via ${thisData?.channel}`
                : ""}
            </span>{" "}
          </p>
          <p className="text-capitalize border-bottom d-flex justify-content-between">
            <span>date&time: </span>
            <span className="fontInherit Lexend">
              {moment(thisData?.createdAt).format("DD/MM/YYYY hh:mm A")}
            </span>{" "}
          </p>
          <p className="text-capitalize border-bottom d-flex justify-content-between">
            <span>Status: </span>
            <span
              className={`fontInherit Lexend ${
                thisData?.status
                  ? "text-success2 text-success-2 text-success"
                  : "text-danger2"
              }`}
            >
              {thisData?.statusText === "funded"
                ? "successful"
                : thisData?.statusText?.replace(/[_]/g, " ")}
            </span>{" "}
          </p>
          {auth?.user?.isAdmin && thisData?.authorizer && (
            <>
              <p className="border-bottom d-flex justify-content-between">
                <span className="text-capitalize">Admin: </span>
                <span>
                  <span className="fontInherit Lexend d-block text-capitalize">
                    {thisData?.authorizer?.lastName}{" "}
                    {thisData?.authorizer?.firstName}
                  </span>{" "}
                  <span className="fontInherit Lexend d-block">
                    {thisData?.authorizer?.telephone}
                  </span>{" "}
                  <span className="fontInherit Lexend d-block">
                    {thisData?.authorizer?.email}
                  </span>{" "}
                  {thisData?.authorizer?.privilege && (
                    <span className="fontInherit Lexend d-block tw-uppercase">
                      {thisData?.authorizer?.privilege}
                    </span>
                  )}
                </span>
              </p>
            </>
          )}
          <div className="ms-auto d-flex align-items-center">
            <button
              className="btn btn-danger-2 btn-danger2 d-block ms-auto me-2"
              onClick={() => setThisData(false)}
            >
              Close
            </button>
          </div>
        </div>
      </ModalComponents>
    </>
  );
};

// export const HistoryData = ({ state, search, setSearch, setThisData }) => {
//   const { numberWithCommas, nairaSign } = useContext(GlobalState);

//   let [range, setRange] = useState(10);

//   const [itemOffset, setItemOffset] = useState(0);
//   if (!state) return;

//   const endOffset = itemOffset + range;

//   const currentItems = state.slice(itemOffset, endOffset);
//   const pageCount = Math.ceil(state.length / range);

//   const handlePageClick = (event) => {
//     const newOffset = (event.selected * range) % state.length;
//     setItemOffset(newOffset);
//   };
//   return (
//     <>
//       <div className="w-50 w50 mb-3">
//         <input
//           type="search"
//           name="search"
//           id="Search"
//           className="form-control w-100 py-3 borderColor2"
//           placeholder="Type here to search"
//           value={search}
//           onChange={(e) => setSearch(e.target.value)}
//         />
//       </div>
//       <MainRanger range={range} setRange={setRange} />
//       <div className="bland row mx-0 py-3 px-0 text-capitalize">
//         <div className="col textTrunc fontReduce fw-bold Lexend">S/N</div>
//         <div className="col textTrunc fontReduce fw-bold Lexend d-none d-md-flex"></div>
//         <div className="col textTrunc fontReduce fw-bold Lexend">
//           Description
//         </div>
//         <div className="col textTrunc fontReduce fw-bold Lexend">Amount</div>
//         <div className="col textTrunc fontReduce fw-bold Lexend">
//           Previous balance
//         </div>
//         <div className="col textTrunc fontReduce fw-bold Lexend">Balance</div>
//         <div className="col textTrunc fontReduce fw-bold Lexend">date&time</div>
//         <div className="col d-none d-md-flex"></div>
//       </div>
//       {currentItems?.length === 0 ? (
//         <EmptyComponent subtitle={"Wallet is empty"} />
//       ) : (
//         currentItems?.map((it, i) => (
//           <div
//             onClick={() => setThisData(it)}
//             key={i}
//             className="row mx-0 py-3 border-bottom myCursor"
//           >
//             <div className="col my-auto text-capitalize fontReduce2 textTrunc py-3 py-md-4">
//               {i + 1}
//             </div>
//             <div className="col d-none d-md-flex fontReduce2">
//               <div className="d-flex">
//                 <div
//                   className="p-3 d-flex rounded10 align-items-center justify-content-center"
//                   style={{ background: `${colorArr[i % colorArr?.length]}` }}
//                 >
//                   <RiShieldStarFill
//                     size={24}
//                     color={`${
//                       colorArr[i % colorArr?.length] === "#000000"
//                         ? "#fff"
//                         : "#000"
//                     }`}
//                   />
//                 </div>
//               </div>
//             </div>
//             <div className="col my-auto text-capitalize textTrunc textTrunc2 fw-md-bold fontReduce2">
//               {it?.description}
//             </div>
//             <div className="col my-auto fontReduce2 d-flex w-100">
//               <span className="fontInherit d-none d-md-flex me-md-1">
//                 {nairaSign}
//               </span>{" "}
//               {it?.amount ? numberWithCommas(Number(it?.amount).toFixed(2)) : 0}
//             </div>
//             <div className="col my-auto fontReduce2 d-flex w-100">
//               <span className="fontInherit d-none d-md-flex me-md-1">
//                 {nairaSign}
//               </span>{" "}
//               {it?.prevBalance
//                 ? numberWithCommas(Number(it?.prevBalance).toFixed(2))
//                 : 0}
//             </div>
//             <div className="col my-auto fontReduce2 d-flex w-100">
//               <span className="fontInherit d-none d-md-flex me-md-1">
//                 {nairaSign}
//               </span>{" "}
//               {it?.balance
//                 ? numberWithCommas(Number(it?.balance).toFixed(2))
//                 : 0}
//             </div>
//             <div className="col my-auto fontReduce2">
//               {moment(it?.createdAt).format("DD/MM/YYYY hh:mm A")}
//             </div>
//             <div className="col my-auto d-none d-md-flex fontReduce2">
//               <div className="d-flex">
//                 <div
//                   className={`p-3 d-flex rounded10 align-items-center justify-content-center shadow2 myCursor horizHover ${
//                     it?.type === "credit"
//                       ? "list-group-item-success"
//                       : "list-group-item-danger"
//                   }`}
//                 >
//                   <BiDotsHorizontalRounded size={24} />
//                 </div>
//               </div>
//             </div>
//           </div>
//         ))
//       )}
//       <MainPaginate handlePageClick={handlePageClick} pageCount={pageCount} />
//     </>
//   );
// };

export const HistoryData = ({ state, search, setSearch, setThisData }) => {
  const { numberWithCommas, nairaSign, wallet, getWalletHistory } =
    useContext(GlobalState);

  // let [range, setRange] = useState(10);
  const params = useParams();

  const [pageCount, setPageCount] = useState(
    wallet?.paginate
      ? Math.ceil(wallet?.paginate?.total / wallet?.paginate?.limit)
      : 1
  );

  useEffect(() => {
    if (wallet?.search_paginate) {
      setPageCount(
        Math.ceil(
          wallet?.search_paginate?.total / wallet?.search_paginate?.limit
        )
      );
    } else {
      setPageCount(
        Math.ceil(wallet?.paginate?.total / wallet?.paginate?.limit)
      );
    }
  }, [wallet?.search_paginate, wallet?.paginate]);

  // const [itemOffset, setItemOffset] = useState(0);
  if (!state) return;

  // const endOffset = itemOffset + range;

  // const currentItems = state.slice(itemOffset, endOffset);
  // const pageCount = Math.ceil(state.length / range);

  const currentItems = state;

  const handlePageClick = async (event) => {
    // const newOffset = (event.selected * range) % state.length;
    // setItemOffset(newOffset);
    if (search) {
      await getWalletHistory("wallet", {
        page: Number(event?.selected + 1),

        search,
      });
    } else {
      await getWalletHistory("wallet", {
        page: Number(event?.selected + 1),
      });
    }
  };
  return (
    <>
      <div className="w-50 w50 mb-3">
        <input
          type="search"
          name="search"
          id="Search"
          className="form-control w-100 py-3 borderColor2"
          placeholder="Type here to search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className="tw-relative tw-overflow-x-auto tw-overflow-y-visible tw-min-h-52">
        <div className="tw-flex tw-justify-between tw-items-center">
          <div className="tw-flex-1">
            {/* <MainRanger range={range} setRange={setRange} /> */}
          </div>
          <ExportButtons
            tableId="walletHistory"
            fileName={`${params?.page} ${params?.id ? `(${params?.id})` : ""}`}
          />
        </div>

        <table id="walletHistory" className="tw-w-full tw-text-sm tw-text-left">
          <thead className="tw-text-xs tw-text-gray-700 tw-captalize bland tw-px-1">
            <tr>
              <th scope="col" className="tw-px-6 tw-py-6 Lexend tw-uppercase">
                S/N
              </th>
              {/* <th
								scope="col"
								className="tw-px-6 tw-py-6 Lexend tw-uppercase"></th> */}
              <th scope="col" className="tw-px-6 tw-py-6 Lexend tw-uppercase">
                description
              </th>
              <th scope="col" className="tw-px-6 tw-py-6 Lexend tw-uppercase">
                amount
              </th>
              <th scope="col" className="tw-px-6 tw-py-6 Lexend tw-uppercase">
                previous amount
              </th>
              <th scope="col" className="tw-px-6 tw-py-6 Lexend tw-uppercase">
                balance
              </th>
              <th scope="col" className="tw-px-6 tw-py-6 Lexend tw-uppercase">
                date&time
              </th>
              <th
                scope="col"
                className="tw-px-6 tw-py-6 Lexend tw-uppercase"
              ></th>
            </tr>
          </thead>
          <tbody>
            {currentItems?.map((it, i) => (
              <tr
                onClick={() => setThisData(it)}
                className={`bg-white tw-border-b tw-text-xs px-1 py-3 myCursor`}
                key={i}
              >
                <td className="tw-w-4 tw-p-4">{i + 1}</td>
                {/* <td className="tw-px-6 tw-py-6">
									<div className="d-flex">
										<div
											className="p-3 d-flex rounded10 align-items-center justify-content-center"
											style={{
												background: `${colorArr[i % colorArr?.length]}`,
											}}>
											<RiShieldStarFill
												size={24}
												color={`${
													colorArr[i % colorArr?.length] === "#000000"
														? "#fff"
														: "#000"
												}`}
											/>
										</div>
									</div>
								</td> */}
                <td className="tw-px-6 tw-py-6">{it?.description}</td>
                <td className="tw-px-6 tw-py-6">
                  <span className="tw-flex tw-items-center">
                    <span className="fontInherit d-none d-md-flex me-md-1">
                      {nairaSign}
                    </span>{" "}
                    {it?.amount
                      ? numberWithCommas(Number(it?.amount).toFixed(2))
                      : 0}
                  </span>
                </td>
                <td className="tw-px-6 tw-py-6 tw-uppercase">
                  <span className="tw-flex tw-items-center">
                    <span className="fontInherit d-none d-md-flex me-md-1">
                      {nairaSign}
                    </span>{" "}
                    {it?.prevBalance
                      ? numberWithCommas(Number(it?.prevBalance).toFixed(2))
                      : 0}
                  </span>
                </td>
                <td className="tw-px-6 tw-py-6">
                  <span className="tw-flex tw-items-center">
                    <span className="fontInherit d-none d-md-flex me-md-1">
                      {nairaSign}
                    </span>{" "}
                    {it?.balance
                      ? numberWithCommas(Number(it?.balance).toFixed(2))
                      : 0}
                  </span>
                </td>
                <td className="tw-px-6 tw-py-6">
                  {moment(it?.createdAt).format("DD/MM/YYYY hh:mm A")}
                </td>
                <td className="tw-px-6 tw-py-6">
                  <div className="d-flex">
                    <div
                      className={`p-3 d-flex rounded10 align-items-center justify-content-center shadow2 myCursor horizHover ${
                        it?.type === "credit"
                          ? "list-group-item-success"
                          : "list-group-item-danger"
                      }`}
                    >
                      <BiDotsHorizontalRounded size={24} />
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <MainPaginate handlePageClick={handlePageClick} pageCount={pageCount} />
      </div>
    </>
  );
};

export const PendingHistory = ({ setThisData }) => {
  let [active, setActive] = useState(0);
  return (
    <>
      <div className="btn-group pb-3">
        <button
          onClick={() => setActive(0)}
          className={`btn text-capitalize fw-bold Lexend ${
            active === 0 ? "text-dark border-bottom" : "text-muted"
          }`}
        >
          wallet transfer
        </button>
        <button
          onClick={() => setActive(1)}
          className={`btn text-capitalize fw-bold Lexend ${
            active === 1 ? "text-dark border-bottom" : "text-muted"
          }`}
        >
          virtual payment
        </button>
        <button
          onClick={() => setActive(2)}
          className={`btn text-capitalize fw-bold Lexend ${
            active === 2 ? "text-dark border-bottom" : "text-muted"
          }`}
        >
          card payment
        </button>
      </div>
      <div>
        {active === 2 ? (
          <PayStackPending setThisData={setThisData} />
        ) : active === 1 ? (
          <MonnifyPending setThisData={setThisData} />
        ) : (
          <TransferPending setThisData={setThisData} />
        )}
      </div>
    </>
  );
};

export let MonnifyPending = ({ setThisData }) => {
  const { pending_virtual, loadAllPending, getReload } =
    useContext(GlobalState);
  let [loading, setLoading] = useState(false),
    [search, setSearch] = useState(""),
    [state, setState] = useState(null);

  useEffect(() => {
    if (search) {
      document.getElementById("Search").addEventListener("search", () => {
        getReload();
      });
      let handleSubmit = async () => {
        if (!search) return;

        await loadAllPending({
          search,
          type: "virtual",
        });
      };
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (pending_virtual.isFound) {
      setState(
        pending_virtual.mainSearch?.filter(
          (item) => item?.action !== "approved"
        )
      );
    } else
      setState(
        pending_virtual.virtual?.filter((item) => item?.action !== "approved")
      );
  }, [
    pending_virtual.virtual,
    pending_virtual.isFound,
    pending_virtual.mainSearch,
  ]);

  useEffect(() => {
    getReload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let handleLoadMore = async () => {
    setLoading(true);

    if (search) {
      await loadAllPending({
        page: Number(pending_virtual?.paginate?.nextPage),
        // limit: Number(
        //   pending_virtual?.paginate?.nextPage * pending_virtual?.paginate?.limit
        // ),
        search,
        type: "virtual",
      });
    } else {
      await loadAllPending({
        page: Number(pending_virtual?.paginate?.nextPage),
        // limit: Number(
        //   pending_virtual?.paginate?.nextPage * pending_virtual?.paginate?.limit
        // ),
        type: "virtual",
      });
    }
    setLoading(false);
  };

  if (!state) return <></>;

  return (
    <>
      <PendingHistoryData
        search={search}
        setSearch={setSearch}
        setThisData={setThisData}
        state={state}
      />
      <BottomTab
        state={state}
        paginate={
          search ? pending_virtual?.search_paginate : pending_virtual?.paginate
        }
      />
      <LoadMore
        next={
          search
            ? pending_virtual?.search_paginate?.next
            : pending_virtual?.paginate?.next
        }
        handleLoadMore={handleLoadMore}
        loading={loading}
      />
    </>
  );
};

export let TransferPending = ({ setThisData }) => {
  const { pending_wallet, loadAllPending, getReload } = useContext(GlobalState);
  let [loading, setLoading] = useState(false),
    [search, setSearch] = useState(""),
    [state, setState] = useState(null);

  useEffect(() => {
    if (search) {
      document.getElementById("Search").addEventListener("search", () => {
        getReload();
      });
      let handleSubmit = async () => {
        if (!search) return;

        await loadAllPending({
          search,
          type: "transfer",
        });
      };
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (pending_wallet.isFound) {
      setState(
        pending_wallet.mainSearch?.filter((item) => item?.action !== "approved")
      );
    } else
      setState(
        pending_wallet.wallet?.filter((item) => item?.action !== "approved")
      );
  }, [
    pending_wallet.wallet,
    pending_wallet.isFound,
    pending_wallet.mainSearch,
  ]);

  useEffect(() => {
    getReload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let handleLoadMore = async () => {
    setLoading(true);

    if (search) {
      await loadAllPending({
        page: Number(pending_wallet?.paginate?.nextPage),
        // limit: Number(
        //   pending_wallet?.paginate?.nextPage * pending_wallet?.paginate?.limit
        // ),
        search,
        type: "transfer",
      });
    } else {
      await loadAllPending({
        page: Number(pending_wallet?.paginate?.nextPage),
        // limit: Number(
        //   pending_wallet?.paginate?.nextPage * pending_wallet?.paginate?.limit
        // ),
        type: "transfer",
      });
    }
    setLoading(false);
  };

  if (!state) return <></>;

  return (
    <>
      <PendingHistoryData
        search={search}
        setSearch={setSearch}
        setThisData={setThisData}
        state={state}
      />
      <BottomTab
        state={state}
        paginate={
          search ? pending_wallet?.search_paginate : pending_wallet?.paginate
        }
      />
      <LoadMore
        next={
          search
            ? pending_wallet?.search_paginate?.next
            : pending_wallet?.paginate?.next
        }
        handleLoadMore={handleLoadMore}
        loading={loading}
      />
    </>
  );
};

export let PayStackPending = ({ setThisData }) => {
  const { pending_card, loadAllPending, getReload } = useContext(GlobalState);
  let [loading, setLoading] = useState(false),
    [search, setSearch] = useState(""),
    [state, setState] = useState(null);

  useEffect(() => {
    if (search) {
      document.getElementById("Search").addEventListener("search", () => {
        getReload();
      });
      let handleSubmit = async () => {
        if (!search) return;

        await loadAllPending({
          search,
          type: "card",
        });
      };
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (pending_card.isFound) {
      setState(
        pending_card.mainSearch?.filter((item) => item?.action !== "approved")
      );
    } else
      setState(
        pending_card.card?.filter((item) => item?.action !== "approved")
      );
  }, [pending_card.card, pending_card.isFound, pending_card.mainSearch]);

  useEffect(() => {
    getReload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let handleLoadMore = async () => {
    setLoading(true);

    if (search) {
      await loadAllPending({
        page: Number(pending_card?.paginate?.nextPage),
        // limit: Number(
        //   pending_card?.paginate?.nextPage * pending_card?.paginate?.limit
        // ),
        search,
        type: "card",
      });
    } else {
      await loadAllPending({
        page: Number(pending_card?.paginate?.nextPage),
        // limit: Number(
        //   pending_card?.paginate?.nextPage * pending_card?.paginate?.limit
        // ),
        type: "card",
      });
    }
    setLoading(false);
  };

  if (!state) return <></>;

  return (
    <>
      <PendingHistoryData
        search={search}
        setSearch={setSearch}
        setThisData={setThisData}
        state={state}
      />
      <BottomTab
        state={state}
        paginate={
          search ? pending_card?.search_paginate : pending_card?.paginate
        }
      />
      <LoadMore
        next={
          search
            ? pending_card?.search_paginate?.next
            : pending_card?.paginate?.next
        }
        handleLoadMore={handleLoadMore}
        loading={loading}
      />
    </>
  );
};

export const PendingHistoryData = ({
  state,
  search,
  setSearch,
  setThisData,
}) => {
  const { numberWithCommas, nairaSign } = useContext(GlobalState);

  let [range, setRange] = useState(10);
  const params = useParams();

  const [itemOffset, setItemOffset] = useState(0);
  if (!state) return;

  const endOffset = itemOffset + range;

  const currentItems = state.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(state.length / range);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * range) % state.length;
    setItemOffset(newOffset);
  };

  const headers = [
    { title: "Type", field: "type" },
    { title: "Channel", field: "channel" },
    { title: "Amount", field: "amount" },
    { title: "Previous Balance", field: "previous_balance" },
    { title: "Balance", field: "balance" },
    { title: "Date & Time", field: "createdAt" },
  ];

  const data = currentItems.map((item, index) => ({
    type: item?.type ? `${item?.type}` : "",
    channel:
      item?.type || item?.provider
        ? `${
            item?.type === "transfer"
              ? "Wallet transfer"
              : item?.type === "virtual"
              ? "Monnify"
              : item?.provider === "paystack"
              ? "Paystack"
              : "Flutterwave"
          }`
        : "",
    amount: item?.amount
      ? `₦ ${numberWithCommas(Number(item?.amount).toFixed(2))}`
      : 0,
    previous_balance: item?.wallet?.prevBalance
      ? `₦ ${numberWithCommas(Number(item?.wallet?.prevBalance).toFixed(2))}`
      : 0,
    balance: item?.wallet?.balance
      ? `₦ ${numberWithCommas(Number(item?.wallet?.balance).toFixed(2))}`
      : 0,
    createdAt: moment(item?.createdAt).format("DD/MM/YYYY hh:mm A"),
  }));

  return (
    <>
      <div className="w-50 w50 mb-3">
        <input
          type="search"
          name="search"
          id="Search"
          className="form-control w-100 py-3 borderColor2"
          placeholder="Type here to search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className="tw-flex tw-justify-between tw-items-center">
        <div className="tw-flex-1">
          <MainRanger range={range} setRange={setRange} />
        </div>
        <ExportButtons2
          headers={headers}
          data={data}
          fileName={`${params?.page} ${params?.id ? `(${params?.id})` : ""}`}
        />
      </div>
      <div className="bland row mx-0 py-3 px-0 text-capitalize">
        <div className="col textTrunc fontReduce fw-bold Lexend d-none d-md-flex"></div>
        <div className="col textTrunc fontReduce fw-bold Lexend d-none d-md-flex">
          type
        </div>
        <div className="col textTrunc fontReduce fw-bold Lexend">Channel</div>
        <div className="col textTrunc fontReduce fw-bold Lexend">Amount</div>
        <div className="col textTrunc fontReduce fw-bold Lexend">
          Previous balance
        </div>
        <div className="col textTrunc fontReduce fw-bold Lexend">Balance</div>
        <div className="col textTrunc fontReduce fw-bold Lexend">date&time</div>
        <div className="col d-none d-md-flex"></div>
      </div>
      {currentItems?.length === 0 ? (
        <EmptyComponent subtitle={"Wallet is empty"} />
      ) : (
        currentItems?.map((it, i) => (
          <div
            onClick={() => setThisData(it)}
            key={i}
            className="row mx-0 py-3 border-bottom myCursor"
          >
            <div className="col d-none d-md-flex fontReduce2">
              <div className="d-flex">
                <div
                  className="p-3 d-flex rounded10 align-items-center justify-content-center"
                  style={{ background: `${colorArr[i % colorArr?.length]}` }}
                >
                  <RiShieldStarFill
                    size={24}
                    color={`${
                      colorArr[i % colorArr?.length] === "#000000"
                        ? "#fff"
                        : "#000"
                    }`}
                  />
                </div>
              </div>
            </div>
            <div className="col my-auto text-capitalize d-none d-md-flex fw-md-bold fontReduce2">
              {it?.type}
            </div>
            <div className="col my-auto text-capitalize textTrunc textTrunc2 fw-md-bold fontReduce2">
              {it?.type === "transfer"
                ? "Wallet transfer"
                : it?.type === "virtual"
                ? "Monnify"
                : it?.provider === "paystack"
                ? "Paystack"
                : "Flutterwave"}
            </div>
            <div className="col my-auto fontReduce2 d-flex w-100">
              <span className="fontInherit d-none d-md-flex me-md-1">
                {nairaSign}
              </span>{" "}
              {it?.amount ? numberWithCommas(Number(it?.amount).toFixed(2)) : 0}
            </div>
            <div className="col my-auto fontReduce2 d-flex w-100">
              <span className="fontInherit d-none d-md-flex me-md-1">
                {nairaSign}
              </span>{" "}
              {it?.wallet?.prevBalance
                ? numberWithCommas(Number(it?.wallet?.prevBalance).toFixed(2))
                : 0}
            </div>
            <div className="col my-auto fontReduce2 d-flex w-100">
              <span className="fontInherit d-none d-md-flex me-md-1">
                {nairaSign}
              </span>{" "}
              {it?.wallet?.balance
                ? numberWithCommas(Number(it?.wallet?.balance).toFixed(2))
                : 0}
            </div>
            <div className="col my-auto fontReduce2">
              {moment(it?.createdAt).format("DD/MM/YYYY hh:mm A")}
            </div>
            <div className="col my-auto d-none d-md-flex fontReduce2">
              <div className="d-flex">
                <div
                  className={`p-3 d-flex rounded10 align-items-center justify-content-center shadow2 myCursor horizHover ${
                    it?.type === "credit"
                      ? "list-group-item-success"
                      : "list-group-item-danger"
                  }`}
                >
                  <BiDotsHorizontalRounded size={24} />
                </div>
              </div>
            </div>
          </div>
        ))
      )}
      <MainPaginate handlePageClick={handlePageClick} pageCount={pageCount} />
    </>
  );
};

export const PendingWalletDetails = ({ thisData, setThisData }) => {
  let {
      numberWithCommas,
      auth,
      updatePending,
      pending_wallet,
      pending_card,
      pending_virtual,
      nairaSign,
    } = useContext(GlobalState),
    [loading, setLoading] = useState(false),
    [submit, setSubmit] = useState(false);

  let handleAction = (type) => async (e) => {
    e?.preventDefault();
    setLoading(true);
    await updatePending(thisData, type === "decline" ? "declined" : "");
    setLoading(false);
    setSubmit(true);
  };

  useEffect(() => {
    if (submit && pending_wallet?.isUpdated) {
      setThisData(null);
      setSubmit(false);
    }
    if (submit && pending_virtual?.isUpdated) {
      setThisData(null);
      setSubmit(false);
    }
    if (submit && pending_card?.isUpdated) {
      setThisData(null);
      setSubmit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    submit,
    pending_wallet?.isUpdated,
    pending_card?.isUpdated,
    pending_virtual?.isUpdated,
  ]);

  return (
    <>
      <ModalComponents
        isOpen={thisData ? true : false}
        toggle={() => setThisData(false)}
        title="Pending Wallet details"
      >
        <div className="downH2 d-flex flex-column">
          <p className="text-capitalize border-bottom d-flex justify-content-between">
            <span>Id: </span>
            <span className="fontInherit Lexend">
              {thisData?.wallet?.item_id}
            </span>{" "}
          </p>
          <p className="text-capitalize border-bottom d-flex justify-content-between">
            <span>type: </span>
            <span className="fontInherit Lexend">
              {thisData?.type === "transfer"
                ? "Wallet transfer"
                : thisData?.type === "virtual"
                ? "Monnify"
                : thisData?.provider === "paystack"
                ? "Paystack"
                : "Flutterwave"}
            </span>{" "}
          </p>
          <p className="text-capitalize border-bottom d-flex justify-content-between">
            <span>date&time: </span>
            <span className="fontInherit Lexend">
              {moment(thisData?.createdAt).format("DD/MM/YYYY hh:mm A")}
            </span>{" "}
          </p>
          {auth?.user?.isAdmin && (
            <p className="border-bottom d-flex justify-content-between">
              <span className="text-capitalize">
                User{thisData?.user?.initEmail && <>(Deleted)</>}:{" "}
              </span>
              <span>
                <span className="fontInherit Lexend d-block text-capitalize">
                  {thisData?.user?.lastName} {thisData?.user?.firstName}
                </span>{" "}
                <span className="fontInherit Lexend d-block">
                  {thisData?.user?.initTelephone || thisData?.user?.telephone}
                </span>{" "}
                <span className="fontInherit Lexend d-block">
                  {thisData?.user?.initEmail || thisData?.user?.email}
                </span>{" "}
                {thisData?.user?.privilege && (
                  <span className="fontInherit Lexend d-block tw-uppercase">
                    {thisData?.user?.privilege}
                  </span>
                )}
              </span>
            </p>
          )}
          <p className="text-capitalize border-bottom d-flex justify-content-between">
            <span>Amount: </span>
            <span className="fontInherit Lexend">
              {nairaSign}{" "}
              {thisData?.amount
                ? numberWithCommas(Number(thisData?.amount).toFixed(2))
                : 0}
            </span>{" "}
          </p>
          <p className="text-capitalize border-bottom d-flex justify-content-between">
            <span>
              {thisData?.wallet?.status ? "Previous " : "Initial "} Balance:{" "}
            </span>
            <span className="fontInherit Lexend">
              {nairaSign}{" "}
              {thisData?.wallet?.prevBalance
                ? numberWithCommas(
                    Number(thisData?.wallet?.prevBalance).toFixed(2)
                  )
                : 0}
            </span>{" "}
          </p>
          <p className="text-capitalize border-bottom d-flex justify-content-between">
            <span>{thisData?.wallet?.status ? "" : "Expected "}Balance: </span>
            <span className="fontInherit Lexend">
              {nairaSign}{" "}
              {thisData?.wallet?.balance
                ? numberWithCommas(Number(thisData?.wallet?.balance).toFixed(2))
                : 0}
            </span>{" "}
          </p>
          {thisData?.type === "transfer" && (
            <>
              {auth?.user?.isAdmin && (
                <p className="border-bottom d-flex justify-content-between">
                  <span className="text-capitalize">Receiver: </span>
                  <span>
                    <span className="fontInherit Lexend d-block text-capitalize">
                      {thisData?.wallet2?.user?.lastName}{" "}
                      {thisData?.user?.firstName}
                    </span>{" "}
                    <span className="fontInherit Lexend d-block">
                      {thisData?.wallet2?.user?.telephone}
                    </span>{" "}
                    <span className="fontInherit Lexend d-block">
                      {thisData?.wallet2?.user?.email}
                    </span>{" "}
                    {thisData?.wallet2?.user?.privilege && (
                      <span className="fontInherit Lexend d-block tw-uppercase">
                        {thisData?.wallet2?.user?.privilege}
                      </span>
                    )}
                  </span>
                </p>
              )}
              <p className="text-capitalize border-bottom d-flex justify-content-between">
                <span>
                  Receivers'{"  "}
                  {thisData?.wallet2?.status ? "Previous " : "Initial "}{" "}
                  Balance:{" "}
                </span>
                <span className="fontInherit Lexend">
                  {nairaSign}{" "}
                  {thisData?.wallet2?.prevBalance
                    ? numberWithCommas(
                        Number(thisData?.wallet2?.prevBalance).toFixed(2)
                      )
                    : 0}
                </span>{" "}
              </p>
              <p className="text-capitalize border-bottom d-flex justify-content-between">
                <span>
                  Receivers' {thisData?.wallet2?.status ? "" : "Expected "}
                  Balance:{" "}
                </span>
                <span className="fontInherit Lexend">
                  {nairaSign}{" "}
                  {thisData?.wallet2?.balance
                    ? numberWithCommas(
                        Number(thisData?.wallet2?.balance).toFixed(2)
                      )
                    : 0}
                </span>{" "}
              </p>
            </>
          )}
          <p className="text-capitalize border-bottom d-flex justify-content-between">
            <span>Description: </span>
            <span className="fontInherit Lexend">
              {thisData?.wallet?.description}
            </span>{" "}
          </p>
          <p className="text-capitalize border-bottom d-flex justify-content-between">
            <span>Status: </span>
            <span
              className={`fontInherit Lexend ${
                thisData?.action === "approved"
                  ? "text-success2 text-success-2 text-success"
                  : "text-danger2"
              }`}
            >
              {thisData?.action}
            </span>{" "}
          </p>
          {thisData?.action === "pending" && (
            <div className="col textTrunc my-auto btn-group fontReduce2 w-50 w50 mx-auto">
              <Buttons
                loading={loading}
                css="btn btn-success2 text-capitalize p-2 p-md-3 w-100 fontReduce2"
                title={"approve"}
                onClick={handleAction("approve")}
              />
              {thisData?.type === "transfer" && (
                <>
                  <Buttons
                    loading={loading}
                    css="btn btn-danger2 text-capitalize p-2 p-md-3 w-100 fontReduce2"
                    title={"decline"}
                    onClick={handleAction("decline")}
                  />
                </>
              )}
            </div>
          )}
          <div className="ms-auto d-flex align-items-center">
            <button
              className="btn btn-danger-2 btn-danger2 d-block ms-auto me-2"
              onClick={() => setThisData(false)}
            >
              Close
            </button>
          </div>
        </div>
      </ModalComponents>
    </>
  );
};
