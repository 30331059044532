import React, { useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container } from "reactstrap";
import { GlobalState } from "../../Data/Context";
import Wallet from "../../Assets/wallet.svg";
import Bonus from "../../Assets/bonus.svg";
import Transaction from "../../Assets/transaction.svg";
import Details from "../../Assets/details.svg";
import Manage from "../../Assets/manage.svg";
import Topup from "../../Assets/topup.svg";
// import Funding from "../../Assets/funding.svg";
import Profit from "../../Assets/profit.svg";
import Withdraw from "../../Assets/withdraw.svg";
import Stat from "../../Assets/stat.svg";
import Debit from "../../Assets/debit.svg";

const ManageWallet = () => {
  let { setStateName } = useContext(GlobalState);
  useEffect(() => {
    setStateName("Manage Wallet");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let navigate = useNavigate(),
    params = useParams(),
    controlsTab = [
      {
        name: "my wallet",
        type: "link",
        icon: Wallet,
        link: `/${params?.page}/my-wallet`,
        color: "linear-gradient(90deg, #DE0DE2 16.14%, #0E102D 101.45%)",
        textColor: "white",
      },
      {
        name: "general wallet details",
        type: "link",
        icon: Details,
        link: `/${params?.page}/manage-wallet`,
        color: "linear-gradient(90deg, #F45F83 16.14%, #9E1A2A 101.45%)",
        textColor: "white",
      },
      {
        name: "Wallet provider stat",
        type: "link",
        icon: Stat,
        link: `/${params?.page}/provider`,
        color: "linear-gradient(96.86deg, #F2E553 18.88%, #FF9900 125.77%)",
      },
      {
        name: "general transaction stat",
        type: "link",
        icon: Stat,
        link: `/${params?.page}/manage-transactions`,
        color: "linear-gradient(90.18deg, #3199B7 -52.19%, #144468 81.92%)",
        textColor: "white",
      },
      {
        name: "manage transaction stat",
        type: "link",
        icon: Stat,
        link: `/${params?.page}/find-transactions`,
        color:
          "linear-gradient(96.86deg, rgba(83, 242, 147, 0.8) 18.88%, rgba(158, 255, 0, 0.8) 125.77%)",
      },
      {
        name: "Data profit",
        type: "link",
        icon: Profit,
        link: `/${params?.page}/bonus-data`,
        color:
          "linear-gradient(90deg, rgba(228, 51, 105, 0.7) 16.14%, rgba(194, 14, 25, 0.7) 101.45%)",
        textColor: "white",
      },
      {
        name: "give bonus",
        type: "link",
        icon: Bonus,
        link: `/${params?.page}/give-bonus`,
        color: "linear-gradient(90.18deg, #6CB731 -52.19%, #0F5A16 81.92%)",
        textColor: "white",
      },
      {
        name: "manual top up",
        type: "link",
        icon: Topup,
        link: `/${params?.page}/manual`,
        color: "linear-gradient(90deg, #E43369 16.14%, #C20E19 101.45%)",
        textColor: "white",
      },
      {
        textColor: "white",
        name: "direct debit",
        type: "link",
        icon: Debit,
        link: `/${params?.page}/manual-debit`,
        color: "linear-gradient(90.18deg, #3199B7 -52.19%, #144468 81.92%)",
      },
      {
        name: "Withdrawal",
        type: "link",
        icon: Withdraw,
        link: `/${params?.page}/withdrawal`,
        color: "linear-gradient(96.86deg, #F2E553 18.88%, #FF9900 125.77%)",
      },
      {
        name: "Wallet funding",
        type: "link",
        icon: Topup,
        link: `/${params?.page}/wallet-funding`,
        color:
          "linear-gradient(90deg, rgba(228, 51, 105, 0.7) 16.14%, rgba(194, 14, 25, 0.7) 101.45%)",
        textColor: "white",
      },
      {
        name: "Referral",
        type: "link",
        icon: Transaction,
        link: `/${params?.page}/referral`,
        color: "linear-gradient(90deg, #E43369 16.14%, #C20E19 101.45%)",
        textColor: "white",
      },
      {
        name: "Commission",
        type: "link",
        icon: Manage,
        link: `/${params?.page}/commissions`,
        color: "linear-gradient(90deg, #E43369 16.14%, #C20E19 101.45%)",
        textColor: "white",
      },
    ];

  return (
    <div className="bg-white aboutScreen tw-py-10">
      <Container>
        <div className="tw-grid tw-grid-cols-4 tw-w-5/6 tw-mx-auto tw-gap-8">
          {controlsTab?.map((item, i) => (
            <div
              className="tw-bg-white tw-flex tw-justify-between tw-items-center tw-px-3 tw-capitalize tw-shadow-xl tw-h-20"
              key={i}
              onClick={() => {
                if (item?.type === "link") {
                  navigate(item?.link);
                } else if (item?.type === "button") {
                  // if (item?.link === "debit") toggle();
                }
              }}
            >
              <img src={item.icon} alt="" className="" />
              <span className="">
                <span className="tw-text-base tw-text-[#1b1b1b] tw-cursor-pointer tw-font-semiboold">
                  {item?.name}
                </span>
              </span>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default ManageWallet;
