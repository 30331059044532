import React, { useContext, useEffect, useState } from "react";
import { Container } from "reactstrap";
import { Buttons } from "../Utils";
import img1 from "../Assets/Group 42924.png";
import moment from "moment";
import { GlobalState } from "../Data/Context";
import { toast } from "react-toastify";
import { useValidation } from "../Data/useFetch";
import { BottomTab } from "./LoadMore";
import { ModalComponents } from "./DefaultHeader";
import { BiCheck, BiCopy, BiDotsHorizontalRounded } from "react-icons/bi";
import { HiThumbDown } from "react-icons/hi";
import { MainPaginate } from "./Transactions";
import { NetworkList, NetworkList2 } from "./Products/airtime";
import { ExportButtons2 } from "../Utils/Export Functions/ExportButtons";
import { useParams } from "react-router-dom";
import { NetworkLoader } from "../Utils/NetworkLoader";

const MainConvert = () => {
  let { setStateName, auth, converterServices } = useContext(GlobalState);
  useEffect(() => {
    setStateName("airtime converter");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    converterServices("get", "converter");
    converterServices("get", "banks");
    converterServices("get", "converter-number");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bg-white aboutScreen">
      <Container>
        {!auth?.user?.isAdmin ? <ConvertTop /> : <ConvertAgentTop />}
        <ConverterHistory />
      </Container>
    </div>
  );
};

export default MainConvert;

const ConvertTop = () => {
  const { converterServices, converter, network } = useContext(GlobalState);
  let init = {
      account_number: "",
      account_name: "",
      bank_name: "",
      bank_code: "",
      reference: "",
      amount: "",
      network: "",
      send_to: "",
    },
    [returnValue, setReturnValue] = useState(""),
    [loading, setLoading] = useState(false),
    [newState, setNewState] = useState(false),
    [submit, setSubmit] = useState(false),
    [state, setState] = useState(init),
    textChange =
      (name) =>
      ({ target: { value } }) => {
        setState({ ...state, [name]: value });
      },
    handleSubmitCard = async (e) => {
      if (e) e.preventDefault();
      if (
        !state?.bank_code &&
        !state?.bank_name &&
        !state?.account_name &&
        !state?.account_number &&
        !state?.reference
      )
        return toast.info("Please provide bank name and account number", {
          autoClose: 10000,
        });
      setLoading(true);
      await converterServices("post", "converter", state);
      setLoading(false);
      setSubmit(true);
    },
    { validateLoading, handleFetch } = useValidation(
      "banks",
      state,
      setNewState
    );

  useEffect(() => {
    if (state?.account_number?.length === 10 && state?.bank_code) handleFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.account_number, state?.bank_code]);

  useEffect(() => {
    if (state?.bank_code) {
      converter?.banks?.map(
        (item) =>
          item?.code === state?.bank_code &&
          setState({ ...state, bank_name: item?.name })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.bank_code, converter]);

  useEffect(() => {
    if (newState) {
      setState({
        ...state,
        account_name: newState?.data?.account_name,
        account_number: newState?.data?.account_number,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newState]);
  // console.log({newState});
  useEffect(() => {
    if (submit && converter?.isAdded) {
      setSubmit(false);
      setState(init);
      setReturnValue("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [converter, submit]);

  useEffect(() => {
    if (state?.amount && state?.network) {
      let commission = 80;
      let findIt = converter?.numbers?.map(
        (item) => item?.network?.toLowerCase() === state?.network?.toLowerCase()
      );
      if (findIt) commission = findIt?.percentage;

      setReturnValue(
        Number(Number(state?.amount) * (commission / 100)).toFixed(2)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.amount, state?.network]);

  useEffect(() => {
    if (state?.network) {
      converter?.numbers?.map(
        (item) =>
          item?.network?.toLowerCase() === state?.network?.toLowerCase() &&
          setState({ ...state, send_to: item?.telephone })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.network, converter?.numbers]);
  // console.log({ state, numbers: converter?.numbers });
  return (
    <>
      <section className="row mx-0">
        <form
          className="mt-4 col-md-7 row mx-0 g-3 g-md-5"
          onSubmit={handleSubmitCard}
        >
          <div className="mb-3 col-md-6">
            <label className="text-capitalize" htmlFor="network">
              Network
            </label>
            <select
              className="form-control py-3 py-md-4 bg-transparent text-capitalize rounded20 form-select"
              name="network"
              placeholder="Network"
              value={state?.network}
              onChange={textChange("network")}
              id="network"
            >
              <option value="">select network</option>
              {network?.data?.map((item, i) => (
                <option value={item?.name} key={i}>
                  {item?.name}
                </option>
              ))}
            </select>
          </div>
          {state?.network && (
            <div className="mb-3 col-md-6">
              <label className="text-capitalize" htmlFor="name">
                Number to send to
              </label>
              <input
                type="text"
                name="reference"
                className="form-control py-3 py-md-4 bg-transparent rounded20"
                value={state?.send_to}
                readOnly
              />
            </div>
          )}
          <div className="mb-3 col-md-6">
            <label className="text-capitalize" htmlFor="name">
              Phone number
            </label>
            <input
              type="text"
              required
              className="form-control py-3 py-md-4 bg-transparent rounded20"
              name="phone"
              value={state?.reference}
              onChange={textChange("reference")}
            />
          </div>
          <div className="mb-3 col-md-6">
            <label className="text-capitalize" htmlFor="bank_code">
              Account Bank
            </label>
            <select
              className="form-control py-3 py-md-4 bg-transparent text-capitalize form-select"
              name="bank_code rounded20"
              placeholder="Account Bank"
              value={state?.bank_code}
              onChange={textChange("bank_code")}
              readOnly={validateLoading}
              id="bank_code"
            >
              <option value="">select bank</option>
              {converter?.banks?.map((item, i) => (
                <option value={item?.code} key={i}>
                  {item?.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3 col-md-6">
            <label className="text-capitalize" htmlFor="name">
              Bank account
            </label>
            <input
              type="number"
              className="form-control py-3 py-md-4 bg-transparent rounded20"
              required
              name="account_number"
              readOnly={validateLoading}
              value={state?.account_number}
              onChange={textChange("account_number")}
            />
          </div>
          {state?.account_name && state?.account_number?.length === 10 && (
            <div className="mb-3 col-md-6">
              <label className="text-capitalize" htmlFor="name">
                Account name
              </label>
              <input
                type="text"
                required
                name="account_name"
                readOnly
                className="form-control py-3 py-md-4 bg-transparent rounded20"
                value={state?.account_name}
                onChange={textChange("account_name")}
              />
            </div>
          )}
          <div className="mb-3 col-md-6">
            <label className="text-capitalize" htmlFor="name">
              Amount
            </label>
            <input
              type="number"
              required
              name="amount"
              className="form-control py-3 py-md-4 bg-transparent rounded20"
              value={state?.amount}
              onChange={textChange("amount")}
            />
          </div>
          {state?.amount && (
            <div className="mb-3 col-md-6">
              <label className="text-capitalize" htmlFor="name">
                Return Amount
              </label>
              <input
                type="number"
                name="amount"
                readOnly
                className="form-control py-3 py-md-4 bg-transparent rounded20"
                value={returnValue}
                onChange={textChange("returnValue")}
              />
            </div>
          )}
          <div>
            <Buttons
              title={"convert"}
              css="btn-primary1 text-capitalize py-3 w-50 my-4"
              width={"w-50"}
              style={{ borderRadius: "30px" }}
              loading={loading}
              onClick={handleSubmitCard}
            />
          </div>
        </form>
        <div className="col-lg-5 h-100 my-auto d-none d-md-flex">
          <img src={img1} alt="Banner" className="img-fluid mx-auto" />
        </div>
      </section>
    </>
  );
};

const ConvertAgentTop = () => {
  let { converterServices, converter } = useContext(GlobalState);
  let [isNumber, setIsNumber] = useState(false),
    [isUpdateNumber, setIsUpdateNumber] = useState(false),
    init = { network: "", telephone: "", percentage: "80" },
    [state, setState] = useState(init),
    [networkLoading, setNetworkLoading] = useState(false),
    [submit, setSubmit] = useState(false),
    [loading, setLoading] = useState(false);
  const params = useParams();
  let clearAll = () => {
    setIsNumber(false);
    setIsUpdateNumber(false);
    setSubmit(false);
    setState(init);
  };
  useEffect(() => {
    if (submit && converter?.isNumberAdded) {
      clearAll();
    }
    if (submit && converter?.isNumberUpdated) {
      clearAll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit, converter?.isAddedNumber]);

  let handleSubmit = (type, method) => async (e) => {
    e?.preventDefault();
    setLoading(true);
    await converterServices(
      method,
      type,
      isUpdateNumber ? isUpdateNumber : state,
      isUpdateNumber ? isUpdateNumber?._id : ""
    );
    setLoading(false);
    setSubmit(true);
  };

  const headers = [
    { title: "S/N", field: "serialNumber" },
    { title: "Network", field: "network" },
    { title: "Telephone", field: "telephone" },
    { title: "Percentage", field: "percentage" },
  ];

  const data = converter?.numbers?.map((item, index) => ({
    serialNumber: index + 1,
    network: item?.network ? `${item?.network?.toUpperCase()}` : "",
    telephone: item?.telephone ? `${item?.telephone}` : "",
    percentage: item?.percentage ? `${item?.percentage}` : "",
  }));

  return (
    <>
      <div className="py-3 py-md-5">
        <h3 className="Lexend text-capitalize fontReduceBig">
          Phone Number convert
        </h3>
        <div className="tw-flex w-100 tw-justify-between py-1">
          <button
            onClick={() => setIsNumber(true)}
            className="btn-primary1 btn p-3 px-md-5 text-capitalize"
          >
            add new number
          </button>
          <ExportButtons2
            headers={headers}
            data={data}
            fileName={`${params?.page}`}
          />
        </div>
        <div className="py-3">
          <div className="row mx-0 bland p-3 text-capitalize">
            <div className="col textTrunc fontReduce fw-bold Lexend">S/N</div>
            <div className="col textTrunc fontReduce fw-bold Lexend">
              Network
            </div>
            <div className="col textTrunc fontReduce fw-bold Lexend">
              Telephone
            </div>
            <div className="col textTrunc fontReduce fw-bold Lexend">
              Percentage{" "}
            </div>
            <div className="col textTrunc fontReduce fw-bold Lexend">
              Update
            </div>
          </div>
          {converter?.numbers?.map((item, i) => (
            <div className="row bland2 p-3 mx-0 text-uppercase" key={i}>
              <div className="col textTrunc fontReduce2 my-auto">{i + 1}</div>
              <div className="col textTrunc fontReduce2 my-auto">
                <NetworkList2 state={item?.network?.toUpperCase()} />
                {/* {item?.network} */}
              </div>
              <div className="col textTrunc fontReduce2 my-auto">
                {item?.telephone}
              </div>
              <div className="col textTrunc fontReduce2 my-auto">
                {item?.percentage}
              </div>
              <div
                className="col textTrunc fontReduce2 myCursor text-info my-auto"
                onClick={() => setIsUpdateNumber(item)}
              >
                {" "}
                edit{" "}
              </div>
            </div>
          ))}
        </div>
      </div>

      <ModalComponents
        title={"Add new number"}
        isOpen={isNumber}
        back={() => {
          setIsNumber(false);
          setState(init);
        }}
      >
        <form onSubmit={handleSubmit("converter-number", "post")}>
          <div className="mb-3">
            <label className="text-capitalize" htmlFor="name">
              telephone
            </label>
            <input
              type="tel"
              className="form-control py-3 py-md-4 text-capitalize"
              name="phone"
              id="telephone"
              value={state?.telephone}
              onChange={(e) =>
                setState({ ...state, telephone: e.target.value })
              }
              placeholder="0800 000 0000"
              maxLength={11}
            />
          </div>
          {networkLoading && <NetworkLoader />}
          <NetworkList
            state={state?.network}
            setState={(i) => {
              setState({ ...state, network: i });
            }}
            phoneNumber={state?.telephone}
            setNetworkLoading={setNetworkLoading}
          />

          <div className="mb-3">
            <label className="text-capitalize" htmlFor="name">
              Percentage
            </label>
            <input
              type="number"
              className="form-control py-3 py-md-4 text-capitalize"
              name="percentage"
              id="percentage"
              value={state?.percentage}
              onChange={(e) =>
                setState({ ...state, percentage: e.target.value })
              }
              placeholder="80"
              min={0}
            />
          </div>
          <Buttons
            loading={loading}
            title="send"
            onClick={handleSubmit("converter-number", "post")}
            css="btn-primary1 text-capitalize py-3 w-50 my-4 mx-auto"
            width={"w-50"}
          />
        </form>
      </ModalComponents>
      <ModalComponents
        title={"Update number"}
        isOpen={isUpdateNumber}
        back={() => {
          setIsUpdateNumber(false);
          setState(init);
        }}
      >
        <form onSubmit={handleSubmit("converter-number", "put")}>
          <div className="mb-3">
            <label className="text-capitalize" htmlFor="name">
              telephone
            </label>
            <input
              type="tel"
              className="form-control py-3 py-md-4 text-capitalize"
              name="phone"
              id="telephone"
              value={isUpdateNumber?.telephone}
              onChange={(e) =>
                setIsUpdateNumber({
                  ...isUpdateNumber,
                  telephone: e.target.value,
                })
              }
              placeholder="0800 000 0000"
              maxLength={11}
            />
          </div>
          <div className="mb-3">
            <label className="text-capitalize" htmlFor="name">
              Percentage
            </label>
            <input
              type="number"
              className="form-control py-3 py-md-4 text-capitalize"
              name="percentage"
              id="percentage"
              value={isUpdateNumber?.percentage}
              onChange={(e) =>
                setIsUpdateNumber({
                  ...isUpdateNumber,
                  percentage: e.target.value,
                })
              }
              placeholder="80"
              min={0}
            />
          </div>
          <Buttons
            loading={loading}
            title="update"
            onClick={handleSubmit("converter-number", "put")}
            css="btn-primary1 text-capitalize py-3 w-50 my-4 mx-auto"
            width={"w-50"}
          />
        </form>
      </ModalComponents>
    </>
  );
};

const ConverterHistory = () => {
  const {
    converter,
    numberWithCommas,
    converterServices,
    auth,
    nairaSign,
    permission,
    getReload,
  } = useContext(GlobalState);
  let [state, setState] = useState(null),
    [isUpdate, setIsUpdate] = useState(false),
    [isView, setIsView] = useState(false),
    [isDecline, setIsDecline] = useState(false),
    init = { reason: "" },
    [data, setData] = useState(init),
    [submit, setSubmit] = useState(false),
    [search, setSearch] = useState(""),
    [loading2, setLoading2] = useState(false),
    [show, setShow] = useState(false);

  useEffect(() => {
    if (auth?.user?.privilege === "superadmin") {
      setShow(true);
    } else {
      let findTitle = "viewairtimetocash";

      let findPermit = permission?.data?.find(
        (item) =>
          item?.slug === findTitle && item?.user?.includes(auth?.user?._id)
      );
      if (findPermit) {
        setShow(true);
      }
    }
    return () => {
      setShow(false);
    };
  }, [auth?.user, permission?.data]);

  useEffect(() => {
    if (search) {
      document.getElementById("Search").addEventListener("search", () => {
        getReload();
      });
      let handleSubmit = async () => {
        if (!search) return;

        await converterServices("get", "converter", {
          search,
        });
      };
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (converter.isFound) {
      setState(converter.mainSearch);
    } else setState(converter?.airtime);
  }, [converter?.airtime, converter?.mainSearch, converter?.isFound]);

  // let handleLoadMore = async () => {
  //   setLoading(true);

  //   await converterServices("get", "converter", {
  //     page: search
  //       ? Number(converter?.search_paginate?.nextPage)
  //       : Number(converter?.paginate?.nextPage),
  //     // limit: search
  //     // 	? Number(converter?.search_paginate?.nextPage * converter?.paginate?.limit)
  //     // 	: Number(converter?.paginate?.nextPage * converter?.paginate?.limit),
  //     search,
  //   });
  //   setLoading(false);
  // };

  let clearAll = () => {
    setIsDecline(false);
    setIsUpdate(false);
    setSubmit(false);
    setData(init);
  };
  useEffect(() => {
    if (submit && converter?.isUpdated) {
      clearAll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit, converter?.isAddedNumber]);

  let handleSubmit = (type, method) => async (e) => {
    e?.preventDefault();
    setLoading2(true);
    await converterServices(
      method,
      type,
      data,
      isUpdate ? isUpdate?._id : isDecline ? isDecline?._id : ""
    );
    setLoading2(false);
    setSubmit(true);
  };

  // let [range, setRange] = useState(10);
  const params = useParams();
  const [pageCount, setPageCount] = useState(
    converter?.paginate
      ? Math.ceil(converter?.paginate?.total / converter?.paginate?.limit)
      : 1
  );

  useEffect(() => {
    if (converter?.search_paginate) {
      setPageCount(
        Math.ceil(
          converter?.search_paginate?.total / converter?.search_paginate?.limit
        )
      );
    } else {
      setPageCount(
        Math.ceil(converter?.paginate?.total / converter?.paginate?.limit)
      );
    }
  }, [converter?.search_paginate, converter?.paginate]);

  console.log(converter);

  // const [itemOffset, setItemOffset] = useState(0);
  // const endOffset = itemOffset + range;

  if (!state || !show) return;

  // const currentItems = state.slice(itemOffset, endOffset);
  const currentItems = state;
  // const pageCount = Math.ceil(state.length / range);

  const handlePageClick = async (event) => {
    // const newOffset = (event.selected * range) % state.length;
    // setItemOffset(newOffset);
    // setLoading(true);

    await converterServices("get", "converter", {
      page: Number(event?.selected + 1),
      // limit: search
      // 	? Number(converter?.search_paginate?.nextPage * converter?.paginate?.limit)
      // 	: Number(converter?.paginate?.nextPage * converter?.paginate?.limit),
      search,
    });
    // setLoading(false);
  };

  const headers = [
    { title: "ID", field: "id" },
    { title: "Reference", field: "reference" },
    { title: "Amount", field: "amount" },
    { title: "Network", field: "network" },
    { title: "Status", field: "status" },
    { title: "Date & Time", field: "createdAt" },
  ];

  const tableData = currentItems.map((item, index) => ({
    id: item?.item_id ? `${item?.item_id}` : "",
    reference: item?.reference ? `${item?.reference}` : "",
    amount: item?.amount
      ? `₦ ${numberWithCommas(Number(item?.amount).toFixed(2))}`
      : "",
    network: item?.network ? `${item?.network?.toUpperCase()}` : "",
    status: item?.statusText ? `${item?.statusText}` : "",
    createdAt: moment(item?.createdAt).format("DD/MM/YYYY hh:mm A"),
  }));

  return (
    <>
      <div className="py-5">
        <h3 className="Lexend text-capitalize mb-2 fontReduceBig">
          {" "}
          conversion history
        </h3>
        <div className="w-50 w50">
          <input
            type="search"
            name="search"
            id="Search"
            className="form-control w-100 py-3 borderColor2"
            placeholder="Type here to search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className="tw-flex tw-justify-between tw-items-center">
          <div className="tw-flex-1">
            {/* <MainRanger range={range} setRange={setRange} /> */}
          </div>
          <ExportButtons2
            headers={headers}
            data={tableData}
            fileName={`${params?.page} ${params?.id ? `(${params?.id})` : ""}`}
          />
        </div>
        ;
        <div className="bland row mx-0 p-3 text-capitalize">
          <div className="col textTrunc fontReduce fw-bold Lexend d-none d-md-flex">
            ID
          </div>
          <div className="col textTrunc fontReduce fw-bold Lexend">
            Reference
          </div>
          {/* <div className="col textTrunc">number</div> */}
          <div className="col textTrunc fontReduce fw-bold Lexend">amount</div>
          <div className="col textTrunc fontReduce fw-bold Lexend">
            network{" "}
          </div>
          <div className="col textTrunc fontReduce fw-bold Lexend">status</div>
          <div className="col textTrunc fontReduce fw-bold Lexend d-none d-md-flex">
            date&time
          </div>
          <div className="col textTrunc fontReduce fw-bold Lexend">action</div>
        </div>
        <div className="bg-white row mx-0 my-0">
          {currentItems?.map((item, index) => (
            <div key={index} className="row mx-0 px-3 my-0">
              <div className="col d-none d-md-flex textTrunc fontReduce2 py-3 py-md-4">
                {item?.item_id}
              </div>
              <div className="col textTrunc fontReduce2 py-3 py-md-4">
                {item?.reference}
              </div>
              {/* <div className="col textTrunc">{item?.telephone}</div> */}
              <div className="col textTrunc fontReduce2 py-3 py-md-4">
                {nairaSign} {numberWithCommas(Number(item?.amount).toFixed(2))}
              </div>
              <div className="col textTrunc fontReduce2 py-3 py-md-4">
                {/* {item?.network} */}
                <NetworkList2 state={item?.network?.toUpperCase()} />
              </div>
              {/* <div
								className={`col textTrunc text-capitalize fontReduce2 ${
									item?.status
										? "text-success"
										: item?.statusText === "declined"
										? "text-danger"
										: ""
								}`}>
								{item?.statusText}
							</div> */}
              <div
                className={`col textTrunc fontReduce2 text-uppercase py-3 py-md-4 ${
                  item?.statusText?.toLowerCase() === "delivered"
                    ? "text-succcess2"
                    : item?.statusText?.toLowerCase() === "pending"
                    ? "text-dark"
                    : "text-danger2"
                }`}
              >
                {item?.statusText}
              </div>
              <div className="col d-none d-md-flex textTrunc fontReduce2 py-3 py-md-4">
                {moment(item?.createdAt).format("DD/MM/YYYY hh:mm A")}
              </div>
              <div className="col textTrunc btn-group fontReduce2 w-100 py-3 py-md-4">
                <button
                  onClick={() => setIsView(item)}
                  className="btn  btn-primary1 text-capitalize p-1 p-md-2 w-100 fontReduce2"
                >
                  <BiDotsHorizontalRounded />
                </button>
                {auth?.user?.isAdmin && (
                  <>
                    <button
                      onClick={() => setIsUpdate(item)}
                      className="btn  btn-success2 text-capitalize p-1 p-md-2 w-100 fontReduce2"
                    >
                      <BiCheck />
                    </button>
                    <button
                      onClick={() => setIsDecline(item)}
                      className="btn  btn-danger2 text-capitalize p-1 p-md-2 w-100 fontReduce2"
                    >
                      <HiThumbDown />
                    </button>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
        <MainPaginate pageCount={pageCount} handlePageClick={handlePageClick} />
        <BottomTab
          state={state}
          paginate={
            converter?.isFound
              ? converter?.search_paginate
              : converter?.paginate
          }
        />
        {/* <LoadMore
          next={
            converter?.isFound
              ? converter?.search_paginate?.nexh
              : converter?.paginate?.next
          }
          handleLoadMore={handleLoadMore}
          loading={loading}
        /> */}
      </div>
      <ModalComponents
        title={"Decline conversion"}
        isOpen={isDecline}
        back={() => {
          setIsDecline(false);
        }}
      >
        <form onSubmit={handleSubmit("converter", "post")}>
          <div className="mb-3">
            <label className="text-capitalize" htmlFor="name">
              Reason
            </label>
            <textarea
              className="form-control py-3 py-md-4 text-capitalize"
              name="reason"
              id="reason"
              style={{
                resize: "none",
                height: "10rem",
              }}
              value={data?.reason}
              onChange={(e) =>
                setData({
                  ...data,
                  reason: e.target.value,
                })
              }
              placeholder="Reason for decline"
            />
          </div>
          <Buttons
            loading={loading2}
            title="decline"
            onClick={handleSubmit("converter", "post")}
            css="btn-primary1 text-capitalize py-3 w-50 my-4 mx-auto"
            width={"w-50"}
          />
        </form>
      </ModalComponents>
      <ModalComponents
        title={"Mark as done"}
        isOpen={isUpdate ? true : false}
        back={() => {
          setIsUpdate(false);
        }}
      >
        <form
          onSubmit={handleSubmit("converter", "put")}
          className="downH2 d-flex"
        >
          <div className="my-auto w-100">
            <p className="text-center">
              Has the transaction been completed?
              <span className="d-block">
                {isUpdate?.credit_mode === "wallet"
                  ? `Proceed to auto credit user`
                  : ``}
              </span>
            </p>
            <div className="d-flex w-100">
              <Buttons
                loading={loading2}
                title={
                  isUpdate?.credit_mode === "wallet" ? "approve" : "confirm"
                }
                onClick={handleSubmit("converter", "put")}
                css="btn-primary1 text-capitalize py-3 w-50 my-4 mx-auto"
                width={"w-50"}
              />
            </div>
          </div>
        </form>
      </ModalComponents>
      <ModalComponents
        title={"View full details"}
        isOpen={isView}
        back={() => {
          setIsView(false);
        }}
      >
        <div className="downH2 d-flex">
          <div className="w-100">
            <h4 className="Lexend">Conversion details</h4>
            <p className="text-capitalize border-bottom d-flex justify-content-between">
              <span>Id: </span>
              <span
                className="fontInherit Lexend force-d-flex myCursor"
                onClick={
                  isView?.item_id
                    ? () => {
                        navigator.clipboard.writeText(isView?.item_id).then(
                          () => {
                            toast.info("Copied", { autoClose: 2000 });
                          },
                          (err) => {
                            toast.warn(`Could not copy: ${err}`, {
                              autoClose: 2000,
                            });
                          }
                        );
                      }
                    : null
                }
              >
                {isView?.item_id}
                <BiCopy />
              </span>{" "}
            </p>
            <p className="text-capitalize border-bottom d-flex justify-content-between">
              <span>Credit Mode: </span>
              <span className="fontInherit Lexend">
                {isView?.credit_mode}
              </span>{" "}
            </p>
            {isView?.channel && isView?.channel !== "auth" && (
              <p className="text-capitalize border-bottom d-flex justify-content-between">
                <span>Channel: </span>
                <span className="fontInherit Lexend">
                  {isView?.channel}
                </span>{" "}
              </p>
            )}
            {isView?.credit_mode !== "wallet" && (
              <>
                <p className="text-capitalize border-bottom d-flex justify-content-between">
                  <span>Bank name: </span>
                  <span className="fontInherit Lexend">
                    {isView?.bank_name}
                  </span>{" "}
                </p>
                <p className="text-capitalize border-bottom d-flex justify-content-between">
                  <span>Account name: </span>
                  <span className="fontInherit Lexend">
                    {isView?.account_name}
                  </span>{" "}
                </p>
                <p className="text-capitalize border-bottom d-flex justify-content-between">
                  <span>Account number: </span>
                  <span
                    className="fontInherit Lexend force-d-flex myCursor"
                    onClick={
                      isView?.account_number
                        ? () => {
                            navigator.clipboard
                              .writeText(isView?.account_number)
                              .then(
                                () => {
                                  toast.info("Copied", { autoClose: 2000 });
                                },
                                (err) => {
                                  toast.warn(`Could not copy: ${err}`, {
                                    autoClose: 2000,
                                  });
                                }
                              );
                          }
                        : null
                    }
                  >
                    {isView?.account_number}
                    <BiCopy />
                  </span>{" "}
                </p>
              </>
            )}
            <p className="text-capitalize border-bottom d-flex justify-content-between">
              <span>Sender's number: </span>
              <span
                className="fontInherit Lexend force-d-flex myCursor"
                onClick={
                  isView?.reference
                    ? () => {
                        navigator.clipboard.writeText(isView?.reference).then(
                          () => {
                            toast.info("Copied", { autoClose: 2000 });
                          },
                          (err) => {
                            toast.warn(`Could not copy: ${err}`, {
                              autoClose: 2000,
                            });
                          }
                        );
                      }
                    : null
                }
              >
                {isView?.reference}
                <BiCopy />
              </span>
            </p>
            <p className="text-capitalize border-bottom d-flex justify-content-between">
              <span>Network: </span>
              <span className="fontInherit Lexend">{isView?.network}</span>{" "}
            </p>
            {auth?.user?.isAdmin && (
              <>
                <p className="border-bottom d-flex justify-content-between">
                  <span className="text-capitalize">User: </span>
                  <span>
                    <span className="fontInherit Lexend d-block text-capitalize">
                      {isView?.user?.lastName} {isView?.user?.firstName}
                    </span>{" "}
                    <span className="fontInherit Lexend d-block">
                      {isView?.user?.telephone}
                    </span>{" "}
                    <span className="fontInherit Lexend d-block">
                      {isView?.user?.email}
                    </span>{" "}
                    {isView?.user?.privilege && (
                      <span className="fontInherit Lexend d-block tw-uppercase">
                        {isView?.user?.privilege}
                      </span>
                    )}
                  </span>
                </p>
              </>
            )}
            <p className="text-capitalize border-bottom d-flex justify-content-between">
              <span>Amount: </span>
              <span className="fontInherit Lexend">
                {nairaSign}{" "}
                {isView?.amount
                  ? numberWithCommas(Number(isView?.amount).toFixed(2))
                  : 0}
              </span>{" "}
            </p>
            <ReturnAmountView isView={isView} />
            <p className="text-capitalize border-bottom d-flex justify-content-between">
              <span>Receiver's number: </span>
              <span
                className="fontInherit Lexend force-d-flex myCursor"
                onClick={
                  isView?.number_sent_to
                    ? () => {
                        navigator.clipboard
                          .writeText(isView?.number_sent_to)
                          .then(
                            () => {
                              toast.info("Copied", { autoClose: 2000 });
                            },
                            (err) => {
                              toast.warn(`Could not copy: ${err}`, {
                                autoClose: 2000,
                              });
                            }
                          );
                      }
                    : null
                }
              >
                {isView?.number_sent_to}
                <BiCopy />
              </span>{" "}
            </p>
            {auth?.user?.isAdmin && isView?.authorizer && (
              <>
                <p className="border-bottom d-flex justify-content-between">
                  <span className="text-capitalize">Admin: </span>
                  <span>
                    <span className="fontInherit Lexend d-block text-capitalize">
                      {isView?.authorizer?.lastName}{" "}
                      {isView?.authorizer?.firstName}
                    </span>{" "}
                    <span className="fontInherit Lexend d-block">
                      {isView?.authorizer?.telephone}
                    </span>{" "}
                    <span className="fontInherit Lexend d-block">
                      {isView?.authorizer?.email}
                    </span>{" "}
                    {isView?.authorizer?.privilege && (
                      <span className="fontInherit Lexend d-block tw-uppercase">
                        {isView?.authorizer?.privilege}
                      </span>
                    )}
                  </span>
                </p>
              </>
            )}
            <p className="text-capitalize border-bottom d-flex justify-content-between">
              <span>Status: </span>
              <span
                className={`fontInherit Lexend ${
                  isView?.status
                    ? "text-success"
                    : isView?.statusText === "declined"
                    ? "text-danger"
                    : ""
                }`}
              >
                {isView?.statusText}
              </span>
            </p>
            {isView?.statusText === "declined" && (
              <p className="text-capitalize border-bottom d-flex justify-content-between">
                <span>Reason: </span>
                <span className="fontInherit Lexend">
                  {isView?.reason}
                </span>{" "}
              </p>
            )}
            <div className="ms-auto d-flex align-items-center">
              <button
                className="btn btn-danger-2 btn-danger2 d-block ms-auto me-2"
                onClick={() => setIsView(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </ModalComponents>
    </>
  );
};

let ReturnAmountView = ({ isView }) => {
  let [re, setRe] = useState(0),
    { converter, nairaSign, numberWithCommas } = useContext(GlobalState);

  useEffect(() => {
    if (isView?.amount) {
      let commission = 80;
      let sendTO = converter?.numbers?.find(
        (item) =>
          item?.network?.toLowerCase() === isView?.network?.toLowerCase()
      );
      if (sendTO) commission = sendTO?.percentage;

      setRe(Number(Number(isView?.amount) * (commission / 100)).toFixed(2));
    }
  }, [isView?.amount, converter?.numbers, isView?.network]);

  return (
    <>
      <p className="text-capitalize border-bottom d-flex justify-content-between">
        <span>Return Amount: </span>
        <span className="fontInherit Lexend">
          {nairaSign}{" "}
          {numberWithCommas(Number(isView?.returnAmount || re || 0).toFixed(2))}
        </span>{" "}
      </p>
    </>
  );
};
