import React, { useContext, useEffect, useState } from "react";
import { Container } from "reactstrap";
import { GlobalState } from "../../Data/Context";
import moment from "moment";
import { Buttons } from "../../Utils";
import { ModalComponents } from "../../Components";
import { MainPaginate } from "../../Components/Transactions";
import { useNavigate, useParams } from "react-router-dom";
import { ExportButtons2 } from "../../Utils/Export Functions/ExportButtons";

const FindTransactions = () => {
  let { setStateName, stat } = useContext(GlobalState);
  useEffect(() => {
    setStateName("Manage Transactions Stat");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let [daily, setDaily] = useState(null),
    [weekly, setWeekly] = useState(null),
    [monthly, setMonthly] = useState(null),
    navigate = useNavigate(),
    { auth, permission } = useContext(GlobalState);

  useEffect(() => {
    if (auth?.user?.privilege !== "superadmin") {
      let findTitle = "viewmanagetransactionstat";

      let findPermit = permission?.data?.find(
        (item) =>
          item?.slug === findTitle && item?.user?.includes(auth?.user?._id)
      );
      if (!findPermit) {
        navigate(-1);
      }
    }
  }, [auth?.user, permission?.data, navigate]);

  useEffect(() => {
    setDaily(stat?.finder?.daily);
    setWeekly(stat?.finder?.weekly);
    setMonthly(stat?.finder?.monthly);
  }, [stat?.finder]);

  return <MainTransFinder weekly={weekly} daily={daily} monthly={monthly} />;
};

export const MainTransFinder = ({
  weekly,
  daily,
  monthly,
  notype = false,
  dataEnd,
  hideData,
}) => {
  let { findProviderStat, stat, getDataTransactionStat } =
    useContext(GlobalState);

  let [isOpen, setIsOpen] = useState(false),
    [active, setActive] = useState(0),
    init = { category: "", provider: "", network: "", type: "" },
    [state, setState] = useState(init),
    toggle = () => {
      if (isOpen) setState(init);
      setIsOpen(!isOpen);
    },
    [loading, setLoading] = useState(false),
    [submit, setSubmit] = useState(false),
    textChange =
      (name) =>
      ({ target: { value } }) => {
        setState({ ...state, [name]: value });
      },
    handleFind = async () => {
      setLoading(true);
      if (dataEnd) await getDataTransactionStat(state);
      else if (hideData) await getDataTransactionStat(state, "registration");
      else await findProviderStat(state);
      setLoading(false);
      setSubmit(true);
    };

  useEffect(() => {
    if (state.year) {
      localStorage.setItem("Year", state.year);
    }
  }, [state.year]);

  useEffect(() => {
    if (submit && stat?.findLoad) {
      toggle();
      setSubmit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit, stat?.findLoad]);

  let tabControl = ["Daily", "weekly", "monthly"];

  return (
    <>
      <div className="bg-white aboutScreen">
        <Container className="py-3 py-md-5">
          <Buttons
            title={"query selection options"}
            css="btn-primary1 text-capitalize py-3 px-4 px-lg-5"
            width={"w-25 w25"}
            onClick={toggle}
            style={{ borderRadius: "30px" }}
          />
          <div>
            <div className="btn-group w-100 py-3">
              {tabControl?.map((item, i) => (
                <button
                  key={i}
                  className={`btn py-3 text-capitalize fw-bold ${
                    active === i ? "border-bottom textColor" : ""
                  } rounded-0`}
                  onClick={() => setActive(i)}
                >
                  {item}
                </button>
              ))}
            </div>
            {active === 2 ? (
              <MainFindTransactionsStatData
                state={monthly}
                factor="month"
                year={
                  localStorage.getItem("Year")
                    ? localStorage.getItem("Year")
                    : null
                }
                // year={state?.year}
                hideData={hideData}
              />
            ) : active === 1 ? (
              <MainFindTransactionsStatData
                state={weekly}
                factor="week"
                year={
                  localStorage.getItem("Year")
                    ? localStorage.getItem("Year")
                    : null
                }
                // year={state?.year}
                hideData={hideData}
              />
            ) : (
              <MainFindTransactionsStatData
                state={daily}
                factor="day"
                // year={state?.year}
                year={
                  localStorage.getItem("Year")
                    ? localStorage.getItem("Year")
                    : null
                }
                hideData={hideData}
              />
            )}
          </div>
        </Container>{" "}
      </div>
      <FindDetails
        isOpen={isOpen}
        back={toggle}
        state={state}
        textChange={textChange}
        handleFind={handleFind}
        loading={loading}
        notype={notype}
        showYear
        hideData={hideData}
      />
    </>
  );
};

export const FindDetails = ({
  state,
  textChange,
  handleFind,
  loading,
  isOpen,
  back,
  notype,
  filter,
  user,
  showYear,
  hideData,
}) => {
  let { network, category, biller, generalYears } = useContext(GlobalState);
  const { page, id } = useParams();
  return (
    <>
      <ModalComponents
        isOpen={isOpen}
        toggle={back}
        title={`${filter ? "Filter" : "Query"} selection`}
      >
        <form onSubmit={handleFind}>
          {!hideData && (
            <>
              {!user ? (
                <>
                  {!notype && (
                    <div className="mb-4">
                      <label htmlFor="Type">Type</label>
                      <select
                        className="form-control py-3 py-md-4 text-capitalize form-select"
                        name="type"
                        placeholder="Type"
                        value={state?.type}
                        onChange={textChange("type")}
                        id="type"
                      >
                        <option value="">select type</option>
                        <option value="data">Data</option>
                        <option value="airtime">Airtime</option>
                        <option value="cables">Cables subscription</option>
                        <option value="electricity">Electricity</option>
                        <option value="biz">Biz Verification</option>
                        <option value="education">Education</option>
                      </select>
                    </div>
                  )}
                  <div className="mb-4">
                    <label htmlFor="Provider">Provider</label>
                    <select
                      className="form-control py-3 py-md-4 text-capitalize form-select"
                      name="provider"
                      placeholder="Provider"
                      value={state?.provider}
                      onChange={textChange("provider")}
                      id="provider"
                    >
                      <option value="">select provider</option>
                      {biller?.data?.map((item, i) => (
                        <option value={item?.billerId} key={i}>
                          {item?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-4">
                    <label htmlFor="Network">Network</label>
                    <select
                      className="form-control py-3 py-md-4 text-capitalize form-select"
                      name="network"
                      placeholder="Network"
                      value={state?.network}
                      onChange={textChange("network")}
                      id="network"
                    >
                      <option value="">select network</option>
                      {network?.data?.map((item, i) => (
                        <option value={item?.name} key={i}>
                          {item?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-4">
                    <label htmlFor="Category">Category</label>
                    <select
                      className="form-control py-3 py-md-4 text-capitalize form-select"
                      name="category"
                      placeholder="Category"
                      value={state?.category}
                      onChange={textChange("category")}
                      id="category"
                    >
                      <option value="">select category</option>
                      {category?.data?.map((item, i) => (
                        <option value={item?.categoryId} key={i}>
                          {item?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {filter && id !== "failed" && (
                    <>
                      <div className="mb-4">
                        <label htmlFor="Status">Status</label>
                        <select
                          className="form-control py-3 py-md-4 text-capitalize form-select"
                          name="status"
                          placeholder="Status"
                          value={state?.status}
                          onChange={textChange("status")}
                          id="status"
                        >
                          <option value="">select status</option>
                          <option value={200}>Successful</option>
                          <option value={400}>Pending</option>
                          <option value={300}>Failed</option>
                        </select>
                      </div>
                      {!user && (
                        <div className="mb-4">
                          <label htmlFor="Channel">Channel</label>
                          <select
                            className="form-control py-3 py-md-4 text-capitalize form-select"
                            name="channel"
                            placeholder="Channel"
                            value={state?.channel}
                            onChange={textChange("channel")}
                            id="channel"
                          >
                            <option value="">select channel</option>
                            <option value={"web"}>Web</option>
                            <option value={"api"}>API</option>
                            <option value={"auto buy"}>Auto Buy</option>
                            <option value={"auto buy app"}>Auto Buy App</option>
                            <option value={"auto buy web"}>Auto Buy Web</option>
                            <option value={"auto buy admin"}>
                              Auto Buy Admin
                            </option>
                            <option value={"admin"}>Admin</option>
                          </select>
                        </div>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <div className="mb-4">
                    <label htmlFor="Status">Registration Status</label>
                    <select
                      className="form-control py-3 py-md-4 text-capitalize form-select"
                      name="statusText"
                      placeholder="Status"
                      value={state?.statusText}
                      onChange={textChange("statusText")}
                      id="statusText"
                    >
                      <option value="">select status</option>
                      <option value={"pending"}>Pending</option>
                      <option value={"suspended"}>Suspended</option>
                      <option value={"activated"}>Activated</option>
                    </select>
                  </div>
                  <div className="mb-4">
                    <label htmlFor="Status">Account Status</label>
                    <select
                      className="form-control py-3 py-md-4 text-capitalize form-select"
                      name="activeStatus"
                      placeholder="Active Status"
                      value={state?.activeStatus}
                      onChange={textChange("activeStatus")}
                      id="activeStatus"
                    >
                      <option value="">select status</option>
                      <option value={"active"}>Active</option>
                      <option value={"inactive"}>Inactive</option>
                      <option value={"dormant"}>Dormant</option>
                    </select>
                  </div>
                  <div className="mb-4">
                    <label htmlFor="Status">NIN</label>
                    <select
                      className="form-control py-3 py-md-4 text-capitalize form-select"
                      name="ninNumber"
                      placeholder="NIN"
                      value={state?.ninNumber}
                      onChange={textChange("ninNumber")}
                      id="ninNumber"
                    >
                      <option value="">select status</option>
                      <option value={"linked"}>Linked</option>
                      <option value={"unlinked"}>Unlinked</option>
                    </select>
                  </div>
                  <div className="mb-4">
                    <label htmlFor="Status">BVN</label>
                    <select
                      className="form-control py-3 py-md-4 text-capitalize form-select"
                      name="bvnNumber"
                      placeholder="BVN"
                      value={state?.bvnNumber}
                      onChange={textChange("bvnNumber")}
                      id="bvnNumber"
                    >
                      <option value="">select status</option>
                      <option value={"linked"}>Linked</option>
                      <option value={"unlinked"}>Unlinked</option>
                    </select>
                  </div>
                  <div className="mb-4">
                    <label htmlFor="Status">Available</label>
                    <select
                      className="form-control py-3 py-md-4 text-capitalize form-select"
                      name="available"
                      placeholder="Available"
                      value={state?.available}
                      onChange={textChange("available")}
                      id="available"
                    >
                      <option value="">select status</option>
                      <option value={"zero"}>Zero Account</option>
                    </select>
                  </div>
                </>
              )}
            </>
          )}
          {showYear && (
            <div className="mb-4">
              <select
                className="form-control py-3 py-md-4 text-capitalize form-select"
                value={state?.year}
                onChange={textChange("year")}
                name="year"
                id=""
              >
                <option value="">Select a year</option>
                {generalYears?.map((it, x) => (
                  <option value={it} key={x}>
                    {it}
                  </option>
                ))}
              </select>
            </div>
          )}
          {filter && <DateFilter state={state} textChange={textChange} />}
          <Buttons
            title={filter ? "Filter" : "Query"}
            css="btn-primary1 text-capitalize py-3 w-50 my-4 mx-auto"
            width={"w-50"}
            onClick={handleFind}
            loading={loading}
            style={{ borderRadius: "30px" }}
          />
        </form>
      </ModalComponents>
    </>
  );
};

export const DateFilter = ({ state, textChange }) => {
  return (
    <>
      <div className="mb-4">
        <label htmlFor="Start Date">Start Date</label>
        <input
          className="form-control py-3 py-md-4 text-capitalize"
          name="startDate"
          type="datetime-local"
          placeholder="Start Date"
          value={state?.startDate}
          onChange={textChange("startDate")}
          id="startDate"
        />
      </div>
      {state?.startDate && (
        <div className="mb-4">
          <label htmlFor="End Date">End Date</label>
          <input
            className="form-control py-3 py-md-4 text-capitalize"
            name="endDate"
            type="datetime-local"
            placeholder="End Date"
            value={state?.endDate}
            onChange={textChange("endDate")}
            id="endDate"
          />
        </div>
      )}
    </>
  );
};

export default FindTransactions;

export let MainFindTransactionsStatData = ({
  state,
  factor,
  year,
  hideData,
}) => {
  let { numberWithCommas, nairaSign } = useContext(GlobalState),
    [total, setTotal] = useState(0);

  useEffect(() => {
    if (state) {
      let dataTotal = state?.reduce((ac, i) => (ac += Number(i?.dataTotal)), 0);
      setTotal(Number(dataTotal));
    }
  }, [state]);

  let [range, setRange] = useState(10);
  const params = useParams();

  const [itemOffset, setItemOffset] = useState(0);
  if (!state) return;

  const endOffset = itemOffset + range;

  const currentItems = state.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(state.length / range);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * range) % state.length;
    setItemOffset(newOffset);
  };

  const headers = hideData
    ? [
        { title: "S/N", field: "serialNumber" },
        { title: "APP", field: "app" },
        { title: "WEB", field: "web" },
        { title: "COUNT", field: "count" },
      ]
    : [
        { title: "S/N", field: "serialNumber" },
        { title: "VALUE", field: "value" },
        { title: "COUNT", field: "count" },
      ];

  const data = hideData
    ? currentItems.map((item, index) => ({
        serialNumber: `${
          factor === "day"
            ? moment().dayOfYear(item?.[factor])?.format("Do MMM")
            : factor === "week"
            ? moment().week(item?.[factor])?.format("Do MMMM")
            : moment()
                .month(item?.[factor] - 1)
                ?.format("MMMM")
        }, ${year || moment().format("YYYY")}`,

        app: item?.app
          ? `(${numberWithCommas(item?.app)} ${hideData || "time"}${
              item?.app !== 1 ? "s" : ""
            })`
          : "",
        web: item?.web
          ? `(${numberWithCommas(item?.web)} ${hideData || "time"}${
              item?.web !== 1 ? "s" : ""
            })`
          : "",
        count: `(${numberWithCommas(item?.dataCount)} ${hideData || "time"}${
          item?.dataCount !== 1 ? "s" : ""
        })`,
      }))
    : currentItems.map((item, index) => ({
        serialNumber: `${
          factor === "day"
            ? moment().dayOfYear(item?.[factor])?.format("Do MMM")
            : factor === "week"
            ? moment().week(item?.[factor])?.format("Do MMMM")
            : moment()
                .month(item?.[factor] - 1)
                ?.format("MMMM")
        }, ${year || moment().format("YYYY")}`,

        value: `₦ ${numberWithCommas(Number(item?.dataTotal).toFixed(2))}`,
        count: `(${numberWithCommas(item?.dataCount)} ${hideData || "time"}${
          item?.dataCount !== 1 ? "s" : ""
        })`,
      }));

  return (
    <>
      <div className="tw-flex tw-justify-between tw-items-center">
        <div className="tw-flex-1">
          {/* <MainRanger range={range} setRange={setRange} /> */}
        </div>
        <ExportButtons2
          headers={headers}
          data={data}
          fileName={`${params?.page} ${params?.id && `(${params?.id})`}`}
        />
      </div>
      <div className="py-5">
        <div className="bland row mx-0 py-3 px-0 text-capitalize">
          <div className="col textTrunc fontReduce fw-bold Lexend">S/N</div>
          {hideData ? (
            <>
              <div className="col textTrunc fontReduce fw-bold Lexend">APP</div>
              <div className="col textTrunc fontReduce fw-bold Lexend">WEB</div>
            </>
          ) : (
            <div className="col textTrunc fontReduce fw-bold Lexend">Value</div>
          )}
          <div className="col textTrunc fontReduce fw-bold Lexend">Count</div>
        </div>
        <div className="bland2 row mx-0">
          {currentItems?.map((item, index) => (
            <div key={index} className="row mx-0 py-3 px-0 border-bottom">
              <div className="col text-capitalize textTrunc fontReduce3 textTrunc2 my-auto">
                {factor === "day"
                  ? moment().dayOfYear(item?.[factor])?.format("Do MMM")
                  : factor === "week"
                  ? moment().week(item?.[factor])?.format("Do MMMM")
                  : moment()
                      .month(item?.[factor] - 1)
                      ?.format("MMMM")}
                , {/* {factor} {item?.[factor]} */}
                {year || moment().format("YYYY")}
              </div>
              {hideData ? (
                <>
                  <div className="col textTrunc fontReduce3 textTrunc2 my-auto">
                    {item?.app && (
                      <>
                        ({numberWithCommas(item?.app)} {hideData || "time"}
                        {item?.app !== 1 ? "s" : ""})
                      </>
                    )}
                  </div>
                  <div className="col textTrunc fontReduce3 textTrunc2 my-auto">
                    {item?.web && (
                      <>
                        ({numberWithCommas(item?.web)} {hideData || "time"}
                        {item?.web !== 1 ? "s" : ""})
                      </>
                    )}
                  </div>
                </>
              ) : (
                <div className="col textTrunc fontReduce3 textTrunc2 my-auto">
                  {nairaSign}{" "}
                  {numberWithCommas(Number(item?.dataTotal).toFixed(2))}
                </div>
              )}
              <div className="col textTrunc fontReduce3 textTrunc2 my-auto">
                ({numberWithCommas(item?.dataCount)} {hideData || "time"}
                {item?.dataCount !== 1 ? "s" : ""})
              </div>
            </div>
          ))}
        </div>
        <MainPaginate handlePageClick={handlePageClick} pageCount={pageCount} />
        <div className="bland row mx-0 py-3 px-0 text-capitalize">
          <div className="col textTrunc fontReduce fw-bold Lexend"></div>
          {!hideData ? (
            <div className="col textTrunc fontReduce fw-bold Lexend tw-capitalize">
              {nairaSign}{" "}
              {numberWithCommas(
                Number(
                  state?.reduce((ac, i) => (ac += Number(i?.dataTotal)), 0)
                ).toFixed(2)
              )}
            </div>
          ) : (
            <>
              <div className="col textTrunc fontReduce fw-bold Lexend tw-capitalize">
                {numberWithCommas(
                  Number(
                    state?.reduce((ac, i) => (ac += Number(i?.app || 0)), 0)
                  ).toFixed(0)
                )}{" "}
                {hideData || "time"}
                {state?.reduce((ac, i) => (ac += Number(i?.app || 0)), 0) !== 1
                  ? "s"
                  : ""}
              </div>
              <div className="col textTrunc fontReduce fw-bold Lexend tw-capitalize">
                {numberWithCommas(
                  Number(
                    state?.reduce((ac, i) => (ac += Number(i?.web || 0)), 0)
                  ).toFixed(0)
                )}{" "}
                {hideData || "time"}
                {state?.reduce((ac, i) => (ac += Number(i?.web || 0)), 0) !== 1
                  ? "s"
                  : ""}
              </div>
            </>
          )}
          <div className="col textTrunc fontReduce fw-bold Lexend tw-capitalize">
            {numberWithCommas(
              state?.reduce((ac, i) => (ac += Number(i?.dataCount)), 0)
            )}{" "}
            {hideData || "time"}
            {state?.reduce((ac, i) => (ac += Number(i?.dataCount)), 0) !== 1
              ? "s"
              : ""}
          </div>
        </div>
        {!hideData && (
          <div className="py-3">
            <h2 className="Lexend">
              Total: {nairaSign} {numberWithCommas(Number(total).toFixed(2))}
            </h2>
          </div>
        )}
      </div>
    </>
  );
};
