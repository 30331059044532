import React, { useContext, useEffect, useState } from "react";
import { Container } from "reactstrap";
import { BottomTab } from "../../Components/LoadMore";
import { GlobalState } from "../../Data/Context";
import { EmptyComponent } from "../../Utils";
import moment from "moment";
import { MainPaginate } from "../../Components/Transactions";

const GenActivities = () => {
  let { setStateName, activity, getReload, getActivity } =
    useContext(GlobalState);
  useEffect(() => {
    setStateName("General Activity");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //  [loading, setLoading] = useState(false),
  let [search, setSearch] = useState(""),
    [state, setState] = useState(null);

  useEffect(() => {
    if (search) {
      document.getElementById("Search").addEventListener("search", () => {
        getReload();
      });
      let handleSubmit = async () => {
        if (!search) return;

        await getActivity({
          search,
          general: "general",
        });
      };
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (activity.isFound) {
      setState(activity.mainSearch);
    } else setState(activity.general_data);
  }, [activity.general_data, activity.isFound, activity.mainSearch]);

  useEffect(() => {
    getReload();
    getActivity({ general: "general" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [pageCount, setPageCount] = useState(
    activity?.general_paginate
      ? Math.ceil(
          activity?.general_paginate?.total / activity?.general_paginate?.limit
        )
      : 1
  );

  useEffect(() => {
    setPageCount(
      Math.ceil(
        activity?.general_paginate?.total / activity?.general_paginate?.limit
      )
    );
  }, [activity?.general_paginate]);

  // let handleLoadMore = async () => {
  // 	setLoading(true);

  // 	await getActivity({
  // 		page: Number(
  // 			activity?.general_paginate?.nextPage
  // 		),
  // 		// limit: Number(
  // 		// 	activity?.general_paginate?.nextPage * activity?.general_paginate?.limit
  // 		// ),
  // 		search,
  // 		general: "general",
  // 	});

  // 	setLoading(false);
  // };

  // let [range, setRange] = useState(10);

  // const [itemOffset, setItemOffset] = useState(0);
  if (!state) return;

  // const endOffset = itemOffset + range;

  // const currentItems = state.slice(itemOffset, endOffset);
  // const pageCount = Math.ceil(state.length / range);

  const currentItems = state;

  const handlePageClick = async (event) => {
    // const newOffset = (event.selected * range) % state.length;
    // setItemOffset(newOffset);

    await getActivity({
      page: Number(event?.selected + 1),
      search,
      general: "general",
    });
  };

  return (
    <div className="bg-white aboutScreen">
      <Container className="py-5">
        <h4 className="Lexend">All general activities</h4>
        <div className="w-50 w50 mb-3">
          <input
            type="search"
            name="search"
            id="Search"
            className="form-control w-100 py-3 borderColor2"
            placeholder="Type here to search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className="py-3 py-md-5">
          {/* <MainRanger range={range} setRange={setRange} /> */}
          <div className="bland row mx-0 py-3 px-0 text-capitalize Lexend fw-bold">
            <div className="col textTrunc Lexend d-none d-md-flex">S/N</div>
            <div className="col textTrunc Lexend">User</div>
            <div className="col textTrunc Lexend">Action</div>
            <div className="col textTrunc Lexend">Date&time</div>
          </div>
          {currentItems?.length === 0 ? (
            <EmptyComponent subtitle={"Activity is empty"} />
          ) : (
            currentItems?.map((it, i) => (
              <div key={i} className="row mx-0 py-3 border-bottom myCursor">
                <div className="col my-auto text-capitalize fontReduce2 textTrunc py-3 py-md-4 d-none d-md-flex">
                  {i + 1}
                </div>
                <div className="col my-auto text-capitalize fontReduce2 textTrunc py-3 py-md-4 textTrunc2">
                  {it?.user?.firstName} {it?.user?.lastName}
                </div>
                <div className="col my-auto fontReduce2 textTrunc py-3 py-md-4 textTrunc4 textTrunc5">
                  {it?.message}
                </div>
                <div className="col my-auto fontReduce2 textTrunc py-3 py-md-4 textTrunc4">
                  {moment(it?.createdAt).format("DD/MM/YYYY hh:mm:ss A")}
                </div>
              </div>
            ))
          )}
          <MainPaginate
            handlePageClick={handlePageClick}
            pageCount={pageCount}
          />
          <BottomTab state={state} paginate={activity?.general_paginate} />
          {/* <LoadMore
						next={activity?.general_paginate?.next}
						handleLoadMore={handleLoadMore}
						loading={loading}
					/> */}
        </div>
      </Container>
    </div>
  );
};

export default GenActivities;
