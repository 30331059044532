import axios from "axios";
import { toast } from "react-toastify";
import {
  ADD_UPGRADE,
  ADD_UPGRADE_FAIL,
  CREATE_SERVICEICON,
  DELETE_SERVICEICON,
  GET_UPGRADE,
  GET_UPGRADE_FAIL,
  GET_USECASE,
  LOGOUT,
  REARRANGE_SERVICEICON,
  SEARCH_RELOAD,
  SEARCH_UPGRADE,
  SEARCH_UPGRADE_FAIL,
  SEARCH_UPGRADE_LOADING,
  SET_SUCCESS,
  UPDATE_SERVICEICON,
  UPDATE_UPGRADE,
  UPDATE_USECASE,
  UPDATE_USECASE_FAIL,
} from "../Actions/ActionTypes";
import { returnErrors } from "./ErrorReducer";
import { DeleteData, EditData } from "./AuthReducer";
import { imageUpload } from "../Actions/AuthActions";
import { useURL, useURL4 } from "../Config";

let initialState = {
  usecase: null,
  isUpdated: null,
  serviceIcon: null,
  status: null,
};

const UseCaseReducer = (state = initialState, action) => {
  let { type, payload } = action;
  switch (type) {
    case GET_USECASE:
      // console.log({ payload });
      return { ...state, usecase: payload, serviceIcon: payload?.serviceIcon };
    case UPDATE_USECASE:
      return {
        ...state,
        usecase: payload,
        isUpdated: true,
        serviceIcon: payload?.serviceIcon,
      };
    case REARRANGE_SERVICEICON:
      return {
        ...state,
        status: "rearranged",
        serviceIcon: payload?.serviceIcon,
        usecase: payload,
      };
    case CREATE_SERVICEICON:
      return {
        ...state,
        status: "created",
        serviceIcon: [payload, ...state?.serviceIcon],
      };
    case DELETE_SERVICEICON:
      return {
        ...state,
        status: "deleted",
        serviceIcon: DeleteData(state?.serviceIcon, payload),
      };
    case UPDATE_SERVICEICON:
      return {
        ...state,
        status: "updated",
        serviceIcon: EditData(state?.serviceIcon, payload),
      };
    case UPDATE_USECASE_FAIL:
      return { ...state, isUpdated: false, status: null };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default UseCaseReducer;

export const getUseCase = (data) => async (dispatch) => {
  try {
    let res;
    if (!data)
      res = await axios.get(`/api/v2/usecase`, {
        baseURL:  useURL,
      });
    else
      res = await axios.post(
        `/api/v2/usecase`,
        { ...data },
        {
          baseURL: useURL,
        }
      );
    // console.log({ data: res?.data });
    dispatch({
      type: !data ? GET_USECASE : UPDATE_USECASE,
      payload: res.data.data,
    });
    if (data) dispatch({ type: SET_SUCCESS, payload: res?.data?.msg });
  } catch (err) {
    if (err) console.log(err.response?.data?.error, { err });
    if (err?.response?.status === 429 || err?.response?.status === 405)
      toast.error(err?.response?.data ? err?.response?.data : err?.message);
    let error = err.response?.data?.error;
    if (error) dispatch(returnErrors({ error, status: err?.response?.status }));
    dispatch({ type: UPDATE_USECASE_FAIL });
  }
};

export const manageServiceIcon = (type, data) => async (dispatch) => {
  try {
    let res;
    if (type === "post") {
      let media = await imageUpload([data?.image]),
        image = media[0];
      res = await axios.post(`/api/v2/serviceicon`, { ...data, image });
    } else if (type === "delete")
      res = await axios.delete(`/api/v2/serviceicon?id=${data?._id}`);
    else if (type === "put") {
      let image;
      if (data?.image)
        if (!data?.image?._id) {
          let media = await imageUpload([data?.image]);
          image = media[0];
        }
      res = await axios.put(`/api/v2/serviceicon?id=${data?._id}`, {
        ...data,
        image: image || data?.image,
      });
    }
    // console.log({ data: res?.data });
    dispatch({
      type:
        type === "put"
          ? UPDATE_SERVICEICON
          : type === "delete"
          ? DELETE_SERVICEICON
          : CREATE_SERVICEICON,
      payload: type === "delete" ? data : res.data.data,
    });
    dispatch({ type: SET_SUCCESS, payload: res?.data?.msg });
  } catch (err) {
    if (err) console.log(err.response?.data?.error, { err });
    if (err?.response?.status === 429 || err?.response?.status === 405)
      toast.error(err?.response?.data ? err?.response?.data : err?.message);
    let error = err.response?.data?.error;
    if (error) dispatch(returnErrors({ error, status: err?.response?.status }));
    dispatch({ type: UPDATE_USECASE_FAIL });
  }
};

let initialState2 = {
  upgrade: null,
  isUpdated: null,
  isAdded: false,
  fee: 0,
  approved: 0,
  declined: 0,
  paginate: null,
  isFound: null,
  searchLoading: null,
  mainSearch: [],
  search: "",
  search_paginate: null,
};

export const UpgradeReducer = (state = initialState2, action) => {
  let { type, payload } = action,
    data = payload?.data || payload;

  switch (type) {
    case SEARCH_RELOAD:
      return {
        ...state,
        isFound: false,
        searchLoading: false,
      };
    case SEARCH_UPGRADE:
      return {
        ...state,
        isFound: true,
        searchLoading: false,
        mainSearch: action?.search === state?.search ? data : state?.mainSearch,
        search_paginate:
          action?.search === state?.search
            ? payload?.paginate
            : state?.search_paginate,
      };
    case SEARCH_UPGRADE_FAIL:
      return {
        ...state,
        searchLoading: false,
        mainSearch: state.mainSearch,
        search_paginate: state.search_paginate,
      };
    case SEARCH_UPGRADE_LOADING:
      return {
        ...state,
        search: action.search,
        searchLoading: true,
      };
    case GET_UPGRADE:
      return {
        ...state,
        upgrade: data,
        fee: payload?.fee,
        paginate: payload?.paginate,
        approved: payload?.approved,
        declined: payload?.declined,
      };
    case UPDATE_UPGRADE:
      return {
        ...state,
        upgrade: EditData(state.upgrade, data),
        mainSearch: EditData(state?.mainSearch, data),
        isUpdated: true,
      };
    case ADD_UPGRADE:
      return { ...state, isAdded: true };
    case ADD_UPGRADE_FAIL:
      return { ...state, isUpdated: false, isAdded: false };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export const manageUpgrade = (data, id, getter) => async (dispatch) => {
  try {
    if (getter?.search)
      dispatch({ type: SEARCH_UPGRADE_LOADING, search: getter?.search });
    let res;
    if (!data)
      res = await axios.get(
        `/api/v2/upgrade?a=a${getter?.limit ? `&limit=${getter?.limit}` : ""}${
          getter?.search ? `&search=${getter?.search}` : ""
        }${
			getter?.page ? `&page=${getter?.page}` : ""
		  }`,
        {
          baseURL: useURL,
        }
      );
    else
      res = await axios.post(
        `/api/v2/upgrade${data?._id ? `/${data?._id}` : ""}${
          id ? `/${id}` : ""
        }`,
        { ...data },
        {
          baseURL:  useURL,
        }
      );

    dispatch({
      type: !data
        ? getter?.search
          ? SEARCH_UPGRADE
          : GET_UPGRADE
        : id
        ? UPDATE_UPGRADE
        : ADD_UPGRADE,
      payload: res.data,
      search: data?.search
        ? res?.data?.search
          ? res?.data?.search
          : data?.search
        : "",
    });
    if (data) dispatch({ type: SET_SUCCESS, payload: res?.data?.msg });
  } catch (err) {
    if (err) console.log(err.response?.data?.error, { err });
    if (err?.response?.status === 429 || err?.response?.status === 405)
      toast.error(err?.response?.data ? err?.response?.data : err?.message);
    let error = err.response?.data?.error;
    if (error) dispatch(returnErrors({ error, status: err?.response?.status }));
    dispatch({
      type: data
        ? UPDATE_USECASE_FAIL
        : getter?.search
        ? SEARCH_UPGRADE_FAIL
        : GET_UPGRADE_FAIL,
    });
  }
};
